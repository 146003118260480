﻿.loading-box {
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    &.loading-in-modal {
        position: absolute;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

.loading-cart {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
}

.loader {
    width: 80px;
    height: 80px;
    /*margin: -75px 0 0 -75px;*/
    border-radius: 50%;
    border-top: 16px solid black;
    border: 3px solid white;
    border-top-color: $cl-dusk;
    animation: spin 2s linear infinite;
}