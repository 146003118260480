$sans-serif-font-stack: "Poppins", sans-serif;
$serif-font-stack: "kepler-std", serif;

.sans-serif, .body-1 {
    font-family: $sans-serif-font-stack;
    font-size: clamp(0.813rem, 1vw, 1rem);
    font-weight: 400;
    line-height: 1.63;
    letter-spacing: 0.32px;
}

.serif, .body-2 {
    font-family: $serif-font-stack;
    font-size: clamp(1.125rem, 1vw, 1.250rem);
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.4px;
}

//Default Body Text Styles
body {
    color: $cl-charcoal-gray;
    font-family: $sans-serif-font-stack;
    font-size: clamp(0.813rem, 1vw, 1rem);
    line-height: 1.63;
    letter-spacing: 0.32px;
}

h1, .h1 {
    color: $cl-dusky-purple;
    font-weight: 400;
    font-family: "mrsheffield-pro";
    font-size: 6.25rem;
    font-size: clamp(to-rem(55px), 8vw, to-rem(100px));
    line-height: 1.33;
    letter-spacing: normal;
    margin: 0;

    @media screen and (min-width: 992px) {
        line-height: 1.03;
        letter-spacing: normal;
    }
}

h2, .h2 {
    color: $cl-charcoal-gray;
    font-weight: 300;
    font-family: "kepler-std";
    font-size: 3.43rem;
    font-size: clamp(2.20rem, 4vw, 3.43rem);
    line-height: 1.14;
    letter-spacing: 0.7px;

    @media screen and (min-width: 992px) {
        line-height: 1.24;
        letter-spacing: 1.1px;
    }
}

h3, .h3 {
    color: $cl-charcoal-gray;
    font-weight: 400;
    font-family: "Poppins";
    font-size: 2.375rem;
    font-size: clamp(1.625rem, 2vw, 2.375rem);
    line-height: 1.31;
    letter-spacing: 0.52px;

    @media screen and (min-width: 992px) {
        line-height: 1.37;
        letter-spacing: 0.76px;
    }
}

h4, .h4 {
    color: $cl-charcoal-gray;
    font-weight: 400;
    font-family: "kepler-std";
    font-size: 2.125rem;
    font-size: clamp(1.875rem, 3vw, 2.125rem);
    line-height: 1.13;
    letter-spacing: 0.6px;

    @media screen and (min-width: 992px) {
        line-height: 1.12;
        letter-spacing: 0.68px;
    }
}

h5, .h5 {
    color: $cl-charcoal-gray;
    font-weight: 500;
    font-family: "Poppins";
    font-size: 1.5rem;
    font-size: clamp(1.25rem, 2vw, 1.5rem);
    line-height: 1.5;
    letter-spacing: 0.4px;

    @media screen and (min-width: 992px) {
        line-height: 1.42;
        letter-spacing: 0.48px;
    }
}

h6, .h6 {
    color: $cl-charcoal-gray;
    font-weight: 400;
    font-family: "Poppins";
    font-size: 1.125rem;
    font-size: clamp(1rem, 2vw, 1.125rem);
    line-height: 1.25;
    letter-spacing: 0.8px;
    text-transform: uppercase;

    @media screen and (min-width: 992px) {
        line-height: 1.44;
        letter-spacing: 1.08px;
    }
}

h7, .h7 {
    color: $cl-charcoal-gray;
    font-weight: 500;
    font-family: "Poppins";
    font-size: 1.125rem;
    font-size: clamp(1rem, 2vw, 1.125rem);
    line-height: 1.38;
    letter-spacing: 0.16px;

    @media screen and (min-width: 992px) {
        line-height: 1.44;
        letter-spacing: 0.36px;
    }
}

//Ensure that, on a dark colored background, all text should be white.
.cl-bg-dark-gray-blue,
.cl-bg-dusk,
.cl-bg-dusky-purple,
.cl-bg-slate-gray,
.theme-dark {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, h7, .h7, p, a, a:hover, label, ul, li {
        color: $cl-white;
    }
}

.theme-light {
    background-color: $cl-white;
    color: $cl-charcoal-gray;
}

.theme-dark {
    background-color: $cl-dark-gray-blue;
}
