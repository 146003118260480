.one-up-image {
    &-block {
        width: 100%;
        background-color: $cl-very-light-pink;
    }

    &__wrapper {
        max-width: 1280px;
        margin: 0 auto;
    }

    &__title {
        // padding should be applied on the section title block
    }

    &__video {
        max-width: 100%;

        &-wrapper {
            display: flex;
            justify-content: center;
        }

        &-cta {
            cursor: pointer;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                background-image: url(/Assets/imgs/icon-play.svg);
                background-repeat: no-repeat;
                background-size: contain;

                width: 138px;
                height: 138px;

                @include media-breakpoint-down(md) {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

    &__image {
        max-width: 100%;

        &-wrapper {
            display: flex;
            justify-content: center;
        }
    }

    &__modal-body {
        height: 260px;

        @include media-breakpoint-up(md) {
            height: 320px;
        }

        @include media-breakpoint-up(lg) {
            height: 560px;
        }

        @include media-breakpoint-up(xl) {
            height: 720px;
        }
    }
}