﻿.dropdown {
    &__button {
        background: white;
        border-bottom: 1px solid $cl-light-gray;
        color: $cl-black;
        display: inline-block;
        font-size: to-rem(14px);
        font-weight: 400;
        height: auto;
        letter-spacing: to-rem(0.28px);
        line-height: to-rem(21px);
        min-width: $dropdown-min-width;
        padding: 0 0 10px 0;
        position: relative;
        text-align: left;
        text-transform: none;
        width: 100%;
        &:after {
            display: none;
        }
        i {
            position: absolute;
            right: 8px;
        }
    }
    &-item {
        background-color: $cl-white;
        text-decoration: none;
        cursor: pointer;
    }
}
