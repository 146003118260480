.blog {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.blog__heading {
    margin-top: 2rem;
    text-align: center;
    font-size: 3.85714rem;
    margin: 28px 0 14px 0;
}

.blog__navbar {
    text-transform: uppercase;
    letter-spacing: .1rem;
    padding: 0;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 17px;
    margin: 1rem 0 3rem;
    flex-wrap: wrap;
}

.blog__navitem {
    padding: 1rem 1rem 0;
    position: relative;
    display: inline-block;
    font-weight: 400;

    a {
        color: inherit;
        white-space: nowrap;
        text-decoration: none;
    }

    &.is-active {
        &:after {
            content: "";
            position: absolute;
            bottom: -.5rem;
            left: 50%;
            transform: translateX(-50%);
            width: 1.5rem;
            height: 3px;
            background: #a7c5c3;
        }
    }

    &:hover {
        text-decoration: none;
        color: #a7c5c3;
    }
}

.blog__external {
    display: block;
    text-align: right;
    color: inherit;
    margin: .5rem 1rem;
}

.blog__row {
    @media screen and (min-width: 320px) {
        display: flex;
        flex-wrap: wrap;
    }
}

.blog__large-col {
    display: block;

    @media screen and (min-width: 320px) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 100%;
        min-width: 0;
    }

    @media screen and (min-width: 768px) {
        flex-basis: 50%;
        min-width: 0;
    }

    a {
        color: #fff;
        text-decoration: none;

        &:hover,
        &:focus {
            color: #fff;
        }
    }
}

.blog__col {
    background: #3d464c;
    color: #fff;
    flex-basis: 100%;

    @media screen and (min-width: 480px) {
        flex-direction: row;
        width: 50%;
    }

    @media screen and (min-width: 768px) {
        position: relative;
        overflow: hidden;
        flex-basis: 25%;

        .blog__grid {
            .blog__thumbnail {
                width: 100%;
            }
        }
    }

    a {
        color: #fff;
        text-decoration: none;

        &:hover,
        &:focus {
            color: #fff;
        }
    }
}

.blog__col--single {
    padding: 1rem;
    visibility: visible;
    display: flex;

    @media screen and (min-width: 480px) {
        padding: 2rem;
    }

    @media screen and (min-width: 768px) {
        flex-direction: column;
    }

    .blog__title {
        letter-spacing: normal;
        text-transform: none;
        margin: 0 0 1rem;
        font-size: 4.5vw;

        @media screen and (min-width: 768px) {
            font-size: 2vw;
            margin: 0.5rem 0;
        }
    }

    & > :first-child {
        width: 100%;
        padding-right: 1rem;

        @media screen and (min-width: 768px) {
            padding-right: 0;
        }
    }
}

.blog__thumbnail {
    color: #fff;
    display: block;
    position: relative;
    background: transparent;
    overflow: hidden;

    & picture img {
        opacity: 1;
        transition: 0.2s ease;
    }

    &:hover {
        & picture img {
            transition: 0.2s;
            opacity: 0.9;
        }
    }

    &:before {
        padding-top: 100%;
        content: "";
        display: block;
    }

    & > :first-child {
        position: absolute;
        display: block;
        max-height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        max-width: none;
        height: 100%;
        margin-left: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    picture {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: inherit;
            top: inherit;
            bottom: inherit;
            position: inherit;
            margin: inherit;
            left: inherit;
            right: inherit;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .blog__title-container {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        top: 0;
        background: -webkit-linear-gradient(bottom,rgba(0,0,0,.5),transparent 50%,transparent);
        background: linear-gradient(0deg,rgba(0,0,0,.5) 0,transparent 50%,transparent);
        padding: 2rem;
    }

    .blog__title-wrapper {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
        visibility: visible;

        @media screen and (min-width: 480px) {
            bottom: 2rem;
            left: 2rem;
            right: 2rem;
        }
    }

    .blog__meta {
        text-transform: uppercase;
        letter-spacing: .1rem;
        font-size: 2.5vw;

        @media screen and (min-width: 768px) {
            font-size: 1.5vw;
        }
    }

    .blog__title {
        letter-spacing: normal;
        text-transform: none;
        margin: .5rem 0 0;
        font-size: 3.5vw;

        @media screen and (min-width: 768px) {
            font-size: 1.7vw;
        }
    }

    .blog__title-container--no-img {
        margin-left: 0;
        left: 50%;
        transform: translateX(-50%);
        visibility: visible;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        background: #a7c5c3;
        padding: 2rem;
    }
}

.blog__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .blog__thumbnail {
        width: 50%;
    }

    .blog__meta {
        @media screen and (min-width: 768px) {
            font-size: 1vw;
        }
    }
}


// card template
.preview {
    &--image-top {
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__card {
        width: 100%;
        position: relative;
        display: flex;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        margin-bottom: 15px;
        font-family: 'Thasadith', sans-serif;

        &--background {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        &--description {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8%;
            flex-direction: column;
            font-size: 1rem;
            position: absolute;

            & * {
                margin-bottom: 10px;
            }

            & *:last-child {
                margin-bottom: 0;
            }

            & a {
                text-transform: uppercase;
                color: inherit;
                border: 2px solid;
                padding: 15px 25px;
                opacity: 0.9;
                font-weight: bold;
                font-size: larger;

                &:hover {
                    text-decoration: none;
                    opacity: 1;
                }
            }
        }

        &--show {
            width: 100%;
            height: 500px;

            & .card--top {
                height: 60%;

                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            & .card--bottom {
                display: flex;
                height: 40%;
                flex-direction: column;
                justify-content: space-between;
                padding: 4%;
                padding-top: 0;

                &__title {
                }

                &__date {
                }
            }
        }

        &--overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 100%;
            width: 100%;
            height: 0;
            overflow: hidden;
            transition: .5s ease;
        }

        &:hover &--overlay {
            bottom: 0;
            height: 100%;
        }

        &--middle {
            position: absolute;
            top: 50%;
            width: 100%;
            height: 10%;
            display: flex;

            & .triangle-center {
                width: 0;
                height: 0;
                border-left: 30px solid white;
                border-right: 30px solid white;
                border-top: 30px solid transparent;
                border-bottom: 20px solid white;
            }

            & .triangle-side {
                width: calc(50% - 15px);
                height: 100%;
                background-color: white;
            }
        }
    }
}

// insight template
.insight {
    display: flex;
    flex-direction: row;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #a3aaae;
    padding-left: 0;
    padding-right: 0;
    font-size: 20px;
    font-weight: 100;
    line-height: 30px;
    color: #000;
    overflow-x: hidden;
    letter-spacing: .5px;

    &__thumbnail {
        width: 100%;
        margin-bottom: 20px;

        &--large {
            height: 300px;
        }

        &--small {
            height: 150px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__large {
        width: 50%;
    }

    &__small--text {
        width: 25%;
    }

    &__small--image {
        width: 25%;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 20px;
        margin-right: 20px;
        border-right: 1px solid #a3aaae;
        border-left: 1px solid #a3aaae;
    }

    &__description {
        & a.read-more {
            font-weight: 600;
            letter-spacing: .5px;
            font-size: 16px;
            line-height: 18px;
            text-transform: uppercase;
            padding-bottom: 2px;
            border-bottom: 2px solid #a95228;
            color: #a95228;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__tag {
        & a {
            color: #666;
        }
    }

    &__date {
        font-size: 17px;
        line-height: 26px;
        color: #a3aaae;
        font-weight: 400;
    }

    &__sumary {
        font-size: 20px;
        font-weight: 100;
        line-height: 30px;
        color: #000;
        overflow-x: hidden;
        letter-spacing: .5px;
    }

    &--reverse {
        flex-direction: row-reverse;
    }
}

.blog-item {
    margin: auto;

    &_body {
        float: none;
        margin: auto;
        padding: 3.12rem 22px;

        @include media-breakpoint-up(md) {
            padding: 3.12rem 0;
        }
    }

    p {
        font-family: $sans-serif-font-stack;
        font-size: clamp(0.813rem, 1vw, 1rem);
        font-weight: 400;
        line-height: 1.63;
        letter-spacing: 0.32px;
    }


    .text-block {
        margin: auto;
        float: none;
        max-width: 100%;
        text-align: left;

        img {
            max-width: 100%;
            height: auto;
        }

        @include media-breakpoint-up(md) {
            max-width: 50%;
        }

        h4 {
            width: 100%;
            margin: auto;

            @include media-breakpoint-up(md) {
                width: 70%;
            }
        }
    }

    .testimonial-block {
        &_text {
            color: $cl-charcoal-gray;
            font-size: clamp(1.875rem, 3vw, 2.125rem);
            font-weight: normal;
            letter-spacing: 0.68px;

            p {
                font-family: 'kepler-std';
                font-size: clamp(1.875rem, 3vw, 2.125rem);
                font-weight: normal;
                line-height: 1.12;
                letter-spacing: 0.68px;
                margin-bottom: 8px;
                text-align: center;
            }
        }

        &_author {
            color: $cl-charcoal-gray;
            font-weight: 500;
        }
    }

    .adjacent-block {

        &--left {
            .adjacent-block__text-block {
                left: 0px;

                .adjacent-block__brand {
                    text-align: left;

                    p {
                        font-size: 2.375rem;
                        font-size: clamp(1.625rem, 2vw, 2.375rem);
                    }

                    .sectiontitleclass1 {
                        font-size: 2.375rem;
                        font-size: clamp(1.625rem, 2vw, 2.375rem);
                    }
                }
            }

            .adjacent-block__container {
                justify-content: flex-start;
            }
        }

        &--right {
            .adjacent-block__text-block {
                right: 0px;
                left: 0px;

                .adjacent-block__brand {
                    left: 0;
                    text-align: right;

                    p {
                        font-size: 2.375rem;
                        font-size: clamp(1.625rem, 2vw, 2.375rem);
                    }

                    .sectiontitleclass1 {
                        font-size: 2.375rem;
                        font-size: clamp(1.625rem, 2vw, 2.375rem);
                    }
                }
            }

            .adjacent-block__container {
                justify-content: flex-start;
            }
        }

        &__brand {
            margin-top: 0px;
        }

        @include media-breakpoint-up(md) {
            max-width: 1230px;

            &--right {
                .adjacent-block__bottom {
                    max-width: 525px;
                    left: 0;
                }

                .adjacent-block__text-block {
                    right: 80px;
                    left: -80px;

                    .adjacent-block__brand {
                        text-align: left;
                    }
                }
            }

            &--left {
                .adjacent-block__bottom {
                    max-width: 525px;
                    left: 0;
                }

                .adjacent-block__text-block {
                    left: 80px;
                }
            }
        }
    }
}