﻿.accordion-block {
    margin-bottom: 68px !important;

    .card {
        border-color: $cl-light-gray;
        border-radius: 0;
        border-left: none;
        border-right: none;
    }

    .card-header {
        background-color: transparent;
        border-bottom: none;
    }

    .card-body {
        p {
            margin-bottom: 0;
        }
    }

    .return-to-top {
        color: $cl-dark-gray-blue;
        margin-top: 52px;

        a {
            margin-left: 10px;
        }
    }

    > h5 {
        margin-bottom: 44px;
    }

    h6 {
        cursor: pointer;
        position: relative;

        &.collapsed {
            &:after {
                @include font-awesome-icon(f067, regular);
            }
        }
        &:after {
            @include font-awesome-icon(f068, regular);
            position: absolute;
            right: 0;
        }
    }
}
 
a.accordion-link {
    border-right: 1px solid $cl-light-gray;
    color: $cl-charcoal-gray;
    display: inline-block;
    font-size: to-rem(13px);
    line-height: to-rem(20px);
    margin-right: 8px;
    padding-right: 12px;
    &:last-child {
        border-right: none;
        margin-right: 0;
        padding-right: 0;
    }
}


