.two-up-product {
    &-block {
        @include media-breakpoint-up(md) {
            padding: 0 8px;

            & + & {
                padding-right: 0;
                padding-left: 15px;
            }
        }

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(md) {
            width: 100%;
            margin: 20px 0;
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;


        &--tall {
            max-width: 614px;

            img {
                max-width: 614px;
                max-height: 764px;
            }
        }

        &--wide {
            max-width: 674px;

            img {
                max-width: 674px;
                max-height: 552px;
            }
        }
    }

    &__image {
        width: 100%;

        &-img {
            max-width: 100%;
        }
    }

    &__body {
        position: relative;
        padding-left: 100px;
        max-width: 500px;


        &:before {
            content: '';
            position: absolute;
            width: 3px;
            height: 173px;
            background-color: $cl-beige;
            top: -44px;
            left: 58px; 

            @include media-breakpoint-down(md) {
                left: 12px;
                top: -30px;
            }
        }

        @include media-breakpoint-down(md) {
            padding: 0 30px;
        }

        &-heading {
            margin-top: 54px;

            @include media-breakpoint-down(md) {
                margin-top: 26px;
            }
        }

        &-description {
            padding: 15px 0;
        }

        &-cta {
            display: flex;

            div {
                margin-bottom: 5px;
                & + div {
                    @include media-breakpoint-up(lg) {
                        margin-left: 22px;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }
}
