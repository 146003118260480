﻿.e-datepicker {
  background-color: $cl-white;
  text-align: center;

  .datepicker {
    &.datepicker-inline {
      margin: 0 auto;
      padding: 0;
    }

    .datepicker-switch.disable {
      &:hover {
        cursor: default;
      }
    }

    td, th {
      padding: 12px;
    }

    thead {
      tr:nth-child(2) {
        border-left: 1px $cl-dark-gray-blue solid;
        border-right: 1px $cl-dark-gray-blue solid;
        border-top: 1px $cl-dark-gray-blue solid;

        th {
          background-color: $cl-dark-gray-blue;
          border-radius: initial;
        }

        th:nth-child(2) {
          color: $cl-white;
          font-weight: normal;
          letter-spacing: 1px;
          font-size: 1.1875rem;
          padding: 13px;
        }

        .prev.disabled, .next.disabled {
          visibility: initial;
          pointer-events: none;

          i {
            color: $cl-dark-gray-blue;
          }
        }

      }

      tr:nth-child(3) {
        border-left: 1px $color-calendar-border solid;
        border-right: 1px $color-calendar-border solid;
      }
    }

    tbody {
      border-left: 1px $color-calendar-border solid;
      border-right: 1px $color-calendar-border solid;
      border-bottom: 1px $color-calendar-border solid;
    }

    .dow {
      text-transform: uppercase;
      color: #7c7c7c;
      font-weight: normal;
    }

    .fa-angle-right, .fa-angle-left {
      color: $cl-white;
      font-size: 23px;
    }

    .day {
      line-height: 1.1875rem;

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        text-decoration: none;

        position: relative;

        &::after {
          //text-decoration: line-through FF font issue
          content: '';
          display: inline-block;
          
          position: absolute;
          top: 41%;
          left: 50%;
          transform: translate(-50%, 0);
          
          width: 30%;
          height: 1px;
          
          border-bottom: 2px solid #949698;
        }
      }

      &.active {
        position: relative;
        background-color: $cl-white;
        color: $cl-white;
        background-image: none;
        z-index: 1;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          width: 35px;
          height: 35px;
          background-color: $cl-dark-gray-blue;
          border-radius: 100%;
          z-index: -1;
          margin: auto;
        }

      }
    }
  }
}

@include media-breakpoint-up('xl') {
  .e-datepicker {
    .datepicker {
      &.datepicker-inline {
        margin: 0 auto;
        padding: 0;
      }

      td, th {
        padding: 12px 22px;
        font-size: 1.1875rem;
      }

      thead {
        tr:nth-child(2) {
          th {
            padding: 10px 22px;
          }
        }

        tr:nth-child(3) {
          border-left: 1px $color-calendar-border solid;
          border-right: 1px $color-calendar-border solid;
        }
      }

      .fa-angle-right, .fa-angle-left {
        font-size: 30px;
      }
    }
  }
}