﻿$transformation-breakpoint: md;

.hero-carousel {

    .carousel-inner {
        max-height: to-rem(1000px);
    }

    &__controls {
        display: flex;
        position: absolute;
        bottom: 28px;
        left: 0;
        padding-left: 33px;

        @include media-breakpoint-up(lg) {
            padding-left: 8rem;
            bottom: 13%;
        }

        @include media-breakpoint-down($transformation-breakpoint) {
            left: auto;
        }

        @media (max-width:1440px) and (min-width:991px) {
            padding-left: 4.4rem;
        }
    }

    &__arrow-wrap {
        text-decoration: none;
        width: 60px;
        height: 60px;
        border: solid 1px $cl-light-gray;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $cl-white;

        &-prev {
            margin-right: 10px;
        }

        &:hover {
            background: $cl-dusky-purple;

            i {
                color: $white;
            }
        }
    }

    &__arrow {
        font-size: 25px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: $cl-dark-gray-blue;
    }

    &__scrollDown {

        i {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% - 115px);
            color: #fff;
            font-size: 15px;
            z-index: 99;

            &.scroll-down-bottom {
                top: auto;
                bottom: -85px;
                font-size: 6px;
            }
        }

        .scroll-down {
            position: absolute;
            top: calc(100% - 91px);
            left: 50%;
            transform: translateX(-50%);
            width: 1px;
            background: #fff;
            opacity: 1;
            overflow: hidden;
            height: 162px;
            z-index: 99;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background: $cl-dusk;
                height: 100%;
                width: 100%;
                animation: scrolling 2.5s infinite cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            @keyframes scrolling {
                0% {
                    transform: translateY(-100%);
                }

                70% {
                    transform: translateY(100%);
                }


                100% {
                    transform: translateY(100%);
                }
            }
        }
    }

    @include media-breakpoint-down($transformation-breakpoint) {
        height: auto;
    }
}


.hero-slide {
    .row {
        flex-wrap: nowrap;

        @include media-breakpoint-down($transformation-breakpoint) {
            flex-wrap: wrap;
        }
    }

    &__title {
        margin-right: -5%;
        position: relative;
        z-index: 1;

        h2, .custom-heading {
            margin-bottom: 0;
            padding-top: 25px;

            > p {
                display: inline;
            }

            .sectiontitleclass1 {
                @include media-breakpoint-down($transformation-breakpoint) {
                    font-size: 35px;
                }
            }

            .sectiontitleclass2 {
                @extend .h1;

                @include media-breakpoint-down($transformation-breakpoint) {
                    font-size: 70px;
                }
            }

            @include media-breakpoint-down($transformation-breakpoint) {
                padding-top: 8px;
            }
        }

        @include media-breakpoint-down($transformation-breakpoint) {
            margin-right: 0;
        }
    }

    &__img {
        width: 100%;
        display: block;

        @include media-breakpoint-down($transformation-breakpoint) {
            height: 304px;
            object-fit: cover;
        }
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        padding-left: 4.6rem;

        @include media-breakpoint-down($transformation-breakpoint) {
            padding: 40px 33px 44px 33px;
        }

        @media only screen and (min-width : 1440px) {
            padding-left: 8.6rem;
        }
    }

    &__line {
        border-bottom: solid 2px $cl-slate-gray;
        display: inline-block;
        margin-left: 9px;
        vertical-align: middle;
        width: 75px;
    }

    &__number {
        margin-bottom: 2%;

        h6 {
            display: inline-block;
            margin: 0;
            min-width: 26px;
            vertical-align: middle;
        }
    }

    &__text {
        color: $cl-black;
        margin-bottom: 24px;
        padding-right: 30px;

        p:last-child {
            margin-bottom: 4%;
        }
    }

    &__text-info {
        display: flex;
        align-items: center;
        padding-bottom: 60px;

        @include media-breakpoint-up(lg) {
            padding-bottom: calc(5rem + 60px);
        }
    }
}

@media only screen and (min-width : 1400px) {
    .hero-slide {
        &__text-info {
            min-width: 692px;
        }
    }
}
