$logo-desktop-width: to-rem(262px);

.header {
    background-color: $cl-white;
    position: fixed;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 999;

    @include media-breakpoint-up(lg) {
        position: static;
    }

    .navbar-mobile-header {
        align-items: center;
        padding: 4px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: $cl-dark-gray-blue;
        color: white;

        .nav {
            align-items: center;
            flex-wrap: nowrap;
            height: 40px;
        }

        p {
            font-size: 10px;
            padding: 0;
            margin: 0;
        }

        .nav-item {
            &.logo {
                width: 100px;
                height: auto;
                max-height: 40px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 7px;

                a {
                    color: white;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                }

                &:hover {
                    background: $cl-dark-gray-blue;
                    opacity: 0.5;
                }
            }
        }
    }

    &__logo {
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: calc(50% - (#{$logo-desktop-width} / 2));
        }

        img {
            max-width: $logo-desktop-width;

            @include media-breakpoint-down(md) {
                height: 24px;
                width: auto;
            }
        }
    }

    &__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-up(lg) {
            align-items: flex-start;
        }
    }

    &__account {
        i {
            font-size: 1.125rem;
            color: $cl-dark-gray-blue;
        }
    }

    &__blog {
        .header-blog-nav-block {
            .nav-link {
                color: $cl-charcoal-gray;
                font-family: $sans-serif-font-stack;
                font-size: to-rem(13px);
                font-weight: 500;
                letter-spacing: to-rem(0.13px);
                line-height: to-rem(10px);
                margin-right: 20px;
                padding: 0px;
                text-decoration: none;
                text-transform: uppercase;

                &:hover {
                    color: $cl-charcoal-gray;
                    text-decoration: underline;
                }
            }
        }
    }

    .navbar {
        padding: 32px 32px 0 32px;

        &-brand {
            left: 50%;
            padding: 0;
            position: absolute;
            transform: translateX(-50%);

            @include media-breakpoint-down(md) {
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        @include media-breakpoint-down(md) {
            height: 60px;
            padding: 0 15px;
        }
    }

    .navbar-toggler {
        color: $cl-dusk;
        padding: 0;

        .fa-bars {
            display: none;
        }

        .fa-times {
            display: block;
        }

        &.collapsed {

            .fa-bars {
                display: block;
            }

            .fa-times {
                display: none;
            }
        }
    }
}

.header__content {
    @supports (-webkit-touch-callout: none) {
        .header__account {
            position: relative;
            top: 2px;
        }

        .header__cart {
            position: relative;
            top: 3px;
        }
    }

    @media screen and (max-width: 992px) {
        .navbar-right-mobile {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1 1 0;
            padding: 0;

            .header__search-mobile {
                margin-right: 20px;
            }
        }

        .navbar-left-mobile {
            flex: 1 1 0;
        }

        .header__logo {
            flex: 1 1 0;
            text-align: center;
        }

        .header__cart {
            .cart-icon {
                position: relative;

                .cartCount {
                    font-size: 9px;
                    color: #fff;
                    background-color: $cl-dusk;
                    border-radius: 12px;
                    padding: 2px 3px;
                    position: absolute;
                    top: -12px;
                    right: -8px;
                    min-width: 1.125rem;
                    text-align: center;
                }

                i {
                    color: $cl-dusk;
                    font-size: 1.125rem;
                }
            }
        }
    }
}
