.input-group  {
    label {
        line-height: 50px;
        margin: 0;
        margin-right: 15px;
    }

    .btn {
        height: 50px;
    }
}

.password-group {
    position: relative;

    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }

    input::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
    }

    input[type="password"] + .password-toggle:before {
        content: '\f070';
    }

    input[type="text"] + .password-toggle:before {
        content: '\f06e';
    }

    .password-toggle {
        position: absolute;
        right: 11px;
        top: 12px;
        cursor: pointer;

        &:before {
            content: '';
            font-size: 16px;
            font-family: 'Font Awesome 5 Pro';
            color: #707070;
        }
    }
}