.custom-control {
    &-label {
        font-family: $sans-serif-font-stack;
        font-size: to-rem(16px);
        font-weight: 400;
        letter-spacing: to-rem(0.32px);
        line-height: to-rem(26px);
        margin-bottom: $label-margin-bottom;
        text-transform: none;
    }  
}

.custom-control-inline {
    display: inline-flex;

    @include media-breakpoint-up(md) {
        margin-right: $custom-control-spacer-x;
    }
}


.custom-switch {
    display: flex;
    padding-left: 0;

    align-items: center;

    &-label {
        font-size: to-rem(13px);
        white-space: nowrap;
        line-height: 1.54;
        margin-right: 10px;
    }

    &-toggle {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 51px;
        height: 22px;        
        background-color: $cl-slate-gray;
        border-radius: 12px;
        cursor: pointer;

        margin: 0;

    }

    &-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    &-text {
        position: relative;
        display: block;
        height: inherit;
        font-size: 11px;
        line-height: 1.55;
        text-transform: uppercase;
        background: $cl-slate-gray;
        border-radius: inherit;

        -webkit-transition: 0.15s ease-out;
        -moz-transition: 0.15s ease-out;
        -o-transition: 0.15s ease-out;
        transition: 0.15s ease-out;
        -webkit-transition-property: opacity background;
        -moz-transition-property: opacity background;
        -o-transition-property: opacity background;
        transition-property: opacity background;
    }

    &-text:before,
    &-text:after {
        position: absolute;
        top: 50%;
        margin-top: -.5em;
        line-height: 1;
        -webkit-transition: inherit;
        -moz-transition: inherit;
        -o-transition: inherit;
        transition: inherit;
    }

    &-text:before {
        content: attr(data-off);
        right: 7px;
        color: $cl-white;
    }

    &-text:after {
        content: attr(data-on);
        left: 7px;
        color: $cl-white;
        opacity: 0;
    }

    &-input:checked~&-text {
        background: $cl-green;
    }

    &-input:checked~&-text:before {
        opacity: 0;
    }

    &-input:checked~&-text:after {
        opacity: 1;
    }

    &-handle {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 16px;
        height: 16px;
        background: $cl-white;
        border-radius: 50%;

        -webkit-transition: left 0.15s ease-out;
        -moz-transition: left 0.15s ease-out;
        -o-transition: left 0.15s ease-out;
        transition: left 0.15s ease-out;
    }    

    &-input:checked~&-handle {
        left: 32px;
    }

}



//Ensure that, on a dark colored background, all custom for elements should be white.
.cl-bg-dark-gray-blue,
.cl-bg-dusk,
.cl-bg-dusky-purple,
.cl-bg-slate-gray {


    .custom-radio .custom-control-label::before {
        background-color: transparent;
        border-color: $cl-white;
        border-width: 1px;
    }

    .custom-radio .custom-control-label::after {}

    .custom-control-input:checked~.custom-control-label::before {
        color: $cl-dark-gray-blue;
        border-color: $cl-white;
        border-width: 5px;
        background-color: transparent;
    }

    .custom-control-input:checked~.custom-control-label::after {
        background-image: none;
    }
}

.custom-radio,
.custom-checkbox {
    .custom-control-label {
        padding-left: 10px;
    }
}

.custom-checkbox {
    .custom-control-input {
        z-index: 1;
        left: -10px;
        top: 6px;
    }
    .custom-control-label {
           
        &:before {
            width: 13px;
            height: 13px;
            border: 1px solid $cl-light-gray;     
            top: 6px;    
            left: -1rem;             
        }
        &:after {                      
            color: $cl-dark-gray-blue;
            top: 6px;
            left: -1rem;
        } 
    }
    .custom-control-input:checked ~ .custom-control-label::after {
        @include font-awesome-icon('f14a', 'solid');  
        font-size: 14px;     
    }

    .custom-control-input:checked ~ .custom-control-label::before {       
        border: none;
        background-color:transparent;
    }

    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        background-color: transparent;
        border-color: $cl-light-gray;
    }
}
