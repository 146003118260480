.two-one-one {

    &__content {
        max-width: 1637px;
        margin: 0 auto;
    }

    &-block {
        img {
            max-width: 100%;
        }

        @media (min-width: 1860px) {
            .container {
                max-width: 1820px;
            }
        }
    }

    &__featured {
        &>div {
            margin-bottom: 22px;

            @include media-breakpoint-up(sm) {
                padding-right: 22px;
            }

            @include media-breakpoint-up(md) {
                padding-right: 0;
            }

            @include media-breakpoint-up(lg) {
                padding: 0 30px 0 0;
            }

            @include media-breakpoint-up(xl) {
                .thumbnail-img {
                    width: 809px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }

    &__side {
        display: flex;
        flex-wrap: wrap;

        &>div {
            width: 50%;
            text-align: center;

            &:nth-child(odd) {
                padding-right: 12px;                
            }

            &:nth-child(even) {
                padding-left: 12px;
            }

            @include media-breakpoint-up(sm) {
                &:nth-child(odd) {
                    padding-right: 22px;
                    padding-left: 22px;
                }

                &:nth-child(even) {
                    padding-right: 22px;
                    padding-left: 22px;
                }
            }

            @include media-breakpoint-up(md) {               

                &:nth-child(odd) {
                    padding-right: 25px;
                    padding-left: 25px;
                }

                &:nth-child(even) {
                    padding-right: 25px;
                    padding-left: 25px;
                }
            }
       

            @include media-breakpoint-up(lg) {                
                margin-bottom: 8px;

                &:nth-child(odd) {
                    padding-right: 28px;
                    padding-left: 28px;
                }

                &:nth-child(even) {
                    padding-right: 28px;
                    padding-left: 28px;
                }
            }

            @include media-breakpoint-up(xl) {
                

                .thumbnail-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
}