.registration {
    &-page {
        padding: 66px 0;

        @include media-breakpoint-up(md) {
            background-color: $cl-very-light-pink;
            padding-bottom: 133px;
        }

        h6 {
            font-size: to-rem(13px);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: to-rem(0.13px);
        }

        h3 {
            @include media-breakpoint-up(md) {
                line-height: 1.37;
                margin-bottom: to-rem(25px);
            }
        }

        @media (min-width: 1680px) {
            .container {
                max-width: 1620px;
            }
        }

        .login-link {
            margin-top: 1rem;
            text-align: center;
        }

    }

    &__aside {
        &-title {
            max-width: 577px;
        }

        &-text {
            max-width: 517px;
        }
    }

    &__steps {
        background-color: #fff;

        &-navs {
            width: 100%;
            height: 6px;

            @include media-breakpoint-down(md) {
                width: 100vw;
                margin-left: -15px;
                margin-bottom: to-rem(22px);
            }

            .nav-item {
                width: 25%;
            }

            .nav-link {
                width: 100%;
                border-radius: 0;
                padding: 0;
                height: 6px;

                background-color: $cl-dark-gray-blue;

                &.active {
                    background-color: $cl-dark-gray-blue;
                }


                &.disabled {
                    background-color: $cl-light-gray;
                }

            }
        }

        .registration-options-wrapper {
            display: flex;
            align-items: start;
            padding-top: 2.75rem;

            .option-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 30px;

                &:first-child {
                    border-right: 1px solid $cl-light-gray;
                    padding-inline-start: 0;
                }

                &:last-child {
                    padding-inline-end: 0;
                }

                p {
                    text-align: center;
                    min-height: 70px;
                    margin-block-start: 10px;
                    margin-block-end: 40px;
                    padding-inline: 15px;
                }
            }
        }
    }

    &__tab-wrapper {
        @include media-breakpoint-up(md) {
            padding: to-rem(26px) to-rem(66px) to-rem(70px);
        }

        h5 {
            padding-top: to-rem(40px);
            padding-bottom: to-rem(20px);

            @include media-breakpoint-down(md) {
                padding-bottom: 0;
            }
        }
    }

    &__form {
        &-tax-text {
            font-size: to-rem(16px);
            font-weight: 500;
            line-height: 1.63;
            letter-spacing: to-rem(0.32px);
        }
    }

    &-controls {
        display: flex;
        margin-top: 2rem;
        flex-direction: column-reverse;

        @include media-breakpoint-up(md) {
            justify-content: flex-end;
            flex-direction: row;

        }
    }

    &__success {
        padding: to-rem(20px) 0;
        background-color: #fff;

        text-align: center;

        @include media-breakpoint-up(lg) {
            padding: to-rem(70px) to-rem(120px);
        }
    }

    &__hr {
        margin-top: to-rem(20px);
        border-top: 1px solid $cl-light-gray;
    }

    &__label {
        text-transform: none;
    }

    &__radio-check {
        label {
            font-size: to-rem(13px);
            margin: 0;
        }
        
        &.semibold {
            label {
                font-weight: 600;
            }
        }

        .edit-profile__channel-info {
            margin-left: 5px;
        }
    }
}