.header__mobile-nav {

    .mobile-nav {
        z-index: 100;
        height: calc(100vh - 60px);
        width: 100vw;
        position: fixed;
        top: 60px;
        background-color: $cl-charcoal-gray;

        &__wrapper {
            left: 100%;
            height: 100%;
            overflow-y: auto;
            width: calc(100vw - 43px);
            background-color: $cl-white;
            transform: translate3d(-100%, 0, 0);
            transition: transform 0.3s ease-in-out;
        }

        &__main {

            .main-nav__primary {
                display: flex;
                flex-direction: column;
                padding: 3px 0 8px;
                margin: 0 15px 7px;
                border-bottom: 1px solid $cl-very-light-pink;

                .nav-item {
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: 0.28px;
                    text-align: left;
                    width: 100%;
                    cursor: pointer;

                    .nav-link {
                        width: 100%;
                        padding: 12px 0;
                        text-decoration: none;

                        i {
                            float: right;
                        }
                    }
                }
            }
        }

        &__main, &__account {
            .account-nav__subMenu, .main-nav__secondary {
                position: fixed;
                left: 0;
                top: 0;
                height: 100%;
                overflow-y: auto;
                width: calc(100vw - 43px);
                background-color: $cl-white;
                transform: translate3d(-100%, 0, 0);
                transition: transform 0.3s ease-in-out;
                z-index: 2;
                padding: 15px;

                &.active {
                    transform: translate3d(0, 0, 0);
                }

                .nav-back-link {
                    font-size: 13px;
                    font-weight: normal;
                    line-height: 1.54;
                    letter-spacing: normal;
                    text-align: left;
                    padding: 12px 0;
                    cursor: pointer;
                }

                .sub-nav-title {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.25;
                    letter-spacing: 0.8px;
                    text-align: left;
                    padding: 14px 0 6px;
                    text-transform: uppercase;
                }

                .sub-nav-content {

                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                    }

                    li {
                        padding: 12px 0;

                        a {
                            font-size: 13px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.62;
                            letter-spacing: 0.26px;
                            text-align: left;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        &__account {
            .nav-link-submenu {
                i {
                    float: right;
                }
            }
        }

        &__blog {
            margin: 0 15px;
            padding-bottom: 15px;

            .nav-item {
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.62;
                letter-spacing: 0.26px;

                .nav-link {
                    padding: 12px 0;
                    text-decoration: none;
                    color: $cl-charcoal-gray;

                    i {
                        float: right;
                    }
                }
            }
        }

        &__user-nav {
            padding: 0 15px;

            .nav-link {
                font-size: to-rem(13px);
            }
        }
    }

    &.show {
        .mobile-nav {
            &__wrapper {
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

.mobile-menu-opened {
    height: 100%;
    overflow: hidden;
}
