﻿/* Thumbnail block change*/
.thumbnail-block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1634px;
    padding: 4rem 0;

    

    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
    }

    &_title {
        .section-title-block {
            padding-bottom: 0px;

            hr {
                margin-bottom: 20px;
            }
        }
    }

    .thumbnailwithtitleonlyblock {
        width: 50%;           
    }

    &_sub {
        padding: 0 7.5px 20px;

        a {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            text-decoration: none;

            img {
                width: 100%;
                margin-bottom: 22px;
            }
        }
    }
}

