.pagination {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 44px 0;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;


    &__controls {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        text-align: left;
        text-transform: uppercase;
        display: flex;
        justify-content: center;

        &-item {
            display: inline-block;
            margin: 0 3px;
            cursor: pointer;
        }

        &-prev {
            line-height: 29px;
            margin-right: 1em;
            text-decoration: none;

            i {
                margin-right: 11px;
            }

            @include media-breakpoint-down(xs) {
                margin-right: .5em;
            }

            @include media-breakpoint-up(md) {
                margin-right: 100px;
            }
        }

        &-next {
            line-height: 29px;
            margin-left: 1em;
            text-decoration: none;

            i {
                margin-left: 11px;
            }

            @include media-breakpoint-down(xs) {
                margin-left: .5em;
            }

            @include media-breakpoint-up(md) {
                margin-left: 100px;
            }
        }

        &-page {
            display: inline-block;
            height: 29px;
            width: 29px;
            text-align: center;
            border-radius: 50%;
            line-height: 29px;
            text-decoration: none;

            &--active {
                background-color: $cl-very-light-pink;
            }
        }

        &-disabled {
            pointer-events: none;
            cursor: default;
            color: lightgray !important;
        }
    }
}

.dataTables_paginate {
    ul.pagination {
        li {
            background-color: transparent;
            margin: 0;

            &:hover a {
                color: $cl-black;
            }
        }
    }

    .page-item {
        cursor: pointer;
        display: inline-block;
        margin: 0 3px;
        border: none;
        padding: 0;

        @include media-breakpoint-up(md) {
            padding: 10px;
        }

        &.disabled {
            a {
                color: lightgray;
            }
        }

        .page-link {
            display: inline-block; 
            height: 29px;
            width: 29px;
            text-align: center;
            border-radius: 50%;
            line-height: 29px;
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;
            text-decoration: none;

            &:focus {
                box-shadow: none;
            }
        }

        &.active {
            .page-link {
                color: $cl-black;
                background-color: $cl-very-light-pink;
            }
        }
    }
}
