@function to-rem($size) {
  @return $size / 16px * 1rem;
}


//Usage:
// Call this mixin from inside a Before or After pseudoelement
// $weight = [solid, regular, light, brands]
// $unicode = f778 (for example)
@mixin font-awesome-icon($unicode, $weight:regular) {
    @extend .icon;
    content: #{"\"\\"}#{$unicode + "\""};

    @if $weight == 'solid' {
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
    }
    @else if $weight == 'regular' {
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
    }
    @else if $weight == 'light' {
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
    }
    @else if $weight == 'brands' {
        font-family: "Font Awesome 5 Brands";
        font-weight: 400;
    }
    @else {
        @error "Invalid weight #{$weight}.";
    }
}

@mixin fontawesome($content, $color, $size, $weight, $family: 'Font Awesome 5 Pro') {
    content: $content;
    font-family: $family;
    color: $color;
    font-size: $size;
    font-weight: $weight;
}
