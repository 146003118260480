.header__search {   
    position: absolute;
    top: 60px;
    right: 0;
    width: 0;
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
        position: relative;
        top: auto;
        right: auto;
        width: auto;
        justify-content: flex-end;
        overflow: initial;
    }

    .searchbox {
        transform: translate3d(100%, 0, 0);
        transition: transform 0.3s ease-in-out;

        @include media-breakpoint-up(lg) {
            transform: translate3d(0, 0, 0);
        }
    }

    &--active {        
        width: 100vw;
        overflow-x: visible;
        padding: 0 30px;
        z-index: 99;

        .searchbox {
            transform: translate3d(0, 0, 0);
        }
    }

    &-mobile {
        display: block;
        width: 24px;
        height: 24px;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        i,svg {
            color: $cl-dusk;
            width: 20px;
            height: 24px;
        }
    }

    .btn {
        background-color: $cl-dusk;
        color: $cl-white;
        padding: 14px;
        width: 47px;

        i,
        svg {
            pointer-events: none;
        }
    }

    .form-control {
        font-size: to-rem(13px);
        line-height: 1.54;
        letter-spacing: 0.26px;
        
        
        &::placeholder {
            color: rgba($black, .5);    
        }

        @include media-breakpoint-down(md) {
            width: calc(100% - 50px);
        }

        @include media-breakpoint-up(xl) {
            width: to-rem(328px);
        }
    }

    input::placeholder,
    input::-webkit-input-placeholder,
    input::-moz-placeholder,
    input:-ms-input-placeholder,
    input:-moz-placeholder {
        color: $cl-black;
        font-family: $sans-serif-font-stack;
        font-size: to-rem(13px);
        font-weight: 400;
        letter-spacing: to-rem(0.26px);
        line-height: to-rem(20px);
    }


}