.breadcrumbs {
    display: flex;
    list-style: none;
    padding: 18px 0;
    margin-left: 40px;
    margin-bottom: 0;

    &__slash {
        margin: 0 20px;
    }

    &__item {
        padding: 0;


        &-link {
            font-family: $sans-serif-font-stack;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            text-align: left;
            display: inline-block;
            text-transform: none;
            text-decoration: none;
            letter-spacing: normal;
        }

        i {
            font-size: 11px;
            line-height: 1.54;
            color: $cl-slate-gray;
            display: inline-block;
            margin: 0 20px;
        }

        &:last-child {

            .breadcrumbs__item-link {
                text-decoration: none;
                font-weight: 500;
                letter-spacing: 0.65px;
            }

            i {
                display: none;
            }
        }
    }
}
