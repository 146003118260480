.global-notification-bar {
    position: relative;
    width: 100%;
    background-color: $cl-dark-gray-blue;

    &__wrapper {
        /*padding: 6.5px 15px;*/
        padding: 0px 15px;
    }

    &__message {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.28px;
        text-align: center;
        color: $cl-white;
        overflow: hidden;

        p {
            margin-bottom: 0;
            font-size: clamp(0.75rem, 1vw, 0.875rem);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // need a more permanent sultion for ridiculous space requirements on one line.
        }

        a {
            color: $cl-white;
        }
    }

    .nav {
        align-items: center;
        flex-wrap: nowrap;
        height: 40px;

        &:hover .logo {
            background: $cl-dark-gray-blue;
            opacity: 0.5;
        }

        &:hover .logo.active {
            background: white;
            opacity: 1;
        }

        .logo:hover {
            background: $cl-dark-gray-blue;
            opacity: 1;
        }
    }

    .nav-item {
        &.logo {
            width: 100px;
            height: auto;
            max-height: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 8px 5px;

            @media screen and (min-width: 992px) and (max-width: 1300px) {
                width: 75px;
            }

            &.active {
                background: white;
            }

            a {
                width: 100%;
                height: 100%;
                padding: 0;
            }
        }

        &-link .nav-link {
            color: $cl-white;
            font-size: .75rem;
            padding: .5rem .3rem;
        }
    }

    .nav-item a {
        color: $cl-white;
        text-decoration: none;
        font-size: clamp(0.75rem, 1vw, 0.875rem);
        padding: 0.5rem 0.5rem;

        @include media-breakpoint-up(lg) {
            padding: 0 1rem;
        }

        @media screen and (min-width: 992px) and (max-width: 1110px) {
            padding: 0 .5rem;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__myaccount {
        position: relative;
        padding-right: 15px;

        &:hover {
            .global-notification-bar__dropdown {
                display: block;
            }
        }
    }

    &__dropdown {
        display: none;
        position: absolute;
        top: 100%;
        z-index: 999;
        border: 1px solid $cl-dark-gray-blue;
        padding-top: 2px;

        a.dropdown-item {
            color: $cl-dark-gray-blue;
            padding: .25rem 1rem;
            text-decoration: none;
            font-size: .875rem;

            &:hover {
                color: #ffffff;
                text-decoration: none;
            }
        }
    }

    @include media-breakpoint-up(lg) {

        &__message {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        &__external,
        &__account,
        &__message {
            flex: 1 1 0;
        }

        &__account {
            justify-content: flex-end;
        }
    }
}

.header__mobile-notify-bar {
    .global-notification-bar {
        &__account {
            display: none;
        }
    }
}

.mobile-nav__account {
    .global-notification-bar {
        height: auto;

        &__wrapper {
            padding: 0;
        }

        &__external {
            display: none;
        }

        &__message {
            display: none;
        }

        &__account {
            width: 90%;
            justify-content: space-between;
        }

        &__register {
            justify-content: center;
            display: flex;
        }

        &__signin {
            justify-content: center;
            display: flex;
        }

        &__status {
            display: none;
        }

        &__signout {
            display: none;
        }

        &__cart {
            display: none;
        }
    }
}

.mobile-nav__user-nav {
    .global-notification-bar {
        height: auto;
        background-color: transparent;

        &__wrapper {
            padding: 0;

            & > .row {
                margin: 0;
            }
        }

        &__external {
            display: none;
        }

        &__message {
            display: none;
        }

        &__account {
            width: 100%;
            display: block;

            .nav-item {
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.54;

                .nav-link {
                    padding: 12px 0;
                    text-decoration: underline;
                    color: $cl-dark-gray-blue;

                    @include media-breakpoint-up(lg) {
                        text-decoration: none;
                    }
                }
            }
        }

        &__dealer {
            display: none;
        }

        &__register {
            display: none;
        }

        &__signin {
            display: none;
        }

        &__status {
            display: none;
        }

        &__cart {
            display: none;
        }
    }
}
