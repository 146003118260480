﻿div.notifyjs {

    &-corner {
        z-index: 9999;

        @include media-breakpoint-up(lg) {
            margin: to-rem(154px) to-rem(39px) to-rem(60.5px) to-rem(356px);
        }
    }

    &-bootstrap-base {
        white-space: normal;
        min-height: 0;
        padding: 1.125rem 1.563rem 1.313rem 1.5rem;

        span {
            font-size: 1.125rem;
            line-height: normal;
            font-weight: normal;
        }
    }

    &-bootstrap-success {
        border-color: $cl-dusky-purple;
        background-color: $cl-dusky-purple;

        span {
            color: $cl-white;
        }
    }
}
