﻿.cart__progress {
    display: flex;
    justify-content: center;

    &--item {
        align-items: center;
        color: $cl-slate-gray;
        display: flex;
        font-size: to-rem(13px);
        font-weight: 500;
        line-height: 1;
        text-decoration: none;
        text-transform: uppercase;
        margin: 0;

        &:hover {
            text-decoration: none;
        }

        &:after {
            content: "\f178";
            color: $cl-light-gray;
            display: block;
            font-family: 'Font Awesome 5 Pro';
            font-size: 20px;
            margin: 0 15px;

            @include media-breakpoint-up(sm) {
                margin: 0 30px;
            }
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &.active {
            color: $cl-charcoal-gray;
        }
    }

    @include media-breakpoint-up(sm) {
        justify-content: flex-start;
    }
}
