.full-width-image-text {
    &-block {
        .sectiontitleclass1 {
            @extend .h2;
        }

        .sectiontitleclass2 {
            @extend .h1;
        }
    }

    &-contaienr {
    }

    &__image {
        text-align: right;

        &-img {
            max-width: 100%;
            max-height: 1000px;
            object-fit: cover;

            @include media-breakpoint-down(sm) {
                height: auto;
            }
        }
    }

    .order-md-2 &__image {
        text-align: left;
    }

    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 110px;

        @include media-breakpoint-down(md) {
            padding: 60px 25px;
        }

        &-heading {
            width: 100%;
        }

        &-description {
            width: 100%;
            padding: 44px 0;
        }

        &-cta {
            width: 100%;
            justify-content: left;
        }
    }
}
