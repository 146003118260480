﻿.best-bet {
    margin: 0 auto;

    &__link {
        text-decoration: none;

        &:hover {
            color: $cl-dark-gray-blue;
            text-decoration: none;
        }
    }

    &__img-wrap {
        @include media-breakpoint-up(lg) {
            max-width: to-rem(451px);
        }
    }

    &__img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        display: block;
    }

    &__content {
        padding: $grid-gutter-width;
        border: 1px solid $cl-light-gray;
    }

    &__recommended {
        font-size: to-rem(13px);
        font-weight: 500;
        line-height: 1.54;
        text-transform: uppercase;
        background: $cl-dusky-purple;
        color: $cl-white;
        padding: 6px 15px;
        max-width: fit-content;
        margin-bottom: 15px;
    }
}

@include media-breakpoint-up(lg) {
    .best-bet {
        max-width: to-rem(1358px);

        &__link {
            display: flex;
            flex-wrap: wrap;
        }

        &__content {
            padding: 46px 53px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            border: 1px solid $cl-light-gray;
        }
    }
}
