
$color-darkgrey: $cl-charcoal-gray;
$text-font-size: 1rem;
$container-max-width: none;

/* Clear inner floats */
.clearfix::after {
    clear: both;
    content: "";
    display: table;
}

body {
    overflow-x: hidden;
}

.container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}

/* Make all blocks float to the left and clear duplicate paddings caused by nested Bootstrap's column */
/*div.block {
    display: inline-block;
    vertical-align: top;
    padding-left: 0;
    padding-right: 0;
}*/


.filters-tag {
    position: relative;
    height: 32px;
    border-radius: 32px;
    padding: 0px 40px 0px 18px;
    border: solid 1px #fcf5ec;
    margin-right: 11px;
    margin-top: 5px;
    background-color: #fff;
    line-height: 32px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    white-space: nowrap;
    color: $cl-charcoal-gray;
    text-transform: none;

    &__remove {
        position: absolute;
        top: 0;
        right: 15px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        text-align: right;
    }
}

.content-filters-tag {
    position: relative;
    border-radius: 3px;
    background-color: $color-darkgrey;
    color: black;
    font-size: $text-font-size;
    padding: 5px 10px 5px 10px;
    margin-bottom: 0;
}

.hidden {
    display: none;
}

.style-list-none {
    list-style: none;
    padding: 0;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-between {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pull-right {
    float: right;
}

.border {
    border: 1px solid #eeeeee;
}

.border-radius-none {
    border-radius: 0;
}

.link {
    text-decoration: none;
    color: #666;
    padding-right: 15px;

    &:hover {
        text-decoration: none;
        color: black;
        opacity: 0.7;
    }
}

.square-box {
    border-radius: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.full-width {
    width: 100%;
}

.align-center {
    text-align: center;
}

.success {
    padding: 15px;
    background-color: #DFF0D8;
    color: #468847;
}

.error {
    padding: 15px;
    background-color: #F2DEDE;
    color: #B94A48;
}

ul.none-style-type {
    list-style-type: none;
}

.screen-width-block {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    .screen-width-wrapper {
        @include media-breakpoint-up(xl) {
            max-width: $container-max-width;
        }

        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    .screen-width-container {
        @include media-breakpoint-up(xl) {
            max-width: $container-max-width;
        }

        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.sticky-top {
    position: sticky;
    top: 0;
}

.disabled {
    pointer-events: none;
    cursor: default;
    background-color: lightgray;
    border: 0;
}

.blog-divider {
    width: 92px;
    border-bottom: solid 2px $cl-beige;
    margin: 23px 0;
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.tool-tip {
    font-size: to-rem(13px);
    position: absolute;
    top: -11px;
    cursor: pointer;
}

.tooltip {
    &-inner {
        background-color: $cl-dark-gray-blue;
    }

    .arrow {
        &:before {
            border-top-color: $cl-dark-gray-blue;
        }
    }
}


/* FontAwesome CSS pseudo-element styling */
/* This is for cases where you want to display a fontAwesome icon in your CSS rather than using an <i> element in your markup */

/* general reset/setup styling for icons - needed on all */
.icon::before, .icon::after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.download-link {
    font-size: to-rem(13px);
    text-decoration: underline;

    &::before {
        margin-right: 7px;
        @include fontawesome('\f019', $cl-dark-gray-blue, 14px, 400);
    }
}

ul#epi-quickNavigator {
    top: 45%;
    right: 0;

    @include media-breakpoint-up(lg) {
        top: to-rem(35px);
    }
}

img {
    max-width: 100%;
}

//temp fix below , need more robust solution for generic pages that need grid orientation

///****///
div.block {
    padding-left: 0;
    padding-right: 0;
}
///****///

div.daterangepicker span.drp-selected {
    text-align: left;
    display: block;
    padding: 5px 0;
    @include media-breakpoint-up(sm) {
        display: inline-block;
    }
}

