﻿.error-message {
    /* 404 background styles*/
    &-background {
        bottom: 0;
        color: $cl-white;
        font-size: clamp(9.375rem, 21vw, 25rem);
        font-weight: 600;
        height: 100%;
        left: 0;
        line-height: 1;
        margin: auto;
        opacity: 0.8;
        overflow: auto;
        position: absolute;
        right: 0;
        text-align: center;
        top: 5%;
        width: 100%;
        z-index: -1;

        @include media-breakpoint-up(sm) {
            top: 10%;
        }
    }
    /* Styles for the text below the container block*/
    &-body {
        align-items: center;
        border-top: 1px solid #777;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1634px;
        margin: auto;
        padding: 3rem 15px 0;
        text-align: center;
    }
    /* Styles for the container block*/
    &-container {
        position: relative;
        width: 100%;
        z-index: 2;
    }
    /* Styles for the text inside the container block*/
    &-text {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 4rem 15px;
        margin: 0 auto;
        max-width: 100%;

        h2 {
            line-height: 1.24;
        }

        @include media-breakpoint-up(lg) {
            max-width: 25%;
            padding: 8rem 10px;
        }
    }
}
