.favorites {
    &-page {
        .product-thumbnail__quick-view {
            display: none !important;
        }
    }

    &__controls {
        padding-bottom: 22px;
        border-bottom: 1px solid $cl-light-gray;
        margin-bottom: 22px;

        &-head {
            font-size: to-rem(18px);
            font-weight: 500;
            line-height: 1.44;
            letter-spacing: 0.36px;
            padding-bottom: 20px;
        }

        &-nav {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 22px;

            @include media-breakpoint-up(md) {
                justify-content: flex-start;
                margin-bottom: 0;
            }
        }


        &-cta {
            font-size: to-rem(13px);
            line-height: 1.54;
            margin-right: 0;

            @include media-breakpoint-up(md) {
                margin-right: 11px;
            }


            @include media-breakpoint-up(xl) {
                margin-right: 23px;
            }

            i {
                font-size: 1em;
                margin-right: 1ch;
            }
        }

        &-view {
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-up(md) {
                justify-content: flex-end;
            }
        }

        &-sorting {
            padding-top: 0px;
            margin-right: 11px;

            @include media-breakpoint-up(xl) {
                margin-right: 22px;
            }

            .dropdown__button {
                margin: 0;
                text-transform: none;

                @include media-breakpoint-up(md) {
                }

                @include media-breakpoint-up(lg) {
                    margin-bottom: -7px;
                    padding-right: 22px;
                }

                i {
                    margin-top: 2px;
                }
            }
        }
    }

    &__content {
        &--noprice {
            .product-thumbnail__price {
                display: none;
            }
        }
    }
}

.modal-add-skus {
    .modal-body {
        padding: to-rem(44px);
    }

    .modal-footer {
        padding: 0 to-rem(44px) to-rem(44px);

    }
}