﻿.section-title-block {

    h2,h6 {
        font-weight: 500;
    }

    &__wrap {
        padding: 0 15px;
    }

    h2 {
        margin-bottom: 34px;
        > p {
            display: inline;
        }
        .sectiontitleclass2 {
            @extend .h1;
        }
    }
    h6 {
        margin-bottom: 18px;
        font-size: to-rem(13px);
        line-height: to-rem(20px);
    }
    hr, p {
        margin-bottom: 35px;
    }
    hr {
        border-top: 3px solid $cl-beige;
        width: to-rem(100px);
    }

    &__desc {
        max-width: 812px;
        margin: 0 auto;
    }
}
