﻿.confirmation-page {
  background-color: $cl-very-light-pink;
  padding: 70px 0;

  &__container {
    max-width: 690px;
    background-color: $cl-white;
    margin: 0 auto;
    text-align: center;
  }

  &__content {
    max-width: 415px;
    margin: 0 auto;
    padding: 65px 0 89px 0;
  }

  &__number {
    font-weight: 500;
    margin-bottom: 21px;
  }

  &__download {
    margin-bottom: 45px;
  }

}

@include media-breakpoint-up(md) {
  .confirmation-page {
    padding: 132px 0;
  }
}

