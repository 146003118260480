.container-block {

    .container {
        padding-left: 0;
        padding-right: 0;
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .row {
        margin-left: 0;
        margin-right: 0;

        @media screen and (min-width: $grid-size-1) {
            margin-left: -22px;
            margin-right: -22px;
        }

        & > div:not([class]) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
    }

    .text-block {
        width: 100%;
    }
}
