﻿.standard-page {
    &__container {
        background-color: #d0d1d6;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: -25px;
        margin-bottom: -50px;
    }

    &__banner {
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    &__hero, &__video {
        position: absolute;
        top: 0;
        width: 100%;
        min-height: 400px;
        z-index: 0;
        object-fit: fill;
        background-size: cover;

        .hero__gradient {
            position: absolute;
            width: 100%;
            height: 300px;
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 0%, #d0d1d6 100%);
        }
    }

    &__box {
        width: 70%;
        z-index: 1;

        &.box__half {
            margin-top: 300px;
        }

        &.box__full {
            margin-top: 600px;
        }
    }

    &__title {
        text-align: center;
        padding: 50px;
        font-family: Arial, 'Museo300-Regular', sans-serif;
    }

    &__content {
        margin-bottom: 4em;
        background-color: white;
        color: black;
        padding: 3.5em 4em;

        table {
            clear: left;
            background: none repeat scroll 0 0 #fff;
            font-size: 0.8em;
            margin-top: 16px;
            border-collapse: collapse;
            border-spacing: 0;
            font-size: 0.85em;
            margin-bottom: 15px;

            thead {
                tr {
                    th {
                        color: #fff;
                        font-size: 1.1em;
                        font-weight: bold;
                        padding: 10px;
                        text-align: left;
                    }

                    background: none repeat scroll 0 0 #575756;
                    color: #fff;
                    font-weight: bold;
                    vertical-align: top;
                }
            }

            tbody {
                tr {
                    td {
                        padding: 5px 10px;
                    }

                    &:nth-child(2n) {
                        background: none repeat scroll 0 0 #f1f3f4;
                    }
                }
            }
        }
    }

    @media (min-width: 320px) and (max-width: 768px) {
        &__box {
            width: 95%;
        }

        &__container .article__title h1 {
            font-size: 2rem;
        }

        &__content {
            padding: 1rem 1.2rem;

            h2 {
                margin-top: 1rem;
            }
        }
    }
}
