.signin {
    &-block {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 0;
    }

    &__form {
        width: 100%;
        max-width: 400px;

        &-header {
            text-align: center;         
            margin-bottom: to-rem(30px);
        }
    }

    &__remember-checkbox {
        margin-top: to-rem(-10px);
        display: flex;
        justify-content: flex-end;

        .custom-control-label {
            font-size: to-rem(13px);
        }
    }

    &__controls {
        display: flex;
        flex-direction: column;
        margin-top: to-rem(10px);

        .btn {
            width: 100%;

            align-self: center;

            i {
                font-size: 1.215em;
                margin-left: 5px;
            }
        }

        &-links {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: to-rem(12.5px);

            a+a {
                &:before {
                    content: "|";
                    margin: 0 1ch;
                    display: inline-block;
                    text-decoration: none;
                }
            }
        }

        &-help {
            margin-top: to-rem(25px);
            text-align: center;
        }
    }
}

.modal-sign-in {
    z-index: 9000;
    background-color: rgba(0,0,0,0.5);

    &.show {
        & ~ .modal-backdrop {
            opacity: 0;

           & + .modal-backdrop {
                opacity: 0.5;
            }
        }
    }   
}

.modal-backdrop + .modal-backdrop {
    opacity: 0;
}