.facets {
    position: relative;
    z-index: 2;
    top: 95px;

    @include media-breakpoint-up(lg) {
        top: 0;
    }


    &__title {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: 0.36px;
        text-align: center;
        justify-content: center;
        padding-top: 24px;
    }

    &__btn-close {
        cursor: pointer;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        position: absolute;
        left: 30px;
        top: 30px;
    }

    &__wrapper {
        padding: 18px 30px 100px;

        @include media-breakpoint-up(lg) {
            padding: 17px 0;
        }
    }

    &__content {

        @include media-breakpoint-up(lg) {
            background-color: $cl-white;
            border: 1px solid $cl-light-gray;
            padding: 34px 44px;
            width: 100%;
            height: auto;
            overflow: visible;
            position: absolute;
            top: 20px;
            z-index: 1;
            display: none;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 60px;
                top: -36px;
                border-top: solid transparent 18px;
                border-left: solid transparent 18px;
                border-right: solid transparent 18px;
                border-bottom: solid $cl-light-gray 18px;
                z-index: 1;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 60px;
                top: -35px;
                border-top: solid transparent 18px;
                border-left: solid transparent 18px;
                border-right: solid transparent 18px;
                border-bottom: solid $cl-white 18px;
                z-index: 2;
            }
        }

        @include media-breakpoint-up(xl) {
            padding: 34px 32px;
        }

        &--active {
            display: block;
        }
    }

    &__list {
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            max-height: 420px;
            overflow-y: auto;
            margin-bottom: 44px;
        }

        &-break {
            flex-basis: 100%;
            height: 0;
        }
    }

    &__count {
        font-size: to-rem(11px);
        color: rgba($cl-charcoal-gray, .5);
        padding-left: 2px;
    }

    &__group {
        list-style: none;
        padding: 0;
        margin: 0;
        flex-grow: 1;
        //flex-basis: 16.5%;

        @include media-breakpoint-up(lg) {

            &--SizeFacet {
                .facets__group-item {
                    width: 90px !important;
                }
            }
        }

        .facets-column {
            padding: 0;
            list-style: none;
        }

        /*&--size {
             &-0 {
                flex-grow: 1;
            }

            &-1 {
                flex-grow: 2;
            }

            &-2 {
                flex-grow: 3;
            }

            &-3 {
                flex-grow: 4;
            }

            &-4 {
                flex-grow: 5;
            } 

            &-5,
            &-6,
            &-7,
            &-8,
            &-9,
            &-10 {
                flex-basis: 100%;
            }
        }*/


        &-body {
            margin: 17px 0;

            @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                flex-grow: 1;
            }
        }

        &-title {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: 0.32px;
            text-align: left;
            color: $cl-black;

            @include media-breakpoint-up(lg) {
                font-size: 13px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.54;
                letter-spacing: 0.13px;
                text-align: left;
                color: $cl-charcoal-gray;
            }
        }

        &-item {
            margin-left: 14px;

            &-label {
                padding-left: 12px;
                font-size: 13px;
                letter-spacing: 0.13px;

                @include media-breakpoint-up(lg) {
                    font-size: 13px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: $cl-charcoal-gray;
                }
            }

            &-control {
                font-size: 13px;
                padding-left: 1rem;
            }
        }

        @include media-breakpoint-up(lg) {
            border-bottom: none;

            .selection--cm__expand,
            .selection--cm__collapse {
                display: none;
            }

            &-body {
                margin: 0 0 20px 0;
            }

            &-title {
                text-transform: uppercase;
                display: block;
            }

            &-dropdown {
                display: flex !important;
                flex-wrap: nowrap;
                /*max-height: 400px;*/
                height: auto !important;
            }

            &-item {
                display: block;
                margin-left: 0;
                margin-right: 20px;
                width: 250px !important;
                margin-bottom: 11px;

                &-label {
                    margin: 0;
                }
            }
        }
    }

    &__controls {
        @include media-breakpoint-down(lg) {
            background-color: $cl-white;
            border: solid 1px $cl-very-light-pink;
            padding: 20px 30px;
            height: 90px;
            width: 100%;
            display: block;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 10;

            .btn {
                width: 100%;
            }
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: flex-end;

            & > a {
                margin: 0 15px;
            }
        }

        &-clear {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 4;
            letter-spacing: normal;
            text-align: left;
            color: $cl-dark-gray-blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.facets-selected {
    border-bottom: 2px solid $cl-very-light-pink;
    margin-bottom: 11px;

    @include media-breakpoint-up(lg) {
        border-top: 1px solid $cl-light-gray;
        border-bottom: none;
        padding-top: 11px;
        margin-bottom: 0;
        margin-top: 11px;
        padding-right: 110px;
    }

    &__list {
        list-style: none;
        display: flex;
        justify-content: flex-start;
        padding: 0;
        flex-wrap: wrap;

        &-item {
            position: relative;
            margin-right: 11px;
            margin-top: 5px;
            background-color: $cl-white;
            border: solid 1px $cl-light-gray;
            border-radius: 32px;
            padding: 3px 18px;
            padding-right: 40px;
            cursor: pointer;
        }
    }

    &__tag {
        padding: 0;
        margin: 0;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        text-transform: none;
        white-space: nowrap;
        color: $cl-charcoal-gray;

        &-remove {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            text-align: right;
            width: 100%;
            height: 100%;

            svg {
                position: relative;
                top: 0;
                right: 13px;
                @include media-breakpoint-up(lg) {
                    top: 4px;
                }
            }
        }
    }

    &__clear {
        position: absolute;
        right: 30px;
        top: 30px;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        text-align: left;
        color: $cl-dark-gray-blue;
        text-decoration: underline;
        cursor: pointer;

        @include media-breakpoint-up(lg) {
            position: static;
            line-height: 32px;
            margin-top: 5px;
            display: block;
        }
    }

    &__amount {
        position: absolute;
        right: 0;
        top: 52px;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        text-align: right;
    }
}
