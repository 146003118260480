.sort-by {
    .dropdown__button {
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: 0.13px;
        text-align: left;
        color: $cl-charcoal-gray;     
        border-bottom: none;   
        background-color: transparent;

        i {          

            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.12;
            letter-spacing: normal;
            text-align: left;
            color: $cl-charcoal-gray;
        }

        @include media-breakpoint-down(md) {          
            padding: 0;
            margin: 0 30px;

            min-width: auto;

            text-transform: uppercase;

            i {
                position: relative;
                right: auto;
            }
        }
    }
}