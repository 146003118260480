﻿.reports-tab {
    padding: 1rem 22px;

    .btn.btn-link {
        font-size: .75rem;
        border-bottom: none;
    }

    .nav {
        &-tabs {
            border-bottom: 2px solid #294a62;
        }

        &-item {
            margin-bottom: 0;
        }

        &-link {
            text-decoration: none;
            border: none;

            &.active {
                background-color: #294a62;
                color: $white;
            }
        }
    }

    .tab-content {
        .filter-panel {
            padding: 1.25rem;
            border-bottom: 2px $code-color solid;

            @include media-breakpoint-down(sm) {
                padding: 1.25rem 0;
            }

            label {
                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }

            .btn.btn-primary {
                padding: .5rem 1.5rem;
                font-size: .75rem;
                margin: 0 .875rem;

                @include media-breakpoint-down(sm) {
                    margin: .875rem 0;
                    display: block;
                }
            }

            .btn.btn-link {
                text-decoration: underline;
            }
        }

        .dataTables {
            max-height: 800px;
            overflow-y: scroll;

            &_wrapper {
                th[class*="sorting"] {
                    cursor: pointer;
                }

                td, th {
                    padding: .75rem 1rem;
                }
            }

            &_paginate .page-link {
                line-height: normal;
                padding: 7px 11px;
            }

            &_processing {
                border: none;
            }
        }
    }
}
