﻿.style-guide {

    code {
        background-color: $cl-very-light-pink;
        display: block;
        font-weight: 400;
        overflow: auto;
        padding: 10px;
        white-space: pre;
    }

    .header {
        background-color: $cl-dark-gray-blue;
        padding: 96px 7% 89px 7%;
        position: relative;

        &__title {
            color: $cl-white;
            font-family: sans-serif;
            font-size: 44px;
            font-weight: 500;
            line-height: 54px;
        }

        &__small {
            color: $cl-very-light-pink;
            font-family: sans-serif;
            font-size: 22px;
            font-weight: 400;
            line-height: 30px;
        }
    }

    .content {
        &__h2 {
            border-bottom: 1px solid $cl-slate-gray;
            color: $cl-black;
            display: block;
            font-family: sans-serif;
            font-size: 38px;
            font-weight: 700;
            letter-spacing: 1.9px;
            line-height: 45px;
            margin-bottom: 80px;
            padding-bottom: 20px;
            text-transform: uppercase;
        }

        &__h3 {
            border-bottom: 1px solid $cl-slate-gray;
            color: $cl-black;
            display: block;
            font-family: sans-serif;
            font-size: 38px;
            font-weight: 700;
            letter-spacing: 1.9px;
            line-height: 45px;
            margin-bottom: 80px;
            padding-bottom: 20px;
            padding-left: 34px;
            position: relative;
            text-transform: uppercase;

            &:before {
                content: "\2013";
                left: 0;
                position: absolute;
                top: -3px;
            }
        }

        &__h4 {
            border-bottom: 1px solid $cl-slate-gray;
            color: $cl-black;
            display: block;
            font-family: sans-serif;
            font-size: 34px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 40px;
            margin-bottom: 60px;
            padding-bottom: 17px;
        }

        &__h5 {
            color: $cl-black;
            display: block;
            font-family: sans-serif;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 24px;
            margin-bottom: 20px;
        }
    }

    .palette {
        margin-bottom: 150px;

        &__swatch {
            height: 112px;
            margin-bottom: 43px;
            width: 112px;
        }

        p {
            color: $cl-black;
            font-family: sans-serif;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.63px;
            line-height: 20px;
            margin: 0;
        }

        strong {
            color: $cl-black;
            display: block;
            font-family: sans-serif;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.75px;
            line-height: 60px;
            margin: 0;
        }
    }


    .typography {
        margin-bottom: 150px;

        &__poppins_medium {
            color: $cl-charcoal-gray;
            display: block;
            font-family: "Poppins";
            font-size: 90px;
            font-weight: 500;
            letter-spacing: normal;
            line-height: 127px;
            margin-bottom: 13px;
        }

        &__poppins_regular {
            color: $cl-black;
            display: block;
            font-family: "Poppins";
            font-size: 90px;
            font-weight: 400;
            letter-spacing: 1.8px;
            line-height: 127px;
            margin-bottom: 13px;
        }

        &__kepler_std_light {
            color: $cl-black;
            display: block;
            font-family: "kepler-std";
            font-size: 90px;
            font-weight: 300;
            letter-spacing: 1.8px;
            line-height: 127px;
            margin-bottom: 13px;
        }

        &__mrsheffield-pro {
            color: $cl-black;
            display: block;
            font-family: "mrsheffield-pro";
            font-size: 90px;
            font-weight: 300;
            letter-spacing: 1.8px;
            line-height: 127px;
            margin-bottom: 13px;
        }

        p {
            color: $cl-black;
            font-family: sans-serif;
            font-size: 26px;
            font-weight: 300;
            line-height: 60px;
        }
    }

    .headings {
        td {
            padding: 50px 0;
            vertical-align: middle;
        }
    }

    .buttons {
        .btn-group {
            display: block;
        }
    }

    .jumbotron {
        p {
            font-family: sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 38px;
            margin: 0;
            text-align: center;
        }
    }
}
