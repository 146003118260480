.payment {
    &-page {
        &--step2 {
            padding-top: 40px;

            .payment-option__m-l {
                display: none;
            }
        }
    }

    &__header {
        margin: 16px 0 22px;
    }

    &__info {
        background-color: $cl-very-light-pink;
        padding: 22px;

        line-height: 1.54;
        font-size: 13px;

        margin-bottom: 22px;

        .btn {
            min-width: 240px;
        }

        dl {

            flex-basis: 50%;

            dt {
                font-size: 13px;
            }

            dd {
                font-size: 16px;
            }
        }

        &--red {
            color: $cl-error-2;
        }

        &-col {
            @include media-breakpoint-up(md) {
                border-left: 1px solid $cl-light-gray;
            }
        }
    }


    &__sidebar {
        @include media-breakpoint-up(md) {
            padding-left: 22px;
        }

        &-info {
            background-color: $cl-very-light-pink;
            padding: 22px;
            margin-top: 22px;
            margin-bottom: 22px;
        }
    }

    &__overview {
        margin-top: 16px;
        padding: 22px;
        border: 1px solid $cl-light-gray;

        &-list {
            max-height: 200px;

            overflow-y: auto;
            overflow-x: hidden;

            line-height: 1.84;
            padding: 11px 0;
        }

        &-submit {
            width: 100%;
        }
    }

    &__total {
        padding: 11px 0;
        border-top: 1px solid $cl-light-gray;
    }
}

.payments {

    &__daterange {

        &-wrap {
            position: relative;
        }

        &-input, .form-control:disabled, .form-control[readonly] {
            background-color: transparent;
            padding-right: 32px;
            cursor: pointer;
        }

        &-icon {
            position: absolute;
            cursor: pointer;
            right: 14px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            height: 26px;
            z-index: -1;
        }
    }

    &__history {
    }

    &__table {

        thead {
            th {
                border-bottom: 1px solid $cl-black;
                padding: 22px 11px;

                &.payments__amount-row {
                    padding-left: 22px;
                    width: 120px !important;
                }
            }
        }

        tbody {
            td {
                padding: 11px;
                vertical-align: middle;
                border-bottom: 1px solid $cl-light-gray;
            }

            tr {
                &.selected {
                    background-color: $cl-very-light-pink;
                }
            }
        }

        .dataTables_empty {
            font-size: 16px;
            text-align: center;
            padding: 44px;
            border-bottom: 0;
        }

        .custom-control {
            position: relative;
            top: -0.75rem;
        }
    }

    &__tooltip {
        top: -0.5em;
        position: relative;
    }

    &__amount {
        &-control {
            text-align: left !important;
            font-size: 13px;
            line-height: 1.54;
            border: 1px solid $cl-light-gray;
            transition: border 0.5s ease-out;

            @media (min-width : 1368px) {
                -webkit-appearance: none;
                width: 120px;
                background-color: transparent;
                border: 1px solid transparent;
            }

            &:focus {
                border: 1px solid $cl-light-gray;
            }
        }

        &--autofill {
            .payments__amount-control {
                transition: border 0.5s ease-out;
                border: 2px solid $cl-light-gray;
            }
        }

        &-row {
            
            // hide column if all columns show
            &:not([style*="display: none;"]) {
                & ~ .dtr-control {
                    display: none;
                }
            }
        }
    }


    &__total {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: $cl-white;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 100;
        padding: 11px;
        box-shadow: 0 -3px 6px 0 #00000029;

        @include media-breakpoint-up(md) {
            align-items: flex-end;
            position: relative;
            box-shadow: none;
            padding: 11px 0;
        }

        &-summary {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 11px;
            font-size: 13px;
            line-height: 1.54;

            @include media-breakpoint-up(md) {
                width: 340px;
                padding-bottom: 22px;
            }
        }

        &-value {
            display: inline-block;
            border: none;
            background-color: transparent;
            font-size: 13px;
            font-weight: 500;
            text-align: right;
            line-height: 1.54;
            margin-left: 22px;
            width: 148px;
            opacity: 1;
            color: $cl-black !important;

            @include media-breakpoint-up(md) {
                text-align: left !important;
            }
        }
    }
}