.product-thumbnail {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 22px;

    @include media-breakpoint-down(md) {
        max-width: 392px;
        margin: auto;
    }

    &.is-bestbet {
        background: $cl-very-light-pink;
        border: 1px solid #707070;
        padding: 5px;
        margin: 0px;

        @include media-breakpoint-up(md) {
            padding: 0.875rem;
            margin: -5px;
        }
    }

    &__best-bet {
        background: $cl-dusk;
        color: $cl-white;
        display: block;
        font-size: to-rem(10px);
        text-transform: uppercase;
        font-weight: 500;
        line-height: 1.6;
        max-width: 100%;
        padding: 4px 10px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    &_link {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;


        &-wrapper {
            position: relative;
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-up(md) {
                &:hover {
                    .product-thumbnail__quick-view {
                        display: flex;
                    }
                }
            }
        }
    }

    &__wishlist-cta {
        position: absolute;
        cursor: pointer;
        right: 5px;
        top: 5px;
        z-index: 1;
        color: $cl-dusky-purple;

        &:before {
            content: '\f004';
            font-family: 'Font Awesome 5 Pro';
            font-size: 25px;
            font-weight: 300;
            line-height: 1.28;
            letter-spacing: 1px;
            text-align: left;
        }

        &.is-wishlisted {
            &:before {
                font-weight: bold;
            }
        }

        i {
            font-size: 25px;
        }

        @include media-breakpoint-up(md) {
            right: 21px;
            top: 21px;

            @media (hover: hover) {
                &:hover {
                    &:before {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    &__quick-view {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 22px;
        flex-direction: column;
        justify-content: flex-end;

        &-cta {
            cursor: pointer;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: 0.8px;
            text-align: center;
            text-transform: uppercase;
            display: block;
            width: 100%;
            padding: 15px;
            background-color: rgba(255, 255, 255, 0.9);
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: normal;
        flex: 1 1 auto;
    }

    &__flags {
        display: flex;
        padding: 5px 0;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: absolute;
        top: 5px;
        left: 5px;

        @include media-breakpoint-up(md) {
            padding: 10px 0 3px;
            min-height: to-rem(39px);
        }

        .flag {
            font-size: 10px;
            display: inline-block;
            margin-right: 10px;
            white-space: nowrap;
            margin-bottom: 5px;

            &--exclusive {
                order: 5;
                background: $cl-dusk;
                color: $cl-white;
                padding: 0 5px;
                text-transform: uppercase;
            }

            &--new {
                order: 1;
                color: $cl-dusky-purple;

                &:before {
                    content: '';
                    background-image: url('/Assets/imgs/flags/new.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 11px;
                    height: 11px;
                    display: inline-block;
                    margin-right: 4px;
                    position: relative;
                    bottom: -1px;
                }
            }

            &--bestseller {
                color: $cl-dark-gray-blue;
                order: 2;

                &:before {
                    content: '';
                    width: 11px;
                    height: 11px;
                    background-image: url('/Assets/imgs/flags/bestseller.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: inline-block;
                    margin-right: 4px;
                    position: relative;
                    bottom: -1px;
                }
            }

            &--madeinusa {
                color: $cl-dusk;
                order: 6;

                &:before {
                    content: '';
                    background-image: url('/Assets/imgs/flags/americanmade.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 11px;
                    height: 11px;
                    display: inline-block;
                    margin-right: 4px;
                    position: relative;
                    bottom: -1px;
                }
            }

            &--recommended {
                background: $cl-dusky-purple;
                color: $cl-white;
                order: 4;
                padding: 0 5px;
                text-transform: uppercase;
            }

            &--sale {
                order: 3;
                background: $cl-dusky-purple;
                color: $cl-white;
                padding: 0 5px;
                text-transform: uppercase;
            }

            @include media-breakpoint-up(md) {
                font-size: 13px;
                margin-right: 0;
                margin-left: 10px;
            }
        }

        @include media-breakpoint-up(md) {
            justify-content: flex-end;
        }
    }

    &__name {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.24px;
        text-decoration: none;
        color: $cl-slate-gray;
        display: block;
        margin-top: 5px;


        @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 1.44;
            letter-spacing: 0.36px;
            color: $cl-slate-gray;
        }
    }

    &__sku {
        font-size: 10px;
        line-height: 1.6;
        letter-spacing: 0.5px;
        color: $cl-slate-gray;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: 10px;
            line-height: 1.56;
            letter-spacing: 0.8px;
            margin-top: 5px;
        }
    }

    &__price {
        font-size: 12px;
        line-height: 1;
        letter-spacing: 0.2px;
        color: $cl-black;
        display: block;
        flex: 1;
        margin-top: 10px !important;
        
        .price {
            font-weight: 500;
        }

        .price-avg {
            font-size: 12px;
            font-weight: 400;
            color: #B1B2B5;
            margin-left: 5px;
        }

        .price-discount {
            font-weight: 500;

            i {
                font-weight: 400;
                font-style: normal;
            }
        }

        .price-old {
            text-decoration: line-through;
            font-weight: 400;
            color: #B1B2B5;
        }

        .on-sale-label {
            color: $cl-black;
            font-weight: 500;
            i {
                color: $cl-dusky-purple;
                font-style: normal;
            }
            
        }

        &__buy-more {
            font-size: 12px;
        }

        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 1.56;
            letter-spacing: 0.32px;
        }
    }

    &__quantity {
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: flex-end;
            justify-content: stretch;
        }

        .quantity {

            @include media-breakpoint-up(md) {
                width: 50%;
                margin-right: 12px;
            }

            @include media-breakpoint-up(lg) {
                width: 40%;
                margin-right: 24px;
            }
        }

        .controls {
            background: transparent;
            display: block;
        }

        .quantity .controls__field {
            background: transparent;
        }

        small.form-text {
            font-size: 10px;
            line-height: 3;
            letter-spacing: 0.2px;
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                font-size: 13px;
            }
        }

        .quantity-controls-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__shop-now {
        flex-grow: 1;

        @include media-breakpoint-down(md) {
            padding: 15px;
            width: 100%;
        }
    }

    &__more {
        &-title {
            font-size: 11px;
            line-height: 2.31;
            letter-spacing: 0.65px;
            color: $cl-slate-gray;

            @include media-breakpoint-up(md) {
                font-size: 13px;
            }
        }

        &-cta {
            width: 100%;
            padding: 0.875rem 1rem;
            text-align: center;
        }
    }
}

@include media-breakpoint-down(md) {
    .upsell-pro__title {
        font-weight: 300;
        font-family: "kepler-std";
        font-size: 3.43rem;
        font-size: clamp(2.2rem, 4vw, 3.43rem);
        line-height: 1.14;
        letter-spacing: 0.7px;
        text-transform: capitalize;
        text-align: center;

        &:after {
            content: "";
            position: relative;
            height: 3px;
            background-color: $cl-beige;
            width: 7.8125rem;
            bottom: 20px;
            margin: 4rem auto;
            display: block;
        }
    }
}

@include media-breakpoint-up(lg) {
    .upsell-pro {
        padding-left: 1.25rem;
        margin-left: 3.75rem;
        border-left: 1px solid #ccc;

        &__title {
            text-transform: uppercase;

            &:after {
                display: none;
            }
        }

        .row {
            flex-direction: column;

            .col-xl-3 {
                max-width: 70%;
            }

            .product-thumbnail {
                margin-bottom: 23px;

                &:hover {
                    .product-thumbnail__image-wrapper:before {
                        visibility: visible;
                        opacity: 1;
                    }

                    .product-thumbnail__body {
                        color: white;
                        opacity: .95;

                        .product-thumbnail__name {
                            visibility: visible;
                            font-size: 0.875rem;
                            font-weight: 600;
                            text-align: center;
                            color: white;
                            margin: 0;
                        }

                        .product-thumbnail__price {
                            visibility: visible;
                            font-size: 0.75rem;
                            font-weight: normal;
                            text-align: center;
                            color: white;
                            margin: 0;
                        }
                    }
                }

                &__flags,
                &__quick-view,
                &__wishlist-cta,
                &__sku,
                &__quantity,
                &__more {
                    display: none;
                }

                &__image-wrapper {

                    &:hover &:before {
                        visibility: visible;
                        opacity: 1;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        visibility: hidden;
                        opacity: 0;
                        background: rgba($cl-dark-gray-blue, .95);
                        height: 100%;
                        width: 100%;
                    }
                }

                &__body {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }

                &__name {
                    visibility: hidden;
                }

                &__price {
                    visibility: hidden;

                    .price-old,
                    .price__buy-more {
                        display: none;
                    }

                    .price-discount {
                        color: white;
                    }
                }
            }
        }
    }
}