﻿@import 'base/colors';
@import 'base/mixins';
@import 'base/typography';
// Customization
//
// Use this stylesheet to OVERRIDE the value of a BOOTSTRAP value only.  
// Do NOT add CUSTOM variables here.
// Values suitable for override can be found in the stylesheets located in /Assets/scss/vendors/bootstrap-x.x.x/
//
// Styleguide 1.0.0.


// Styleguide page 
$code-color: $cl-dusky-purple;

$grid-size-1: 1634px;
$grid-size-2: 1410px;
$grid-gutter-width: 22px;

//vertical spacing
$vertical-s: 11px;
$vertical-m: 22px;
$vertical-l: 44px;
$vertical-el: 66px;

// Buttons
$btn-font-family: $sans-serif-font-stack;
$btn-font-size: 14px;
$btn-line-height: 18px;
$btn-border-radius: 0;
$btn-font-weight: 600;
$btn-padding-x: 45px;
$btn-padding-y: 15px;
$btn-active-box-shadow: none;
$btn-box-shadow: none;

//Links
$link-color: $cl-dark-gray-blue;
$link-decoration: underline;
$link-hover-color: $cl-dusky-purple;
$link-hover-decoration: underline;

//Forms
$input-border-radius: 0;
$input-border-color: $cl-light-gray;
$form-group-margin-bottom: to-rem(22px);
$form-feedback-invalid-color: $cl-error-2;
$form-check-input-margin-y: 0.2rem;
$form-grid-gutter-width: to-rem(22px);
$input-focus-box-shadow: none;
$input-focus-border-color: $cl-charcoal-gray;
$input-height: 50px;
$label-margin-top: 10px;
$label-margin-bottom: 6px;

$custom-checkbox-indicator-border-radius: 2px;
$custom-control-indicator-checked-bg: $cl-dark-gray-blue;

//Dropdowns
$dropdown-border-color: $cl-light-gray;
$dropdown-border-radius: 0;
$dropdown-border-width: 1px;
$dropdown-font-size: to-rem(14px);
$dropdown-item-padding-x: 15px;
$dropdown-item-padding-y: 10px;
$dropdown-link-color: $cl-charcoal-gray;
$dropdown-link-hover-bg: $cl-dark-gray-blue;
$dropdown-link-hover-color: $cl-white;
$dropdown-min-width: 10rem;
$dropdown-padding-y: 0;
$dropdown-spacer: -1px;

//Horizontal Rule
$hr-border-color: $cl-light-gray;

//Tables 
$table-border-color: $cl-charcoal-gray;
$table-border-width: 1px;

$modal-footer-border-width: 0;
$modal-header-border-width: 0;