﻿.bread-crumb {
    list-style-type: none;
    display: flex;
    margin: 1.125rem 0;
    font-size: 13px;
    line-height: 1.54;

    @media (max-width: 991.98px) {
        float: none;
        padding: 20px 10px;
    }

    & li {
        &:not(:last-child)::after {
            content: ">";
            margin-left: 20px;
            margin-right: 20px;

            @media (max-width: 991.98px) {
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        &:last-child {
            a {
                font-weight: 500;
            }
        }

        & a {
            text-decoration: none;
            color: inherit;
            font-weight: normal;

            &:hover {
                opacity: 0.8;
                text-decoration: underline;
                font-weight: 500;
            }
        }
    }
}
