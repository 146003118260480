﻿.social-media-container {
    display: flex;
    flex-wrap: wrap;
    .block.socialmediablock {
        flex: 1;
        @include media-breakpoint-down(sm) {
            flex: 0 50%;
        }
    }
    @include media-breakpoint-down(sm) {
        .block.socialmediablock:nth-of-type(4) ~ .block.socialmediablock {
            display: none;
        }
    }
}
