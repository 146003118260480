.password-field {
    position: relative;

    &__icon {
        position: absolute;
        right: 11px;
        top: 16px;
        width: 20px;

        svg {
            pointer-events: none;
        }
    }

    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }

    input::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
    }
}

// Hide the eye icon when the field is marked invalid so that it doesn't overlap the red 'x' that 
// Bootstrap adds.
.was-validated .password-field__icon {
    display: none;
}