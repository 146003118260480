﻿.product-thumbnail-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    width: 100%;

    &_m-6 {
        margin-bottom: 6px;
    }

    .product-thumbnail {
        &_product-type {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            padding: 10px 0 3px;

            @include media-breakpoint-up(md) {
                min-height: to-rem(39px);
            }

            &--category {
                align-items: center;
                display: flex;
                justify-content: center;
                font-size: 10px;
                font-weight: 500;
                padding-left: 5px;
                padding-bottom: 5px;
                letter-spacing: 0.65px;
                line-height: 1;

                @include media-breakpoint-up(md) {
                    font-size: 13px;
                    padding-left: 15px;
                }
            }

            .new-product-icon {
                color: $cl-dusky-purple;
            }

            .bestseller-product-icon {
                color: $cl-dark-gray-blue;
            }

            .american-made-product-icon {
                color: $cl-dusk;
            }


            @include media-breakpoint-down(sm) {
                font-size: 10px;
            }

            @include media-breakpoint-up(md) {
                justify-content: flex-end;
            }

            .flag--2 {

                &.bestseller-product-icon:before {
                    content: '';
                    width: 11px;
                    height: 11px;
                    background-image: url(/Assets/imgs/flags/bestseller.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: inline-block;
                    margin-right: 4px;
                    position: relative;
                    bottom: -1px;
                    margin-right: 5px;
                }

                &.american-made-product-icon:before {
                    content: '';
                    width: 11px;
                    height: 11px;
                    background-image: url(/Assets/imgs/flags/americanmade.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: inline-block;
                    margin-right: 4px;
                    position: relative;
                    bottom: -1px;
                    margin-right: 5px;
                }

                &.new-product-icon:before {
                    content: '';
                    width: 11px;
                    height: 11px;
                    background-image: url(/Assets/imgs/flags/new.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: inline-block;
                    margin-right: 4px;
                    position: relative;
                    bottom: -1px;
                    margin-right: 4px;
                }
            }

            .hidden-sm-down {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .hidden-md-up {
                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }

        &_image {
            position: relative;
            text-align: center;
        }

        &_product-name {
            display: block;
            margin-bottom: 6px;
            text-decoration: none;
        }

        &_product-sku {
            font-size: 10px;
            margin-bottom: 0px;

            @include media-breakpoint-up(sm) {
                font-size: 16px;
            }
        }

        &_product-price {
            font-size: 10px;
            margin-bottom: 6px;
            flex: 1;

            @include media-breakpoint-up(sm) {
                font-size: 16px;
            }
        }

        &_product-color {
            font-size: 10px;
            font-weight: normal;
            letter-spacing: 0.65px;

            @include media-breakpoint-up(md) {
                font-size: 13px;
            }
        }

        &_product-form {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .form-group {
                margin-bottom: 0px;
                width: 100%;

                .controls {
                    background: transparent;
                    text-align: center;
                    padding: 10px 0 15px 0;
                    width: 100%;
                    display: block;

                    @include media-breakpoint-up(lg) {
                        background: transparent;
                        font-size: 13px;
                        width: 86%;
                    }

                    .qty-minus {
                        float: left;
                    }

                    input {
                        background: transparent;
                        text-align: center;
                    }

                    .qty-plus {
                        float: right;
                    }
                }

                @include media-breakpoint-up(lg) {
                    width: 42%;
                }
            }

            .btn-productform {
                width: 100%;

                a {
                    width: 100%;
                }

                @include media-breakpoint-up(lg) {
                    width: 58%;
                }
            }

            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }
        }
    }

    .btn-more {
        padding: 0.875rem 1rem;
    }

    .btn-wishlist {
        position: absolute;
        cursor: pointer;
        right: 5px;
        top: 5px;
        z-index: 1;
        color: $cl-dusky-purple;

        &.is-wishlisted{
            svg {
                fill: $cl-dusky-purple;
            }
        }

        @include media-breakpoint-up(md) {
            right: 21px;
            top: 21px;

            &:hover {
                svg {
                    fill: $cl-dusky-purple;
                }
            }
        }
    }
}
