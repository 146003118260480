﻿.feature-box {
    display: inline-flex;

    &__icon {
        border: 1px black solid;
        border-radius: 25px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
    }

    &__content {
        padding: 10px 0 15px 10px;
        font-size: 15px;
        text-transform: uppercase;
    }
}
