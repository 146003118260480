﻿.my-account-layout {
    padding-bottom: 44px;
}

.profile-page {
    &__header {
        align-items: center;
        margin: 12px 0;

        h1 {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(sm) {
            margin: 2.43rem 0 2.81rem;
        }
    }

    &__subhead {
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 0.16px;
    }

    &__area {

        &-header {
            padding: 14px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-cta {
            padding: 0;
            font-size: 13px;
            font-weight: 500;
            line-height: 1.54;
            text-transform: uppercase;
            text-decoration: none;
            background-color: $cl-white;
            border: none;
            cursor: pointer !important;

            &:focus {
                outline: none;
            }

            &--left {
                i {
                    margin-right: 10px;
                }
            }

            &--right {
                i {
                    margin-left: 10px;
                }
            }
        }

        &-content {
            padding-top: 22px;
            border-top: solid 1px $cl-charcoal-gray;
        }
    }
}

.edit-profile {
    &-page {

        .tab-content {
            width: 100%;
        }

        .custom-checkbox {

            @include media-breakpoint-up(lg) {
                flex-basis: calc(33% - 11px);
                margin-right: 11px;
            }

            @include media-breakpoint-up(xl) {
                flex-basis: calc(25% - 11px);
                margin-right: 11px;
            }
        }


        .validation-message {
            flex-basis: 100%;
        }

        .validation-error {
            display: block;
            margin-bottom: to-rem(10px);
            font-size: to-rem(13px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: to-rem(0.13px);
            text-align: left;
            color: $cl-error-2;
        }

        .form-control.validation-error {
            border-color: $cl-error-2;
        }
    }

    &__tax-form {

        &--complete {
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.31;
            letter-spacing: normal;
        }

        &--incomplete {
            color: $cl-error-2;
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.31;
            letter-spacing: normal;
        }
    }

    &__tax-doc {
        border: dashed 1px $cl-light-gray;
        text-align: center;
        padding: 22px;
    }

    &__channel {
        flex-wrap: wrap;

        .custom-radio {
            margin-right: 22px;
        }
    }

    &__shipping {
        display: block;

        @include media-breakpoint-up(md) {
            display: flex;
        }

        .form-control {
            min-width: 100px;
        }

        &-carrier {
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: .02rem;
            line-height: 2.9;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        p {
            margin-bottom: 0;
        }

        .btn {
            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-top: 10px;
            }
        }
    }

    &__password {
        &-controls {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: wrap;
            margin-top: .5rem;

            @include media-breakpoint-down(sm) {
                .btn-primary {
                    order: 0;
                }

                .btn-link {
                    order: 1;
                }
            }

            .btn {
                width: 100%;

                &-link {
                    border: 0;
                    font-size: .8125rem;
                    padding: 0.875rem 2.5rem;
                    color: $cl-charcoal-gray;
                }

                @include media-breakpoint-up(sm) {
                    width: auto;
                    margin: 0;

                    & + .btn {
                        margin-left: 11px;
                    }
                }
            }
        }
    }

    &__modal {
        .modal-content {
            border-radius: 0;
        }

        .modal-body {
            padding: 45px 45px 10px;
            text-align: center;

            h5 {
                white-space: nowrap;
            }
        }

        .modal-footer {
            padding: 0 45px 35px;
            border-top: none;
            flex-direction: column;

            @include media-breakpoint-up(sm) {
                justify-content: space-between;
                flex-direction: row;
            }
        }

        @include media-breakpoint-down(sm) {
            .btn {
                width: 100%;
                margin: 0;

                & + .btn {
                    margin-top: 11px;
                }
            }
        }
    }
}

.customer-act {
    white-space: nowrap;
}

.customer-view-page {
    padding-top: 44px;
}
