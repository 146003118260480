.forgot-password-page {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding: 100px 0;
}

.forgot-password__form {
 
    &-body {
        width: 100%;
        max-width: 450px;
    }

    h3 {
        text-align: center;
    }

    &-text {
        margin-top: to-rem(24px);
        text-align: center;
        margin-bottom: to-rem(22px);
    }

    &-return {
        padding-top: to-rem(26px); 
        text-align: center;

        a {
            font-size: to-rem(13px);
        }
    }

    .btn {
        width: 100%;       
    }
}

.forgot-password__confirm {
    width: 100%;
    max-width: 450px;

    text-align: center;
    
    &-title {
        font-size: to-rem(60px);
    }

    h3 {
        margin-top: to-rem(25px);       
        font-size: clamp(1.625rem, 1.6vw, 2.375rem); 
    }

    p {
        margin-top: to-rem(31px);       
    }

    &-return {
        padding-top: to-rem(24px);       
    }

    &-support {
        padding-top: to-rem(43px); 
    }
}