﻿.category-page {
    margin: auto;
    max-width: 1634px;

    &__title {
        margin-top: 35px;
        margin-bottom: 22px;

        &:after {
            content: '';
            display: block;
            width: 75px;
            height: 2px;
            background-color: $cl-beige;
            margin-top: 13px;

            @include media-breakpoint-up(md) {
                width: 115px;
            }
        }
    }

    &__facet-controls {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 50px;
        background-color: $cl-very-light-pink;

        margin: 0 -15px 30px;

        @include media-breakpoint-up(md) {
            display: block;
            height: auto;
            background-color: transparent;
            margin: 0;
            position: relative;
        }
    }

    &__filters {
        z-index: 10;

        @include media-breakpoint-up(md) {
            margin: 20px 0 27px;
        }

        &-btn {
            cursor: pointer;

            @include media-breakpoint-down(md) {
                border: 0;
                padding: 0;
                margin: 0 30px;

                font-size: 13px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.54;
                letter-spacing: 0.13px;
                text-align: left;
                color: $cl-charcoal-gray;
            }
        }

        &-wrapper {
            z-index: 10;

            @include media-breakpoint-down(md) {
                display: none;
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                overflow-y: scroll;
                background-color: $cl-white;

                &--active {
                    display: block;
                }
            }
        }
    }

    &__sort-by {
        @include media-breakpoint-up(md) {
            position: absolute;
            right: 0;
            top: 15px;
        }
    }

    .toolbar {
        margin-bottom: 15px;
    }

    &-noCrumbs {
        .breadcrumb {
            display: none;
        }
    }

    &-products {
        margin-bottom: 44px;

        .section-title-block {
            margin: 0 -15px;
        }
    }

    &__noresults {
        &-query {
            text-align: center;
            padding: 44px 0;

            @include media-breakpoint-up(md) {
                padding: 55px 0;
            }

            h3 {
                margin-bottom: 17px;
            }

            .h7 {
                display: block;
                margin-bottom: 22px;
            }
        }

        .suggested-search {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: 0.32px;

            a {
                color: $cl-black;
            }
        }

        .search-tips {
            border-top: 1px solid $cl-light-gray;
            border-bottom: 1px solid $cl-light-gray;
            font-size: 18px;

            @include media-breakpoint-up(md) {
                padding: 22px 0;
            }

            &__wrapper {
                max-width: 530px;
                margin: 0 auto;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    line-height: 26px;
                    font-size: 18px;
                }
            }

            p {
                font-size: 18px;
            }
        }

        .search__recomended {
            padding: 70px 0 0;

            .section-title-block {

                h2 {
                    font-family: $sans-serif-font-stack;

                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;

                    text-align: center;
                    font-size: 20px;
                    line-height: 1.5;
                    letter-spacing: 0.4px;
                    margin-bottom: 0;

                    @include media-breakpoint-up(md) {
                        font-size: 24px;
                        line-height: 1.42;
                        letter-spacing: 0.48px;
                    }

                }
            }
        }
    }

    &__description {
        margin: 0 auto 44px;

        font-size: 14px;
        line-height: 1.57;
        letter-spacing: 0.28px;
        text-align: center;

        @include media-breakpoint-up(md) {
            max-width: 925px;
            margin: 0 auto 50px;
            font-size: 16px;
            line-height: 1.63;
            letter-spacing: 0.32px;
        }
    }
}

.category {
    &-name {
        color: $cl-charcoal-gray;
        font-size: 18px;
        font-weight: normal;
        line-height: 1.44;
        letter-spacing: 1.08px;
        margin-top: 22px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
    }

    &-page-data {
        display: flex;
        flex-wrap: wrap;
        padding-top: 5px;
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            padding-bottom: 80px;
        }
    }

    &-thumbnail {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 44px;
        padding-right: 11px;
        padding-left: 11px;
        width: 50%;
    }

    &-header {
        padding: 5px 11px 25px;
    }

    &-image {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    &-cta {
        font-size: 18px;
        font-weight: normal;
        line-height: 1.44;
        letter-spacing: 1.08px;
        text-align: center;
    }
}


@media (max-width: 991.98px) {
    .category-page {
        &__facets {
            order: 1;
        }
    }
}