.reorder {

    &__controls {
        padding-bottom: 22px;
        border-bottom: 1px solid $cl-light-gray;
        margin-bottom: 22px;

        &-view {
            display: flex;
            justify-content: center;
            align-items: center;

            &.left {
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
            }

            &.right {
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
            }
        }

        .form-control {
            display: inline-block;
            width: auto;
            padding-right: 30px;
            text-align: center;
            margin: 10px;
        }

        .label {
            display: none;
            font-size: to-rem(16px);
            font-weight: normal;
            text-transform: none;

            @include media-breakpoint-up(md) {
                display: inline-block;
            }
        }
    }
}
