﻿.checkout {
    max-width: 1410px;
    margin: 20px auto 0;
    padding: 0 22px 22px;

    &__top {
        margin-bottom: 27px;
    }

    &__error {
        padding: 15px;
        background-color: #F2DEDE;
        color: #B94A48;
        margin-bottom: 20px;

        &--top-m {
            margin-top: 20px;
        }
    }

    &__separator {
        width: 100%;
        height: 1px;
        background-color: $cl-charcoal-gray;
        margin: 14px 0 22px;

        &--light-grey {
            background-color: $cl-light-gray;
        }
    }

    &__left {
        max-width: 930px;
    }

    &__address-form {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s linear;

        &-pencil {
            margin-right: 8px;
        }

        &.show {
            max-height: 1040px;
        }

        &[data-type='edit'] {
            .checkout__address-form-edit-elem {
                display: block;
            }

            .checkout__address-form-add-elem {
                display: none;
            }
        }

        &[data-type='add'] {
            .checkout__address-form-edit-elem {
                display: none;
            }

            .checkout__address-form-add-elem {
                display: block;
            }
        }

        &-tab-btns {
            margin-bottom: 25px;

            a:first-child {
                margin-right: 10px;
            }
        }

        &-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            a {
                margin-right: 20px;
            }
        }
    }

    &__payments {
        margin-bottom: 44px;
    }

    &__po {
        margin-bottom: 44px;

        .checkout__separator {
            margin-bottom: 12px;
        }

        .form-group {
            max-width: 450px;
        }
    }

    &__notes {
        &-header {
            display: flex;
            justify-content: space-between;
        }

        &-title {
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 15px;
        }

        &-text {
            width: 100%;
            min-height: 210px;
        }
    }

    &__summary {
        border: 1px solid $cl-light-gray;
        padding: 26px 18px 17px;
        margin-top: 13px;
        margin-bottom: 22px;

        &-item {
            display: flex;
        }

        &-title {
            font-size: to-rem(13px);
            font-weight: 500;
            width: 500px;
        }

        &-value {
            font-size: to-rem(13px);

            &-red {
                color: #d11843;
            }
        }

        &-tooltip {
            font-size: to-rem(13px);
        }

        .jsCheckoutSubmit:after {
            content: '\f061';
            font-family: 'Font Awesome 5 Pro';
            font-weight: 300;
            font-size: 1rem;
            padding-left: 10px;
            padding-top: 1px;
        }
    }

    &__submit {
        margin-left: 16px;

        .fa-arrow-right {
            font-size: to-rem(16px);
        }
    }

    &__help-text {
        margin: 22px 0;
        padding: 22px;

        h1, h2, h3, h4, h5, h6, h7, a {
            text-decoration: none;
            line-height: 1.44;
            font-family: $sans-serif-font-stack;
            font-weight: 500;
            font-size: to-rem(18px);
            color: $cl-charcoal-gray;
            margin-bottom: 1rem;
            letter-spacing: normal;
        }
    }
}

.shipping-address {
    padding: 12px 12px;
    border: 1px solid $cl-light-gray;
    margin-bottom: 25px;

    &__def-label {
        font-weight: 500;
        font-size: to-rem(14px);
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__modal {
        .modal-header {
            padding: 32px 16px 25px;
        }

        .modal-body {
            padding: 25px 0 16px 44px;
            margin-right: 16px;
            max-height: 500px;
            overflow: auto;
        }

        .modal-footer {
            padding: 16px 44px 44px 44px;
        }
    }
}

.billing-address {
    padding: 12px 12px;
    border: 1px solid $cl-light-gray;
    margin-bottom: 25px;

    &__def-label {
        font-weight: 500;
        font-size: to-rem(14px);
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.payment-option {
    padding: 15px 33px;
    border: 1px solid $cl-light-gray;
    margin-bottom: 22px;
    cursor: pointer;

    &--selected {
        border: 1px solid $cl-dusky-purple;
    }

    .form-group {
        margin-bottom: 0;
    }

    &__m-l {
        margin-left: 35px;
    }

    &__card {
        i {
            margin-right: 8px;
            font-size: 22px;
        }
    }

    .svg-inline--fa {
        vertical-align: middle;
    }

    .custom-control .custom-control-label {
        .tool-tip {
            @include media-breakpoint-down(sm){
                position:relative !important;
            }
        }
    }
}

.instructions {
    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    .checkbox {
        margin-right: 20px;
        min-width: 30%;
    }
}

.request-dates {
    margin-bottom: 25px;

    &__input {
        &-wrap {
            max-width: 350px;
            position: relative;
        }
    }

    &__icon {
        position: absolute;
        cursor: pointer;
        right: 14px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: 26px;
    }

    &__calendar {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: white;
        z-index: 10;
        display: none;

        &--open {
            display: block;
        }
    }

    &__date {
        .field-validation-valid {
            display: none;
        }
        .field-validation-error {
            display: block;
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        padding: 20px 15px 20px;
        border: 1px $color-calendar-border solid;
    }
}

@include media-breakpoint-up(md) {
    .checkout {
        left: auto;
        right: 47px;

        &__form {
            display: flex;
        }

        &__left {
            margin-right: 75px;
            flex: 1;
        }

        &__right {
            flex: 1;
            max-width: 405px;
        }

        &__summary {
            &-container {
                max-width: 380px;
            }

            &-title {
                width: 222px;
            }
        }

        &__address-form {
            &.show {
                max-height: 1000px;
            }
        }
    }

    .shipping-address {
        padding: 24px 28px;
        border: 1px solid $cl-light-gray;

        &__address {
            display: flex;
        }

        &__def-label {
            font-size: to-rem(16px);
            margin-right: 15px;
        }
    }

    .billing-address {
        padding: 24px 28px;
        border: 1px solid $cl-light-gray;

        &__address {
            display: flex;
        }

        &__def-label {
            font-size: to-rem(16px);
            margin-right: 15px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .checkout {
        &__form {
            display: flex;
        }

        &__summary-wrapper {
            position: sticky;
            top: 0;
            background-color: $cl-white;
        }
    }

    .request-dates {

        &__range {
            padding: 24px 28px;
            border: 1px solid $cl-light-gray;
            margin: 0;
        }

        &__date {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;

            span {
                flex: 1 1 100%;
            }
        }

        &__label {
            width: fit-content;
            flex: 0 0 auto;
            margin-right: 11px;
        }

        &__input {
            flex: 1 1 0;

            &-wrap {
                flex: 1;
                max-width: 242px;
            }
        }
    }
}
