﻿.signin {
    &-page {
        .signin-block {           

            @include media-breakpoint-up(md) {
                padding: 70px 0;
            }
    
            @include media-breakpoint-up(xl) {
                padding: 90px 0;
            }
        }
    }   
}