﻿.storeLocatorModal {
    .close {
        top: 0px;
        right: 0px;

        span {
            position: absolute;
            top: 15%;
            left: 25%;
        }
    }

    .modal-content {
        padding: 32px 10px;
    }

    .modal-header {
        display: block;
        padding-bottom: 8px;

        p {
            margin-bottom: 0;
        }
    }

    .modal-body {
        input {
            height: 50px;
            width: calc(100% - 50px);
        }

        form {
            display: flex;
        }

        button {
            margin-left: -1px;
            padding: 10px;
            background-color: $cl-dusk;
            border: 2px solid $cl-dusk;
            color: $cl-white;
        }
    }
}
