﻿/* Searchbox in Header */
$text-font-size: 1rem;
.searchbox {

    &__product-list {
        max-height: 45vh;
        overflow-y: auto;
    }

    &__row {
        width: 100%;
        padding: 12px 20px;
        display: flex;

        &:not(last-child) {
            border-bottom: 1px solid #eee;
        }
    }

    &__heading {
        text-transform: uppercase;
        background: #F9F9F9;
        padding: 12px 20px;
    }

    &__product {
        margin-left: 10px;
        flex: 1;

        &-price {
            font-weight: 500;
            display: inline-block;
            margin-top: 10px;
        }
    }

    &__product-img {
        width: 60px;
        display: inline-block;
    }

    &__product-name {
        font-size: 0.875rem;
        line-height: 1rem;
        color: black;
        display: block;

        &:hover {
            color: black;
        }
    }

    &__notfound {
        font-style: italic;
        margin-bottom: 0;
    }

    .icon-left {
        position: absolute;
        left: 8px;
        top: 27px;
        z-index: 1000;
    }

    .icon-right {
        position: absolute;
        right: 8px;
        top: 27px;
        cursor: pointer;
    }
}

.searchbox-popover {
    width: 100vw;
    left: 0;
    top: 0;
    position: absolute;
    min-height: 80px;
    box-shadow: 0 3px 4px rgba(0,0,0,0.25);
    z-index: 9999;
    background: #fff;
    font-size: $text-font-size;
    line-height: 16px;
    transform: translate3d(0, 50px, 0) !important; // overiding library styles

    @include media-breakpoint-up(lg) {
        width: 100%;
    }
}

/* Searchbox widget */
.search-box {
    border: 1px solid #ddd;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > input[type=text] {
        font-size: 16px;
        padding: 12px 20px;
        border: 0;
        flex-grow: 1;

        &:focus {
            border: 0;
            outline: 0;
        }
    }

    & > a {
        height: 100%;
        padding: 15px 30px;
        flex-grow: 0;
        border-left: 1px solid #ddd;

        &:hover {
            background-color: lightgrey;
            color: white;
        }
    }
}
