﻿label {
    font-family: $sans-serif-font-stack;
    font-size: to-rem(13px);
    font-weight: 500;
    letter-spacing: to-rem(0.13px);
    line-height: to-rem(20px);
    margin-top: $label-margin-top;
    margin-bottom: $label-margin-bottom;
    text-transform: uppercase;
    cursor: pointer;
}

select.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-angle-down fa-w-10' aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' data-fa-i2svg=''%3E%3Cpath fill='currentColor' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    cursor: pointer;

    &.select-menu {
        border: 1px solid $cl-light-gray;
        border-radius: 0;
        height: 50px;
        font-size: 1rem;
    }
}

.input-validation-error {
    border-color: $cl-error-2;
}

.field-validation-error {
    display: block;
    margin-top: to-rem(10px);
    margin-bottom: to-rem(10px);
    font-size: to-rem(13px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: to-rem(0.13px);
    text-align: left;
    color: $cl-error-2;
}

.form-row .btn,
.form-row .form-control {
    height: to-rem(50px);
}

.formcontainerblock {
    max-width: 100%;
    margin: 0 auto;
    background: $cl-very-light-pink;
    padding: 55px;

    @include media-breakpoint-up(lg) {
        max-width: 50%;
    }

    &__info {
        @include media-breakpoint-down(sm) {
            padding: 0 10px;
        }
    }

    .EPiServerForms {
        .hide {
            display: none !important;
        }

        .Form__Element {
            font-size: 0.875rem;
            margin: 0 1rem;
        }

        .Form__MainBody {
            .block {
                margin-bottom: 22px;
            }

            section {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .Form__Element__ValidationError {
            font-size: 0.812rem;
        }

        .FormSubmitButton {
            padding: 15px 45px;
            font-size: .875rem;
            line-height: 18px;
            border-radius: 0;
            border: 2px solid transparent;
            letter-spacing: 1.05px;
            text-transform: uppercase;
            text-decoration: none;
            background-color: $cl-dark-gray-blue;
            border-color: $cl-dark-gray-blue;
            color: $cl-white;
            width: auto;

            &:hover {
                background-color: $cl-dusky-purple;
                border-color: $cl-dusky-purple;
                color: $cl-white;
            }

            &:not(:disabled):not(.disabled):active {
                background-color: $cl-dark-gray-blue;
                border-color: $cl-dark-gray-blue;
            }
        }

        .block.submitbuttonelementblock {
            align-items: center;
            display: flex;
            vertical-align: bottom;

            @include media-breakpoint-up(sm) {
                justify-content: flex-end;
            }
        }

        .choiceelementblock .radio,
        .choiceelementblock .checkmark {
            position: initial;
            top: auto;
            left: auto;
            height: auto;
            width: auto;
            border: 0;
        }

        .Form__Element .Form__Element__Caption {
            margin-top: .5rem;
        }

        .FormSelection .select {
            background-color: white;
            width: 100%;
            padding: 8px;
            height: 50px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            cursor: pointer;
            border-radius: 0;
            border: 1px solid #ddd;

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            /* SVG background image */
            background-image: url("data:image/svg+xml,%3Csvg class='svg-inline--fa fa-angle-down fa-w-10' aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' data-fa-i2svg=''%3E%3Cpath fill='currentColor' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'%3E%3C/path%3E%3C/svg%3E");
            background-position: right .7em top 50%, 0 0;
            background-size: .65em auto, 100%;
            background-repeat: no-repeat;
            background-color: #fff;

        }

        .Form__CustomInput,
        .FormTextbox__Input,
        .FormRange__Input {
            padding: 10px;
            font-size: 0.875rem;
            font-weight: 400;
            border: 1px solid #ddd;
            width: 100% !important;
            -moz-appearance: textfield;
        }

        .FormCaptcha__Image {
            margin-top: .5rem;
            width: 100%;
        }


        .FormCaptcha__Refresh,
        .FormResetButton {
            display: inline-block;
            border-radius: 0;
            min-width: 100px;
            font-size: $text-font-size;
            line-height: $text-font-size;
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;
            vertical-align: middle;
            padding: 12px 20px;
            border: 1px solid black;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: transparent;
            -webkit-transition: color 0.15s;
            -o-transition: color 0.15s;
            transition: color 0.15s;
            text-decoration: none;
            min-height: 40px;
            min-width: 100px;
        }

        .Form__NavigationBar__Action {
            width: auto !important;
            height: auto !important;
            display: inline-block !important;
            border-radius: 0 !important;
            min-width: 100px !important;
            font-size: $text-font-size !important;
            line-height: $text-font-size;
            font-weight: 400;
            text-transform: uppercase;
            text-align: center !important;
            vertical-align: middle !important;
            padding: 12px 20px !important;
            border: 1px solid black !important;
            -webkit-user-select: none !important;
            -moz-user-select: none !important;
            -ms-user-select: none !important;
            user-select: none !important;
            background-color: transparent !important;
            -webkit-transition: color 0.15s !important;
            -o-transition: color 0.15s !important;
            transition: color 0.15s !important;
            text-decoration: none !important;
            min-height: 40px !important;
            min-width: 100px !important;
            background-image: none !important;
        }

        .FormFileUpload__Input {
            display: inline-block;
            border-radius: 0;
            min-width: 100px;
            font-size: $text-font-size;
            line-height: $text-font-size;
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;
            vertical-align: middle;
            padding: 12px 20px;
            border: 1px solid #ddd;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: transparent;
            -webkit-transition: color 0.15s;
            -o-transition: color 0.15s;
            transition: color 0.15s;
            text-decoration: none;
            min-height: 40px;
            width: 100%;
        }

        .FormRange__Min,
        .FormRange__Max {
            position: relative;
            top: -15px;
            font-size: 18px;
        }

        .Form__Element .checkbox input[type="checkbox"] {
            display: block;
            float: left;
            margin-right: 9px;
            margin-top: 4px;
        }

        .FormChoice > div.checkbox {
            text-align: left;
            padding-left: 0px;
            margin-top: 8px;
        }
    }

    &__info {
        p {
            margin-bottom: 0px;
        }
    }
}

.form-group {
    margin-bottom: 10px;
}