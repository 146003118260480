﻿.blog-item {
    font-size: 16px;

    &__image {
        width: 100%;
    }

    .section-title-block__wrap {
        h2 {
            font-family: $sans-serif-font-stack;
            font-size: to-rem(26px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.37;
            letter-spacing: normal;


            p {
                font-family: $sans-serif-font-stack;
                font-size: to-rem(26px);
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.37;
                letter-spacing: normal;
            }

            @include media-breakpoint-up(md) {
                font-size: to-rem(38px);

                p {
                    font-size: to-rem(38px);
                }
            }
        }


    }
}

.blog-landing {
    &__container {

        margin: 0 auto;
    }
}