.reset-password-page {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding: 100px 0;
}

.reset-password__form {
    width: 100%;
    max-width: 450px;

    &-title {
        text-align: center;
        margin-bottom: to-rem(26px);
    }

    &-requirments {
        list-style-type: none;

        padding: to-rem(7px) 0;
        margin-bottom: 0;

        font-size: to-rem(13px);
    }

    .btn {
        width: 100%;
    }
}

.reset-password__confirm {
    width: 100%;
    max-width: 450px;

    text-align: center;
    
    &-title {
        font-size: to-rem(60px);
    }

    h3 {
        margin-top: to-rem(25px);       
        font-size: clamp(1.625rem, 1.6vw, 2.375rem); 
    }

}