.header__desktop-nav {
    border-bottom: 1px solid $cl-light-gray;

    .nav-primary {
        position: relative;

        .nav-item:hover .nav-link,
        .nav-item:focus .nav-link {
            color: $cl-black;

            &:after {
                width: 100%;
                transition: width linear .3s;
            }
        }

        .sub-nav-item-dropdown li a {
            position: relative;

            &:after {
                bottom: -4px;
            }
        }

        .sub-nav-item:hover span,
        .sub-nav-item:focus span,
        .sub-nav-item.active span {
            color: $cl-dusky-purple;

            &:after {
                width: 100%;
                transition: width linear .3s;
            }
        }

        ul {
            list-style: none;
            padding-left: 0;
            display: inline;
        }

        .dropdown-wrapper {
            display: none;
        }

        .main-nav__submenu {
            &-wrapper {
                position: absolute;
                width: 100%;
                top: 53px;
                left: 0;
                right: 0;
                z-index: 10;
            }
        }

        .dropdown-content {
            width: 100%;
            min-height: 545px;
            background-color: $cl-white;
            border-bottom: solid 1px $cl-light-gray;
        }

        .nav-item-dropdown {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            left: 0;
            max-width: $grid-size-1;
            margin: 0 auto;
            background-color: $cl-white;
            list-style: none;
            padding: 60px 15px 1.5625rem;
        }

        .sub-nav-item {
            color: $cl-charcoal-gray;
            font-size: to-rem(16px);
            font-weight: 500;
            letter-spacing: to-rem(0.8px);
            line-height: to-rem(20px);
            padding-top: 15px;
            padding-bottom: 15px;
            text-transform: uppercase;
            width: auto;
            cursor: pointer;
            max-width: to-rem(261px);

            .sub-nav-link {
                position: relative;

                &:after {
                    bottom: -6px;
                }

                i {
                    margin-left: 12px;
                }
            }

            .dropdown-wrapper {
                min-height: 462px;
                left: 0;
                margin-left: 257px;
                padding-bottom: 1.5625rem;
                padding-left: 40px;
                padding-right: 1.875rem;
                position: absolute;
                right: 0;
                top: 0;

                @include media-breakpoint-up(xl) {
                    margin-left: to-rem(261px);
                }

                background-color: $cl-white;
            }
            // show active first child menu
            &.nav-item-hasDropdown:first-child {
                .dropdown-wrapper {
                    display: block;
                }
            }

            .sub-nav-item-dropdown {
                display: flex;
                justify-content: space-between;

                li {
                    margin-right: 2.8125rem;
                    max-width: 16.25rem;

                    a {
                        color: $cl-charcoal-gray;
                        font-size: to-rem(16px);
                        font-weight: normal;
                        line-height: to-rem(26px);
                        letter-spacing: to-rem(0.96px);
                        text-decoration: none;

                        &:hover {
                            color: $cl-dusky-purple;
                            text-decoration: underline;
                        }
                    }
                }

                .feature-section {
                    display: flex;
                    flex-direction: column;
                    margin-top: 50px;

                    .feature-content {
                        text-align: center;
                        padding-top: 1.5625rem;

                        p,
                        a {
                            color: $cl-charcoal-gray;
                            font-size: to-rem(18px);
                            font-weight: 400;
                            letter-spacing: to-rem(1.08px);
                            line-height: to-rem(26px);
                            text-transform: uppercase;
                        }
                    }

                    .feature-image {
                        max-width: 472px;
                        max-height: 390px;
                        width: 100%;
                    }
                }
            }

            &--simple-menu {
                .sub-nav-link {
                    display: none;
                }

                .dropdown-wrapper {
                    margin-left: 0;
                }
            }
        }

        .sub-nav-content {
            margin-top: 55px;
            width: 100%;
            display: flex;
            align-content: flex-start;

            @media only screen and (min-width : 1400px) {
                flex: 1;
            }

            ul {
            }

            li {
                padding: 8px 0;
                text-transform: initial;

                @include media-breakpoint-up(xl) {
                    padding: 15px 0;
                }
            }
        }
    }

    .sub-nav-back {
        display: none;
    }
}

.header-navigation {

    .nav {
        &-primary {
            justify-content: center;
            margin: 1rem auto 0;
        }

        &-item {
            padding-right: 23px;
            padding-left: 23px;

            & > .dropdown-wrapper {
                display: block;
                visibility: hidden;
                opacity: 0;
                transition: opacity .3s .5s; 
            }


            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            &:hover {

                .dropdown-wrapper {
                    visibility: visible;
                    opacity: 1;
                }

                .nav-link::before {
                    background-color: $cl-dark-gray-blue;
                }
            }
        }

        &-link {
            border: none;
            color: $cl-black;
            font-size: to-rem(16px);
            font-weight: 400;
            letter-spacing: to-rem(0.96px);
            line-height: to-rem(22px);
            padding: 15px 0;
            position: relative;
            text-decoration: none;

            &::before {
                content: '';
                background-color: transparent;
                height: 4px;
                width: 100%;
                position: absolute;
                top: calc(100% - 3px);
            }
        }
    }

    li:hover > .dropdown-wrapper,
    li:focus > .dropdown-wrapper,
    .sub-nav-item.active .dropdown-wrapper {
        display: block;
    }
}
