﻿.content-container {
    .socialmediablock {
        overflow: hidden;
        position: relative;

        @include media-breakpoint-up(md) {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }

        &.block {
            display: inline-block;
            padding-left: 0;
            padding-right: 0;
            vertical-align: top;
        }

        a {
            display: block;
            height: 100%;

            &:before {
                content: '';
                opacity: 0.95;
                background-color: rgba($cl-dark-gray-blue,0.95);
                height: 100%;
                width: 100%;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;

                @include media-breakpoint-up(lg) {
                    transition: all 600ms ease-in-out;
                }
            }

            &:hover {
                i {
                    opacity: 1;
                }

                &:before {
                    top: 0;
                }
            }
        }

        i {
            color: $cl-white;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 35px;
            z-index: 1;
            opacity: 0;

            @include media-breakpoint-up(lg) {
                transition: all 600ms ease-in-out;
            }

            @include media-breakpoint-down(sm) {
                font-size: 25px;
            }
        }

        img {
            width: 100%;
        }
    }
}
