.m {
    &-none {
        // margin-top: 0;
        // margin-bottom: 0;
    }

    &-small {
        margin-top: 11px;
        margin-bottom: 11px;
    }

    &-medium {
        margin-top: 22px;
        margin-bottom: 22px;
    }

    &-large {
        @include media-breakpoint-down(md) {
            margin-top: 22px;
            margin-bottom: 22px;
        }

        margin-top: 44px;
        margin-bottom: 44px;
    }

    &-extra-large {
        @include media-breakpoint-down(md) {
            margin-top: 22px;
            margin-bottom: 22px;
        }

        margin-top: 66px;
        margin-bottom: 66px;
    }

    &-extra-extra-large {
        @include media-breakpoint-down(md) {
            margin-top: 44px;
            margin-bottom: 44px;
        }

        margin-top: 66px;
        margin-bottom: 66px;

        @include media-breakpoint-up(md) {
            margin-top: 132px;
            margin-bottom: 132px;
        }
    }
}

.p {
    &-none {
        // padding-top: 0;
        // padding-bottom: 0;
    }

    &-small {
        padding-top: 11px;
        padding-bottom: 11px;
    }

    &-medium {
        padding-top: 22px;
        padding-bottom: 22px;
    }

    &-large {
        @include media-breakpoint-down(md) {
            padding-top: 22px;
            padding-bottom: 22px;
        }

        padding-top: 44px;
        padding-bottom: 44px;
    }

    &-extra-large {
        @include media-breakpoint-down(md) {
            padding-top: 22px;
            padding-bottom: 22px;
        }

        padding-top: 66px;
        padding-bottom: 66px;
    }

    &-extra-extra-large {
        @include media-breakpoint-down(md) {
            padding-top: 44px;
            padding-bottom: 44px;
        }

        padding-top: 66px;
        padding-bottom: 66px;

        @include media-breakpoint-up(md) {
            padding-top: 132px;
            padding-bottom: 132px;
        }
    }
}
