﻿.badge-icon {
    width: 100px;
    height: 100px;
    border: 2px solid $cl-dark-gray-blue;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: to-rem(15px);
    font-family: $sans-serif-font-stack;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    text-transform: uppercase;
    color: $cl-dark-gray-blue;
    position: absolute;
    top: -73px;
    left: 15px;
}

@include media-breakpoint-up(lg) {
    .badge-icon {
        left: auto;
        right: 47px;
    }
}