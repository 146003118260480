.modal {
    z-index: 100050;

    &-content {
        border: none;
    }

    &-dialog {
        margin: 22px;

        @include media-breakpoint-up(sm) {
            margin: 28px auto;
        }
    }

    .close {
        border-radius: 50%;
        color: $cl-white;
        background-color: $cl-dark-gray-blue;
      
        position: absolute;
        top: -17px;
        right: -17px;
        width: 34px;
        height: 34px;
        opacity: 1;
        z-index: $zindex-fixed;

        &:hover {
            opacity: 1;
        }
    }

    &-header, &-footer {
        border: none;
    }

    &-footer {
        .button-black {
            margin-right: 23px;
            font-size: to-rem(13px);
        }
    }

    &-title--center {
        margin: 0 auto;
    }
}