﻿.product-gallery-item-container {
    max-width: 1678px;
    margin: 0 auto;

    .row {
        margin: 0;
    }

    .col {
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }
    //Column paddings
    //First Row
    .row:nth-child(1) {
        .col:nth-child(1), .col:nth-child(3) {
            padding-top: 0px;

            @include media-breakpoint-up(md) {
                padding-top: 100px;
            }
        }
    }
    //Second Row
    .row:nth-child(2) {
        .col:nth-child(1), .col:nth-child(3) {
            padding-top: 0px;

            @include media-breakpoint-up(md) {
                padding-top: 60px;
            }
        }

        .col:nth-child(2) {
            margin-top: 0px;

            @include media-breakpoint-up(md) {
                margin-top: -40px;
            }
        }
    }
}
