.cl-bg-very-light-pink.content-padding.activation {
    @include media-breakpoint-down(md) {
        background-color: $cl-white;
        padding: 0 4% 50px 4%;
    }
}


.activate-account {
    &-page {
        padding: 66px 40px 0 22px;
        min-height: 585px;
        max-width: $grid-size-1;
        margin: auto;

        h3 {
            @include media-breakpoint-up(md) {
                line-height: 1.37;
                margin-bottom: to-rem(44px);
            }
        }

        h5 {
            margin-top: 0;
            margin-bottom: to-rem(22px);
        }

        h6 {
            font-size: to-rem(13px);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: to-rem(0.13px);
        }

        @include media-breakpoint-down(md) {
            padding: 22px 0 0 0;
            min-height: auto;
        }
    }

    &__form {
        background-color: $cl-white;
        padding: 62px 65px;

        &-header {
            margin-bottom: 28px;

            h5 {
                margin-bottom: to-rem(11px);
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 0;
                margin-top: 35px;
            }
        }

        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }

    &__error {
        background-color: $cl-white;
        padding: 18px 65px 70px;
        text-align: center;

        @include media-breakpoint-down(md) {
            padding: 20px 0;
        }
    }

    &__hr {
        margin-top: to-rem(20px);
        padding-top: to-rem(44px);
        margin-bottom: to-rem(44px);
        border-top: 1px solid $cl-light-gray;
    }

    &__controls {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
    }

    &__success {

        &-content {
            background-color: $cl-white;
            padding: 100px 140px 140px;
            text-align: center;

            @include media-breakpoint-down(md) {
                padding: 20px 0;
            }

            .btn {
                margin-top: 1rem;
            }
        }

        &-message {
        }
    }
}
