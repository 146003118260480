.footer {
    padding: 7.5rem 22px;
    width: 100%;

    &__description {
        @include media-breakpoint-up(lg) {
            padding-right: 30px;
        }
    }

    &__heading {
        color: $cl-white;
        font-family: $sans-serif-font-stack;
        font-size: to-rem(18px);
        font-weight: 500;
        letter-spacing: to-rem(0.36px);
        margin-bottom: 2.5rem;

        &:after {
            border-bottom: 3px solid $cl-beige;
            content: "";
            display: block;
            margin-top: 12px;
            width: 63px;
        }
    }

    &__subtext {
        font-size: 12px;
        color: #666666;
        padding-top: 40px
    }

    &__link {
        color: $cl-white;
        font-family: $sans-serif-font-stack;
        font-size: to-rem(14px);
        font-weight: 400;
        letter-spacing: to-rem(0.28px);
        text-decoration: none;
        padding: to-rem(7px) 0;
        display: block;
    }

    &__mobile_links {
        margin-bottom: 40px;
        padding: 0;

        h3 {
            font-family: $sans-serif-font-stack;
            font-size: to-rem(16px);
            font-weight: 500;
            letter-spacing: to-rem(0.32px);
            line-height: to-rem(26px);
            margin-bottom: 24px;
            position: relative;
            text-decoration: none;

            i {
                position: absolute;
                right: 0;
                top: 2px;
            }

            .fa-chevron-down {
                display: none;
            }

            .fa-chevron-up {
                display: block;
            }

            &.collapsed {
                margin-bottom: 20px;

                .fa-chevron-down {
                    display: block;
                }

                .fa-chevron-up {
                    display: none;
                }
            }
        }

        ul {
            margin-bottom: 2rem;
        }

        p {
            font-size: 16px;
        }

        .collapse {
            margin-bottom: 15px;

            li {
                margin-bottom: 15px;
            }
        }
    }

    .email-form {
        .form-control {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: .28px;
            color: #000;
            border: none;

            @media screen and (min-width: $grid-size-2) {
                width: to-rem(355px);
            }
        }

        button {
            width: 100%;
            margin-top: 11px;

            @include media-breakpoint-up(sm) {
                width: auto;
                margin-top: 0;
            }
        }
    }

    &__social {
        font-size: 0;
        margin-top: 30px;

        .fab {
            font-size: 1.75rem;
        }

        svg.svg-inline--fa {
            width: 2rem;
            height: 2rem;
        }

        a:not(:first-child) {
            padding-left: 22px;
        }

        .socialmediablock {
            display: inline-block;
            margin-right: 22px;

            img {
                display: none;
            }

            a {
                display: block;
                font-size: to-rem(28px);

                &:hover {
                    color: #f0cca2;
                }
            }
        }
    }

    &__copyright {
        text-align: right;

        p {
            display: inline;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 45px;
            text-align: center;
        }
    }

    .textblock p {
        font-family: $sans-serif-font-stack;
        font-size: to-rem(14px);
        font-weight: 400;
        letter-spacing: to-rem(0.28px);
        line-height: to-rem(22px);
        margin: 0 0 40px 0;
        text-decoration: none;
    }

    picture img {
        margin-bottom: 3rem;
        max-width: 205px;
    }

    hr {
        margin-top: 0.8rem;
    }

    &__form {
        .form-control {
            width: 100%;
            height: 51px;
            margin-right: 0;
            margin-bottom: 11px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                margin-right: 6px;
                width: calc(100% - 180px);
            }
        }

        .btn {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: auto;
            }
        }

        .custom-control-label {
            margin-top: 0;
            padding-left: 3px;
        }
    }

    .navbar {
        padding: 0;

        .navbar-nav {
            flex-direction: row;

            .nav-item {
                .nav-link {
                    color: $cl-white;
                    font-family: $sans-serif-font-stack;
                    font-size: to-rem(16px);
                    font-weight: 400;
                    letter-spacing: to-rem(0.32px);
                    line-height: to-rem(30px);
                    margin-right: 40px;
                    padding: 0px;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: to-rem(14px);
                        letter-spacing: to-rem(0.28px);
                        line-height: to-rem(21px);
                        margin-right: 30px;
                        text-align: center;
                    }
                }

                &:last-child a {
                    margin-right: 0;
                }
            }

            @include media-breakpoint-down(sm) {
                align-items: center;
                flex-direction: row;
                justify-content: center;
                width: 100%;
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding: 4.8rem 22px;
    }
}

.navbar-mobile-footer {
    align-items: center;
    padding: 2rem 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: $cl-dark-gray-blue;
    color: white;

    @include media-breakpoint-up(md) {
        padding: 4rem;
        padding-bottom: 7rem;
    }

    .nav {
        align-items: center;
        flex-wrap: nowrap;
        height: 40px;
    }

    p {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.42;
        letter-spacing: 0.48px;
        padding: 0;
        margin: 0;

        @include media-breakpoint-up(md) {
            font-size: 24px;
            line-height: 1.5;
            letter-spacing: 0.4px;
        }

        &:after {
            border-bottom: 3px solid #f0cca2;
            content: "";
            display: block;
            margin: 20px auto;
            width: 64px;

            @include media-breakpoint-up(md) {
                width: 98px;
            }
        }
    }

    .nav {
        margin-top: 0;

        @include media-breakpoint-up(md) {
            margin-top: 2.5rem;
        }

        &:hover .logo {
            background: $cl-dark-gray-blue;
            opacity: 0.5;
        }

        .logo:hover {
            background: $cl-dark-gray-blue;
            opacity: 1;
        }
    }

    .nav-item {
        &.logo {
            border-right: 0px;
            width: 95px;
            height: auto;
            max-height: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px;

            @include media-breakpoint-up(md) {
                border-right: 2px solid gray;
                max-width: 100%;
                width: 240px;
                height: auto;
                max-height: 60px;
                padding: 20px;
            }

            a {
                color: white;
                padding: 0;
                width: 100%;
                height: 100%;
            }


            &:last-child {
                border-right: 0px;
            }
        }
    }
}
