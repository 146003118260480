﻿.search-result {
    &-page {

    }

    &__query {       
        padding: 22px 0;
        text-align: center;

        @include media-breakpoint-up(lg) {
            padding: 66px 0 0;
        }

        &-title {
            font-size: 16px;
            font-weight: 500;           
            line-height: 1.38;
            letter-spacing: 0.16px;
        }

        &-text {
            line-height: 1.53;
        }
    }
}