﻿
.heading-title {
    font-size: 30px;
    margin: 0px;
    padding-left: 0;
}

.panel {
    border: 1px solid black;
    margin-bottom: 25px;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

    &__heading {
        background: #ff553e;
        border-color: #ff553e;
        color: #fff;
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    &__title {
        font-size: 16px;
        margin-bottom: 0;
    }

    &__body {
        padding: 15px 10px;
    }
}

.store-detail {
    margin-bottom: 20px;

    &__info {
        > h4 {
            font-weight: 700;
            line-height: 1.14;
            letter-spacing: 0.5px;
            color: #000;
        }

        > span {
            display: block;
            font-family: Roboto, Helvetica, Arial, sans-serif;
            line-height: 1.43;
            letter-spacing: 0.5px;
            color: #7f7f7f;
        }
    }

    &__store-locator {
        display: none;
    }
}

.use-current-location {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    width: fit-content;
    cursor: pointer;

    > div {
        position: absolute;
        bottom: -4px;
        left: 25px;
        width: max-content;
    }
}

#storeMap {
    width: auto;
    min-height: 450px;
    position: relative;
}

@media (min-width: 1200px) {
    #storeMap {
        height: 600px;
    }
}

#searchMapInput {
    z-index: 1;
    left: 0;
    position: absolute;
    width: 35%;
    opacity: 0.9;
    border: none;

    &::placeholder {
        color: #333;
        font-style: italic;
    }
}

.store-locator-page {

    &__search {
        background-color: $cl-very-light-pink;
        border: 1px solid $cl-light-gray;
        border-top: none;
        padding: 2.812rem;

        .search-miles {
            select.form-control {
                font-size: .812rem;
            }
        }

        .search-input {
            display: flex;
            flex-wrap: wrap;

            input {
                font-size: .812rem;
                flex: 1 1 0;
                order: 0;
                width: auto;
                min-width: 50px;
            }

            #ZipCode-error {
                display: block;
                flex: 1 1 100%;
                order: 2;
            }

            button {
                padding: 10px;
                background-color: $cl-dusk;
                color: white;
                border: 1px solid $cl-dusk;
                order: 1;
                flex: 0 0 auto;
            }
        }

        .search-error {
            color: $cl-error-2;
            font-size: 0.812rem;
            margin-top: 12px;
        }

        .search-results {
            padding-top: 2rem;
        }
    }

    &__results {
        .stores {
            //background-color: rgba(204, 204, 204, .15);
            min-height: 200px;
            overflow-y: auto;
            height: 720px;

            @include media-breakpoint-up(md) {
                min-height: 720px;
                height: 720px;
            }
        }

        .store {
            //background-color: rgba(204, 204, 204, .15);
            padding: 2.2rem 1.8rem 1.812rem 2.812rem;
            border: 1px solid $cl-light-gray;
            border-top: none;

            &.active {
                background-color: rgba(204, 204, 204, .15);
            }

            &__name {
                display: flex;
                justify-content: space-between;
                position: relative;

                &--num {
                    position: absolute;
                    color: white;
                    top: 30%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &--icon {
                    flex: 1 1 auto;
                    margin-bottom: 0;
                    position: relative;

                    .icon {
                        &.active {
                            color: $cl-dusky-purple;
                        }
                    }
                }

                &--title {
                    flex: 1 1 80%;
                    margin-left: 10px;
                }

                &--distance {
                    flex: 1 1 40%;
                    text-align: right;
                }
            }

            &__details {
                padding-left: 50px;

                .store__name--title {
                    display: none;
                }

                &--address {
                    margin-bottom: 0px;

                    a {
                        text-decoration: none;
                        color: $cl-charcoal-gray;
                    }

                    .store__details--link {
                        border-bottom: 1px solid $cl-dark-gray-blue;
                        color: $cl-dark-gray-blue;
                        text-decoration: none;
                        margin-left: 10px;

                        &:first-of-type {
                            margin-right: 40px;
                        }

                        &:hover {
                            color: $cl-dusky-purple;
                            border-bottom: 1px solid $cl-dusky-purple;
                        }
                    }
                }

                &--address2 {

                    a {
                        text-decoration: none;
                        color: $cl-charcoal-gray;
                    }
                }

                &--phone {
                    a {
                        text-decoration: none;
                        color: $cl-charcoal-gray;
                    }
                }

                &--link {
                    border-bottom: 1px solid $cl-dark-gray-blue;
                    text-decoration: none;
                    margin-left: 10px;

                    &:first-of-type {
                        margin-right: 40px;
                    }

                    &:hover {
                        border-bottom: 1px solid $cl-dusky-purple;
                    }
                }
            }
        }
    }

    &__map {
        height: 100%;

        .map {
            width: 100%;
            height: 800px;

            @include media-breakpoint-up(md) {
                height: 100%;
            }

            .gm-style-iw gm-style-iw-c {
                border-radius: 0;
            }

            .store__name--title {
                margin-bottom: 20px;
            }

            .store__details {
                &--address {
                    margin-top: 12px;
                    margin-bottom: 5px;

                    a {
                        color: $cl-charcoal-gray;
                        text-decoration: none;
                    }

                    .store__details--link {
                        border-bottom: 1px solid $cl-dark-gray-blue;
                        color: $cl-dark-gray-blue;
                        text-decoration: none;
                        margin-left: 10px;

                        &:first-of-type {
                            margin-right: 40px;
                        }

                        &:hover {
                            border-bottom: 1px solid $cl-dusky-purple;
                            color: $cl-dusky-purple;
                        }
                    }
                }

                &--address2 {
                    a {
                        color: $cl-charcoal-gray;
                        text-decoration: none;
                    }
                }

                &--phone {
                    a {
                        text-decoration: none;
                        color: $cl-charcoal-gray;
                    }
                }
            }
        }
    }
}
