﻿.blog-large-feature-block,
.blog-article-thumbnail-block {
    max-width: 530px;
    margin: 0 auto;

    &__wrap {
        padding: 0 15px;
    }

    &__img {
        width: 100%;
        display: block;
    }

    &__img-wrap {
        margin-bottom: 33px;
    }

    &__title {
        margin-bottom: $vertical-m;
        margin-top: 10px;
    }

    &__link {
        text-decoration: none;
        color: $cl-charcoal-gray;
        display: block;

        &:hover {
            color: $cl-charcoal-gray;
            text-decoration: none;
        }
    }

    &__text {
        margin-bottom: $vertical-m;
    }

    &__read-more {
        border-bottom: 1px solid $cl-dark-gray-blue;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        text-transform: none;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        font-size: 1rem;
        font-weight: normal;
        padding: 0;
    }

    &__text-block {
        padding: 1.37rem;
        text-align: left;
    }
}

.blog-article-thumbnail-block {
    margin-bottom: 67px;

    &__link {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__text {
        flex-grow: 1;
    }

    &__img {
        max-width: 100%;

        &-wrap { 

            @include media-breakpoint-up(md) {
                width: 530px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

            }
        }
    }
}

.articles-grid {
    &__wrap {
        padding: 0 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        max-width: 1112px;
        flex-direction: column;
        margin: 0 auto;
    }

    .blog-article-thumbnail-block {
        display: flex;

        &>div {
            display: flex;
            flex-grow: 1;
        }
    }
}

@include media-breakpoint-up(lg) {
    .blog-large-feature-block {
        max-width: 1634px;

        &__link {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &__img-wrap {
            max-width: 1024px;
            margin-bottom: 0;
        }

        &__text-block {
            max-width: 545px;
            padding-left: 80px;
            padding-right: 0;
        }
    }
}

// 2 elements
@media screen and (min-width: 1112px) {
    .articles-grid {
        &__wrap {
            flex-direction: row;
        }

        .blog-article-thumbnail-block {
            margin-right: initial;
            margin-left: initial;

            &:nth-child(odd) {
                margin-right: 22px;
            }
        }
    }
}

// 3 elements
@media screen and (min-width: 1664px) {
    .articles-grid {
        &__wrap {
            max-width: 1664px;
        }

        .blog-article-thumbnail-block {
            margin-right: 22px;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
}