.print-pdf {

    &-page {
        padding: 0 44px 0 44px;  

        #epi-quickNavigator {
            display: none;
        }

        @media print {
            .row {
                flex-wrap: wrap;               
                width: 100%;
                page-break-inside: avoid;
                break-inside: avoid;

                position: relative;
            }
           
        }

    }

    &__logo {
        justify-content: center;
        display: flex;
        height: 25px;

        &-cta {
            display: inline-block;
        }

        img {
            width: auto;
            max-width: 300px;
            height: 100%;
        }
    }

    &__title {
        padding-top: 22px;
        padding-bottom: 22px;
        font-family: Arial, Helvetica, sans-serif;
    }

    &__product {
        padding-bottom: 16px;
  

        &-img {
            margin-bottom: 22px;
            page-break-inside: avoid;
            break-inside: avoid;

            img {
                page-break-inside: avoid;
                break-inside: avoid;
            }
        }

        &-name {
            font-size: to-rem(18px);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.53;
            letter-spacing: 0.68px;
            text-align: left;
            color: $cl-charcoal-gray;

            margin-bottom: 11px;
        }

        &-sku {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.4px;
            text-align: left;
            color: $cl-slate-gray;
            margin-bottom: 5px;
        }

        &-price {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.56px;
            text-align: left;
            color: $cl-black;
            margin-bottom: 11px;
        }

        &-barcode {
            margin-top: 22px;
            width: 60%;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    .custom-row-styles {
        margin-bottom: 8px;
        padding-bottom: 0;
    }
}