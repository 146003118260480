﻿.product__tearsheet {
    height: 1320px;
    width: 100vw;
    position: relative;

    &-wrapper {
        height: 100%;
        width: 100%;

    }



    #epi-quickNavigator {
        display: none;
    }

    .content {
        position: relative;


        .header__logo {
            width: 100%;
            display: block;
            text-align: center;
            position: relative;
            height: 50px;

            &-cta {
                display: block;
                position: relative;
            }

            hr {
                position: relative;
                margin-top: 25px;
            }

        }

        .image-main {
            padding-top: 65px;
            width: 100%;
            display: block;
            text-align: center;

            img {
                display: inline-block;
                width: 600px;
                height: 600px;
                margin: 0 auto;
            }
        }

        .product_info {
            padding-top: 50px;
            position: relative;

            .product-detail__price {

                .price__old,
                .price__original,
                .price__discount {
                    font-size: 18px;
                }
            }
        }
    }

    .footer {
        padding: 0;
        position: absolute;
        bottom: 0;

        div {
            text-align: center;

            a {
                text-decoration: none;
            }
        }
    }

    footer {
        display: none;
    }

    .navbar-mobile-footer {
        display: none;
    }
}