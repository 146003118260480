﻿/// <reference path="../main.scss" />
.quantity {
    label {
        margin: 0 6px 0 0;
    }

  .controls {
        background: white;
        padding: 0 0 15px 0;
        width: 100%;

        .quantity-controls-wrapper {
            display: flex;
            justify-content: space-between;
        }
    

        @include media-breakpoint-up(md) {
            border-bottom: 1px solid $cl-light-gray;
            display: inline-block;
            margin-bottom: 0;
        }

        small {
            font-family: $sans-serif-font-stack;
            font-size: to-rem(13px);
            line-height: to-rem(20px);

            @include media-breakpoint-up(md) {
                margin-bottom: 10px;
            }
        }

        &__field {
            border: none;
            color: $cl-charcoal-gray;
            flex: 0 0 auto;
            font-size: to-rem(12px);
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.2px;
            text-align: left;
            max-width: 30px;
            -moz-appearance: textfield;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-size: 14px;
            }

            @include media-breakpoint-up(xl) {
                max-width: 3rem;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }

            &::-ms-clear {
                display: none;
            }
        }

        &__button {
            &:first-of-type {
                margin-left: 8px;
            }

            &:last-of-type {
                margin-right: 8px;
            }

            cursor: pointer;
        }
    }
}