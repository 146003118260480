﻿.order-details {
    &-page {
        padding-top: 40px;
    }

    &__header {
        margin: 16px 0 0;
    }

    &__dates {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.54;
        color: $cl-charcoal-gray;

        span {
            display: inline-block;
            margin-right: 22px;
        }
    }

    &__info {
        margin-top: 22px;
        @include media-breakpoint-up(md) {
            margin-top: 55px;
        }

        padding-top: 28px;
        border-top: 1px solid $cl-light-gray;
        border-bottom: 1px solid $cl-light-gray;
        font-size: 13px;

        &-area {
            padding-bottom: 22px;

            @include media-breakpoint-up(md) {
                padding-bottom: 44px;
            }
        }

        b {
            display: block;
            font-weight: 500;
            margin-bottom: 6px;
        }

        p {
            line-height: 1.54;
            margin-bottom: 0;
        }
    }

    &__items {
        overflow-x: auto;
        width: 100%;

        &-table {
            margin-top: 44px;
            border-bottom: 1px solid $cl-light-gray;

            thead th {
                border-top: 0;
                border-bottom: 1px solid $cl-charcoal-gray;
                font-size: 13px;
                font-weight: 500;
                line-height: 1.54;
                color: $cl-charcoal-gray;

                &:active {
                    outline: none;
                }
            }



            td {
                padding-top: 35px;
                padding-bottom: 22px;
            }
        }
    }

    &__item {
        font-size: 13px;
        line-height: 1.54;

        &-th {
            width: 200px;
        }

        b {
            display: block;
            font-weight: 500;
            margin-bottom: 6px;
        }

        p {
            margin-bottom: 0;
        }

        &-image {
            width: 130px;
            padding-top: 22px !important;
        }
    }


    &__cell {
        &-wrapper {
            width: 130px;
            height: 130px;
            object-fit: contain;
            overflow: hidden;
            margin: 0;            
        }

        &-img {
            width: 100%;
            height: auto;
        }
    }

    &__summary {
        font-size: 13px;
        line-height: 1.54;

        @include media-breakpoint-up(md) {
            width: 390px;
            float: right;
            margin-top: 22px;
        }

        td {
            width: 200px;
            padding: 0 0 22px;
        }

        b {
            display: block;
            font-weight: 500;
        }

        p {
            margin-bottom: 0;
            font-weight: normal;
        }

        &-discount {
            color: $cl-error-2;
        }



        &-total {
            td {
                border-top: 1px solid $cl-light-gray;
                padding-top: 22px;
            }
        }
    }

    &__not-found {
        min-height: 300px;

        @include media-breakpoint-up(xl) {
            min-height: 470px;
        }
    }
}
