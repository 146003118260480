﻿.adjacent-block {
    max-width: 375px;
    margin: 0 auto;
    position: relative;

    &__img {
        width: 100%;
        display: block;
    }

    &__brand {
        margin: 39px 0 28px 0;
    }

    &__text-block {
        margin-left: 25px;
        position: relative;
    }

    &--badge {
        margin-top: 73px;
    }

    .btn {
        padding-left: 70px;
        padding-right: 70px;
        width: auto;
    }
}

.adjacent-fw-block {
    max-width: 375px;
    margin: 0 auto;
    background-size: 0 0;
    background-repeat: no-repeat;
    background-position: center;

    &__m-img-wrap {
        margin-bottom: 22px;
    }

    &__m-img {
        display: block;
        width: 100%;
    }

    &__title {
        margin-bottom: 6px;
    }

    &__text-block {
        padding: 0 15px 22px;        
    }

    &--white-box {
        .adjacent-fw-block__text-block {
            background-color: rgba(#ffffff, 0.95);
        }
    }

    .body-1 {
        color: $cl-charcoal-gray;

        p {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: 0.32px;
            text-align: left;
        }
    }

    &__cta {
        margin-top: 40px;
    }
}

@include media-breakpoint-up(lg) {
    .adjacent-block {
        max-width: 1482px;

        &__container {
            display: flex;
            justify-content: center;           
        }

        &__brand {
            margin-top: 100px;
        }

        &__bottom {
            max-width: 573px;
            position: relative;
            left: 61px;
        }

        &--left {

            .adjacent-block__container {
                flex-direction: row;
            }
        
            .adjacent-block__text-block {
                left: -118px;
            }

            .badge-icon {
                left: 47px;                
            }
        }

        &--right {
            .adjacent-block__brand {
                padding-left: 15px;

                @media screen and (min-width: 1500px) {
                    padding-left: 0;
                }
            }

            .adjacent-block__container {
                flex-direction: row-reverse;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .adjacent-fw-block {
        max-width: none;
        background-size: cover;
        height: 757px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__m-img-wrap {
            display: none;
        }

        &__text-block {
            max-width: 704px;
            padding: 130px 140px 125px 90px;
        }
    }
}

// fixing images in adjacent block
.one-up-image__image, .two-up-product__image-img, .adjacent-block__img, .adjacent-fw-block__m-img {
    object-fit: cover;
}