﻿.dataTable {
    width: 100% !important;

    &.table-striped {


        tbody tr {
            &:nth-of-type(odd) {
                background-color: transparent;
            }

            &.odd, &.odd + .child,
            &.odd.shown + tr,
            &.odd + tr:not(.even) {
                background-color: $cl-very-light-pink;
            }
        }
    }

    thead th {
        border-top: 0;
        border-bottom: 0;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.54;
        color: $cl-charcoal-gray;
        white-space: nowrap;

        &:active {
            outline: none;
        }
        /*  &:not([style*="display: none;"]) {
            &~.dtr-control {
                display: none;
            }
        } */
    }

    td {
        border: none;
        font-size: 13px;
        line-height: 1.54;
        color: $cl-charcoal-gray;
        padding: to_rem(16.5px) 11px;

        &.child {
            padding: 0 11px 0;
            border-top: 1px solid $cl-light-gray;
        }
    }

    .sorting {
        cursor: pointer;
    }

    .sorting_asc {
        cursor: pointer;

        &:after {
            @include font-awesome-icon('\f0de', 'solid');
            font-size: 16px;
            display: inline-block;
            margin-left: 5px;
        }
    }

    .sorting_desc {
        cursor: pointer;

        &:after {
            @include font-awesome-icon('\f0dd', 'solid');
            font-size: 16px;
            display: inline-block;
            margin-left: 5px;
        }
    }

    .dtr-details {
        padding: 11px 0;
        margin: 0;
        display: block;
        list-style: none;

        > li {
            margin-bottom: 11px;
            clear: left;
        }

        .dtr-title {
            margin-bottom: 11px;
            float: left;
            clear: left;
            font-weight: bold;
            display: inline-block;
            margin-right: 1.5ch;

            &:not(:empty):after {
                content: ':';
            }
        }

        .dtr-data {
            display: inline-block;
        }

        dt {
            margin-bottom: 11px;
            margin-left: 1.5ch;
            float: left;
            clear: left;
        }

        dd {
            margin-bottom: 11px;
        }
    }

    td.control {
        position: relative;
        padding-right: 44px;

        &:after {
            display: block;
            width: to-rem(14px);
            height: to-rem(14px);
            position: absolute;
            line-height: 1;
            right: 11px;
            top: 50%;
            margin-top: -4px;
            font-size: to-rem(24px);
            transform: translateY(-50%);
            content: '+';
        }
    }



    .dtr-control {
        position: relative;

        &__details {
            width: to-rem(14px);
            height: to-rem(14px);
            position: absolute;
            line-height: 1;
            right: 11px;
            top: 50%;
            margin-top: -4px;
            font-size: to-rem(24px);
            transform: translateY(-50%);

            &:after {
                content: '+';
            }
        }
    }

    .parent {
        .dtr-control__details {
            &:after {
                content: '-';
            }
        }

        .control {
            &:after {
                content: '-';
            }
        }
    }
}

.dataTables {
    &_wrapper {
        position: relative;
        min-height: 300px;
    }
    &_processing {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;

        background-color: transparent;
        border: none;


        .loading-box {
            position: absolute;
        }
    }
}
