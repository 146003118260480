.container-fluid {
    padding-left: 0px;
    padding-right: 0px;
    max-width: none;
}

.content-padding {
    padding: 0 11px;
}

.container {
    @include media-breakpoint-up(lg) {
        max-width: $grid-size-1;
        padding: 0 $grid-gutter-width;
    }
}
