﻿.quick-view--modal { 
    z-index: 8000;

    & .modal-dialog {
        width: 100%;

        & .modal-content {
            border-radius: 0;
        }
    }

    & .modal-footer {
        padding: 0;

        & .btn-fullwidth {
            width: 100%;
        }
    }

    .modal-header {
        padding: 0px;
    }

    .modal-body {
        padding: 0;
    }

    &_close {
        top: -3px !important;
        right: 0 !important;
        background-color: $cl-dark-gray-blue !important;
        font-size: 18px;
        font-family: "Font Awesome 5 Pro";
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_details {
        position: absolute;
        bottom: to-rem(-30px);
        left: 0;
        right: 0;
    }

    &__info {
        margin: 4rem 0;
        padding: 0 1rem 0 !important;

        @include media-breakpoint-up(md) {
            padding: 0 4rem 0 !important;
        }
    }

    .modal-xl {
        @media (min-width: 1200px) {
            max-width: 1160px;
        }
    }

    &__buy {
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        margin-top: 20px;


        &--qty {
            display: flex;
            padding-right: 20px;
            width: 100%;

            @include media-breakpoint-down(sm) {
                display: block;
                padding-left: 10px;
                padding-right: 10px;
                text-align: center;
            }

            @include media-breakpoint-up(xl) {
                width: 50%;
            }

            label {
                align-self: flex-end;
            }
        }

        .form-group {
            display: inline-block;
            margin-bottom: 0px;
            padding-left: 10px !important;
            width: 100%;

            .sub-title {
                font-size: 13px;

                @include media-breakpoint-down(sm) {
                    border-right: 1px solid gray;
                    padding-right: 20px;
                }
            }

            section {
                @include media-breakpoint-down(sm) {
                    display: inline-block;
                }
            }

            .controls {
                /*min-width: 170px;*/
                .controls__field {
                    min-width: 200px;

                    @include media-breakpoint-up(md) {
                        min-width: 80%;
                    }

                    @include media-breakpoint-up(xl) {
                        display: inline;
                        font-size: .8125rem;
                        font-weight: normal;                   
                    }
                }

                .controls__button {
                    display: inline;
                    font-size: 10px;
                }
            }
        }

        &--btn {
            width: 100%;

            @include media-breakpoint-up(xl) {
                width: 50%;
            }

            button {
                width: 100%
            }
        }

        @include media-breakpoint-down(md) {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    .product-detail__img {

        .img-fluid {
            width: 100%;
        }
        
        .product-detail__image {
            bottom: 44px;
            position: absolute;
            left: 0;
            right: 0;
        }
    }

    .product-detail__image {
        bottom: 44px;
        left: 90px;
        position: absolute;


        @include media-breakpoint-down(md) {
            bottom: 0;
            left: 0;
            position: relative;
        }

        ul {
            height: 12px;
        }

        ul > li img, ul > li video {
            //border: solid 0.5px $cl-light-gray;
            height: 12px;
            opacity: 0;
            width: 12px;
        }

        ul > li {
            border-radius: 50%;
            height: 12px;
            background: #696d74;
            margin-right: 9px;
            padding-right: 0px;

            .activeImage.active {
                border: solid 2px $cl-dark-gray-blue;
            }

            &.active {
                height: 18px;
                background: transparent;
                border: 2.5px solid $cl-dark-gray-blue;
                width: 18px;
            }
        }
    }
    /*
        .product-detail__image ul{ 
            min-height: 18px;
        }

        .product-detail__image ul li {
            width: 12px;
            height: 12px;
            margin: 0 9px 0 0;
            border: solid 2.5px gray;
            border-radius: 50%;
            padding: 0;
            background: gray;

            &:hover {
                background: transparent;
                width: 18px;
                height: 18px;
            }
        }

        .product-detail__image ul li img {
            display: none;
            min-height: 610px;
        }
    }*/
}
