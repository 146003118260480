.address-book {
    &__header {
        margin: 39px 0 22px;
        padding-bottom: 22px;
        border-bottom: solid 1px $cl-charcoal-gray;
    }

    &__billing {
        &-area {
            padding: 22px 30px;
            background-color: $cl-very-light-pink;
            margin-bottom: 44px;
        }
    }

    &__item {
        padding-bottom: 28px;
        height: 100%;

        &:hover {
            .address-book__item-controls {
                opacity: 1;
            }
        }

        &-wrapper {
            height: 100%;
            background-color: $cl-very-light-pink;
            display: flex;
            flex-direction: column;
        }

        &-head {
            padding: 11px 30px;
            border-bottom: 1px solid $cl-slate-gray;
        }

        &-billing {
            font-size: 13px;
            line-height: 2;
            letter-spacing: 0.26px;

            @include media-breakpoint-up(md) {
                text-align: right;
            }
        }

        &-body {
            padding: 22px 30px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .address-block {
                flex-grow: 1;
            }

            strong {
                display: block;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                letter-spacing: 0.32px;
            }
        }

        &-controls {
            justify-self: flex-end;

            &--edit {
                cursor: pointer !important;
            }

            &--remove {
                margin-left: 22px;
                cursor: pointer !important;
            }

            @include media-breakpoint-up(md) {
                opacity: 0;
            }
        }



        &-shipping {
            padding-top: 10px;
            border-top: 1px solid $cl-slate-gray;
            margin: 0 30px;
            padding: 0 0 22px;

            label {
                margin-bottom: 0;
            }

            &-btn-set {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                border: none;
                opacity: 0;
            }
        }
    }

    &__edit {
        &-controls {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: wrap;
            margin-top: .5rem;

            @include media-breakpoint-down(sm) {
                .btn-primary {
                    order: 0;
                }

                .btn-link {
                    order: 1;
                }
            }

            .btn {
                width: 100%;

                &-link {
                    border: 0;
                    font-size: .8125rem;
                    padding: 0.875rem 2.5rem;
                    color: $cl-charcoal-gray;
                }

                @include media-breakpoint-up(sm) {
                    width: auto;
                    margin: 0;

                    & + .btn {
                        margin-left: 11px;
                    }
                }
            }
        }
    }

    &__modal {
        z-index: 9000;
        &-confirm {
            .modal-body {
                text-align: center;
            }

            .modal-footer {
                justify-content: center;
            }
        }
    }
}

.address-block {
    strong + & {
        margin-top: 8px;
    }

    p {
        font-size: 16px;
        line-height: 1.63;
        letter-spacing: 0.32px;
    }
}
