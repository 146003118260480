﻿.receipt-page {
    max-width: 1410px;
    margin: 0 auto;
    padding: 0 15px;

    .price__discount {
        color: $cl-dusky-purple;
    }

    .price__old, 
    .price__discount {
        font-size: 0.812rem;
        font-weight: 500;
    }

    &__title {
        margin-bottom: 21px;
    }

    &__logo {
        width: 135px;
        display: block;
        margin: 0 auto;

        &-wrap {
            margin-bottom: 20px;
            margin-top: 35px;
        }
    }

    &__top-text {
        margin-bottom: 25px;
    }

    &__grid {
        padding: 28px 0 40px;
        border-top: 1px solid $cl-light-gray;
        border-bottom: 1px solid $cl-light-gray;
        margin-bottom: 25px;

        &-row-indent {
            margin-bottom: 15px;
        }
    }

    &__item {

        &--title {
            &-upper {
                text-transform: uppercase;
            }

            &-bold {
                font-weight: 500;
            }
        }

        &--title, h4 {
            font-size: to-rem(13px);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: normal;
            font-family: $sans-serif-font-stack;
        }
    }

    .address-block {
        b {
            font-weight: normal;
        }
    }
}

.receipt-page-ip {
    margin-bottom: 145px;

    &__row {
      display: flex;
      flex-wrap: nowrap;

      &-mb {
        margin-bottom: 16px;
      }
    }

  &__detail {
    border-bottom: 1px solid $cl-light-gray;
    padding-bottom: 22px;

    &-header {
      padding-bottom: 14px;
      margin-bottom: 38px;
      border-bottom: 1px solid #1a1a1a;

    }
  }

  &__total {
    max-width: 450px;
    margin-left: auto;
    border-bottom: 1px solid $cl-light-gray;

    &-wrap {
      display: flex;
      justify-content: space-between;
      padding: 30px 0;
      max-width: 320px;
      margin-right: auto;
    }
  }
}

.receipt-page-order {
  &__top-text {
    margin-bottom: 25px;
    font-size: to-rem(13px);

    &-detail {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
    }

    &-item {
      margin-right: 30px;
    }

    &--label {
      font-weight: 500;
    }
  }

  .order-details__items-table {
    margin-top: 0;

    .receipt-page-order__custom-text {
      font-size: to-rem(13px);

      &-label {
        font-weight: 500;
      }

      td {
        border-top: none;
        padding: 0 0 25px 12px;
      }

      i {
        margin-right: 7px;
      }
    }
  }

}

@include media-breakpoint-up(md) {
  .receipt-page {
    &__logo {
      width: 262px;

      &-wrap {
        margin-top: 72px;
        margin-bottom: 42px;
      }
    }

    &-wrap {
      margin-bottom: 42px;
    }

    &__top-text {
      margin-bottom: 50px;
    }

    &__grid {
      margin-bottom: 50px;
    }
  }

  .receipt-page-ip {
      &__row {
        display: flex;
        flex-wrap: wrap;
      }
  }

  .receipt-page-order {
    &__top-text {
      margin-bottom: 50px;
    }
  }
}
