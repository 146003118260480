﻿.testimonial-slider-container.theme-light {
    .testimonial-block_carousel-indicators li {
        background-color: $cl-charcoal-gray;
    }
}

.testimonial-block {
    text-align: center;
    min-height: to-rem(478px);

    @include media-breakpoint-up(md) {
        min-height: to-rem(533px);
    }

    &_content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0 30px;
    }

    &_author {
        font-size: 14px;
        letter-spacing: .36px;
        line-height: 1.28;
        margin-bottom: 2rem;
        text-align: center;
        text-decoration: none;
        display: block;
        cursor: default;

        @include media-breakpoint-up(md) {
            font-size: 18px;
        }
    }

    a.testimonial-block_author {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &_text {
        font-family: 'kepler-std';
        font-size: 18px;
        font-weight: 300;
        letter-spacing: .4px;
        line-height: 1.4;
        margin-bottom: 8px;
        text-align: center;

        @include media-breakpoint-up(md) {
            font-size: 20px;
            max-width: 600px;
            margin: auto;
        }
    }

    &_character {
        color: $cl-beige !important;
        font-family: 'kepler-std';
        font-size: to-rem(60px);
        font-weight: 300;
        letter-spacing: 2px;
        line-height: 1.28;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            font-size: 6rem;
        }
    }

    &_carousel-indicators {
        align-items: center;
        margin-bottom: to-rem(50px);

        li {
            border-radius: 50%;
            height: 12px;
            margin-right: 6px;
            margin-left: 6px;
            opacity: .8;
            width: 12px;

            &.active {
                background-color: transparent;
                border: 2px solid white;
                height: 14px;
                width: 14px;
            }
        }
    }
}
