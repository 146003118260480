﻿.large-cart {
    &--btn-group__bottom {
        padding-top: 15px;
        padding-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &--container {
        & .row {
            margin-right: 0px;
            padding-left: 0px;
        }

        & .cart-tile-list {
            &__img {
                padding-right: 1rem;

                label {
                    line-height: 1.25;
                    margin-bottom: 0;
                }

                @include media-breakpoint-down(sm) {
                    width: 40%;
                }
            }

            &__title {
                label {
                    line-height: 1.25;
                    margin-bottom: 0;
                }
            }

            &__qty {
                font-size: 14px;
                order: 1;

                label {
                    line-height: 1.25;
                    margin-bottom: 0;
                }

                .min-qty {
                    font-size: 0.812rem;
                    margin-bottom: 0;
                }

                .form-group {
                    margin-bottom: 0px;
                }

                .controls {
                    border: 1px solid $cl-light-gray;
                    padding: 12px 0 12px 0;
                    text-align: center;

                    @include media-breakpoint-down(sm) {
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        max-width: 75%;
                    }


                    .controls__button {
                        font-weight: normal;
                        margin: 0;

                        &:first-of-type {
                            padding-left: 10px;

                            @include media-breakpoint-up(md) {
                                padding-left: 4px;
                            }
                        }

                        &:last-of-type {
                            padding-right: 10px;

                            @include media-breakpoint-up(md) {
                                padding-right: 4px;
                            }
                        }
                    }

                    .controls__field {
                        font-weight: normal;

                        @include media-breakpoint-down(sm) {
                            max-width: 2rem;
                        }
                    }
                }

                @include media-breakpoint-up(sm) {
                    order: 0;
                    padding-left: 0.5rem;
                    padding-right: 1rem;
                    width: 100%;
                }

                @include media-breakpoint-up(md) {
                    font-size: 12px;
                }
            }

            &__item--price {
                order: 0;
                padding-right: 0;

                label {
                    line-height: 1.25;
                    margin-bottom: 0;
                }

                .price__discount {
                    color: $cl-dusky-purple;
                }

                @include media-breakpoint-down(sm) {
                    order: 0;
                    margin-bottom: 10px;
                }

                .content {
                    @include media-breakpoint-up(xl) {
                        width: 150px;
                    }
                }
            }

            &__total--price {
                padding-right: 0;

                label {
                    line-height: 1.25;
                    margin-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    padding-left: 2.5rem;
                    padding-right: 0;
                    width: 48%;
                }
            }

            &__saving--price {
                padding-right: 0;

                label {
                    line-height: 1.25;
                    margin-bottom: 0;
                }

                @include media-breakpoint-up(sm) {
                    padding-left: 1.75rem;
                    padding-right: 0;
                }
            }

            &__product--price {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                order: 2;
            }

            &__price {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                @include media-breakpoint-down(xs) {
                    flex-direction: column;
                }
            }

            &__buttons {
                cursor: pointer;

                .action-icons {
                    display: flex;
                    flex-wrap: wrap;
                }

                .cart-btn-link {
                    color: $cl-dark-gray-blue;
                    border-bottom: 1.25px solid $cl-dark-gray-blue;
                    font-size: 0.8125rem;
                    font-weight: normal;
                    letter-spacing: normal;
                    margin-left: 6px;
                    text-decoration: none;

                    &:hover {
                        border-bottom: 1.25px solid $cl-dusky-purple;
                    }
                }

                @include media-breakpoint-down(sm) {
                    margin: 10px 0 0 0;
                    order: 3;
                }

                .cart-btn-wishlist {
                    color: #294a62;

                    &:before {
                        content: '\f004';
                        font-family: 'Font Awesome 5 Pro';
                        font-size: 0.875rem;
                        font-weight: 300;
                        line-height: 1.28;
                        letter-spacing: 1px;
                        text-align: left;
                    }

                    &.is-wishlisted {
                        &:before {
                            font-weight: bold;
                        }
                    }
                }
            }

            &__gygi-msg {
                color: $cl-dusky-purple;
                margin-top: 10px;

                i {
                    margin-right: 6px;
                    width: 13px;
                    height: 18px;
                }

                p {
                    font-size: 0.812rem;
                    font-weight: normal;
                    margin-bottom: 0;
                }
            }

            &__info {
                display: flex;
                flex-wrap: wrap;
                margin-left: -11px;
                margin-right: 0px;
                padding-left: 0px;
                padding-right: 0;

                @include media-breakpoint-down(sm) {
                    width: 60%;
                }
            }

            &__item {
                padding: 6px 0 6px 0;
                position: relative;
                /*border-bottom: 1px solid #d3d7d8;*/
                > div {
                    font-size: 0.812rem;

                    &:first-child {
                        padding-left: 0;
                    }
                }

                &:first-child {
                    padding-top: 0px;
                }
            }

            &__image {
                /*border: 1px solid #eeeeee;*/
                max-width: 130px;

                &:hover {
                    cursor: pointer;
                }
            }

            &__right-box {
            }

            &__bestbet {
                position: absolute;
                background: #808080;
                color: #ffffff;
                padding: 8px 8px;
                border-radius: 50%;
                top: 15px;
                left: 15px;
            }

            &__content {
                .price__old, .price__discount, .price__original {
                    font-size: 0.812rem;
                    font-weight: 500;
                }
            }
        }

        &__alert-msg {
            align-items: center;
            color: $cl-dusky-purple;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 8px;

            i .svg-inline--fa.fa-w-16 {
                width: 14px;
                height: auto;
            }

            p {
                font-size: 0.812rem;
                font-weight: normal;
                margin-bottom: 0;
                margin-left: 6px;
            }
        }

        &__error-msg {
            color: $cl-error-2;
            font-size: 0.812rem;
            letter-spacing: 0.13px;
            line-height: 1.54;
            margin-bottom: 0;
            margin-top: 12px;
        }

        .cart {
            &__title {
                p {
                    color: $cl-charcoal-gray;
                    font-size: 13px;
                    margin-bottom: 0;
                    text-transform: capitalize;
                }

                a {
                    color: $cl-charcoal-gray;
                    display: block;
                    font-size: 13px;
                    font-weight: 500;
                    margin-bottom: 10px;
                    text-decoration: none;
                }
            }
        }
    }

    &--notes {
        margin: 1.5rem 0;

        &_title {
            align-items: center;
            display: flex;
            justify-content: space-between;

            label, p {
                font-size: .8125rem;
                margin-top: 0;
                margin-bottom: 12px;
            }
        }

        textarea {
            border-color: #d0d0d0;
            color: $cl-charcoal-gray;
            font-size: .8125rem;
            padding: 25px;
            width: 100%;
        }
    }

    &__margin-btn {
        margin-right: 20px;
    }

    &__margin-top {
        margin-top: 15px;
    }

    &--block {
        border: 1px solid $cl-light-gray;
        margin-top: 10px;
        padding: 22px 18px 17px;

        .promotion__form {
            display: flex;
            flex-direction: column;

            &--coupon {
                display: flex;
                flex-wrap: wrap;

                .couponCode {
                    flex: 1 1 0; 
                    min-height: to-rem(50px);
                    margin-right: -2px;
                    min-width: 50px;
                    width: auto;

                    @media screen and (max-width: 390px) {
                        margin-bottom: 5px;
                    }
                }
            }

            .couponcode-errormessage {
                color: $cl-error-2;
            }
        }

        .btn-primary {
            @include media-breakpoint-down(sm) {
                padding: .875rem .75rem;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                border-top: none;

                span {
                    font-size: 13px;
                    font-weight: normal;
                    padding: 0;
                }

                .title {
                    font-weight: 500;
                }

                .largecart-PromotionalDiscount {
                    color: $cl-error-2;
                }
            }
        }
    }

    &__summary {

        @include media-breakpoint-up(xl) {
            padding-left: 65px;
        }
    }

    &__additional-info {
        margin: 22px 0;
        padding: 22px;

        h1, h2, h3, h4, h5, h6, h7, a {
            text-decoration: none;
            line-height: 1.44;
            font-family: $sans-serif-font-stack;
            font-weight: 500;
            font-size: to-rem(18px);
            color: $cl-charcoal-gray;
            margin-bottom: 1rem;
            letter-spacing: normal;
        }
    }
}

.shopping-cart {
    padding-left: 22px;
    padding-right: 22px;

    @media (min-width: 576px) {
        max-width: 100%;
    }

    @media (min-width: 1680px) {
        max-width: 1454px;
    }
}
