﻿.hero-block {
    position: relative;
    overflow: hidden;

    &__image {
        position: absolute;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        background-position: top center;
    }

    &__video {
        position: absolute;
        width: 100%;
        height: 100%;

        > video {
            width: 100%;
            height: auto;
        }
    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__callout {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;

        > .hero-block-link {
            position: absolute;
            height: 100%;
            width: 100%;
            cursor: pointer;
        }

        > .callout {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        h1 {
            font-size: 3vw;
        }

        h2 {
            font-size: 2.7vw;
        }

        h3 {
            font-size: 2.5vw;
        }

        h4 {
            font-size: 2.3vw;
        }

        h5 {
            font-size: 2.1vw;
        }

        h6 {
            font-size: 1.9vw;
        }

        p {
            font-size: 1vw;
        }
    }

    &__callout-content {
        position: relative;
        z-index: 1;
    }
}
