﻿.product-gallery-item {
    margin: 0 1.5rem;
    overflow: hidden;
    position: relative;

    img {
        width: 100%;

        &:hover ~ aside {
            top: 0%;

            @include media-breakpoint-down(sm) {
                top: auto;
            }
        }
    }

    aside {
        color: $cl-white;
        display: block;
        height: 100%;
        left: 0px;
        opacity: 0.95;
        overflow: hidden;
        position: absolute;
        top: 100%;
        transition: all 600ms ease-in-out;
        width: 100%;
        z-index: 1;

        .title {
            font-size: clamp(to-rem(12px), 1vw, to-rem(16px));
            font-weight: 500;
            left: 50%;
            letter-spacing: to-rem(0.32px);
            line-height: to-rem(26px);
            position: absolute;
            text-align: center;
            top: 6.8%;
            transform: translateX(-50%);

            @include media-breakpoint-down(sm) {
                margin-top: 6.8%;
                position: relative;
                top: auto;
                transform: translate(-50%, 0);
            }
        }

        .description {
            @extend h7;
            left: 50%;
            position: absolute;
            max-width: 51%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            @include media-breakpoint-down(sm) {
                max-width: none;
                padding: 0 10%;
                position: relative;
                top: auto;
                transform: translate(-50%, 0);
            }
        }

        &:hover {
            top: 0%;

            @include media-breakpoint-down(sm) {
                top: auto;
            }
        }

        svg {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        @include media-breakpoint-down(sm) {
            bottom: 0;
            height: auto;
            left: auto;
            max-height: 75%;
            right: 0;
            top: auto;
            transition: none;
        }
    }
}
