﻿.order-history {
    &-page {}

    &__tbl {
        &-toggle {
            width: to-rem(14px);
            height: to-rem(14px);
            position: absolute;
            line-height: 1;
            right: 11px;
            top: 50%;
            font-size: to-rem(24px);
            transform: translateY(-50%);

            &:before {
                content: '+';

            }

            &.active {
                &:before {
                    content: '-';
                }
            }
        }

        &-row {
            position: relative;

            b {
                float: left;
                clear: left;
                margin-right: 2ch;
            }

            span {
                display: block;
            }

        }

        &-mobile {

            border-top: 1px solid $cl-light-gray;
            padding-top: 11px;
            margin-top: -17px;

            dt {
                margin-right: 2ch;
                float: left;
                clear: left;
            }

            @include media-breakpoint-up(md) {
                display: none;
            }

        }
    }
}

.order-claim-history {
    width: 100%;
    position: relative;

    &__body {
        .dataTables_wrapper {
            width: 100%;
        }

        thead {
            .order-history__tbl-mobile {
                display: none;
            }
        }

        .hidden-mobile {
            display: none;

            @include media-breakpoint-up(md) {
                display: table-cell;
            }
        }
    }

    &__search {
        margin-bottom: 44px;

        @include media-breakpoint-down(md) {    
            label {
                flex: 1 1 100%;
            }
            .btn {
                padding: .875rem 1.5rem;
            }
        }
    }

    &__searchBox {
        min-width: 100px;
    }

    &__results {}


}


.order {
    &__item {
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 15px;

        &:last-child {
            border: none;
        }
    }

    &--box {
        &>*:last-child {
            margin-bottom: 15px;
        }

        &>*:first-child {
            margin-top: 15px;
        }
    }

    &--form-group {
        display: flex;
        justify-content: space-between;

        & * {
            width: 30%;
        }

        @media screen and (max-width: 766px) {
            display: block;

            & * {
                width: 100%;
            }
        }

        @media screen and (max-width: 991px) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            & * {
                width: 40%;
            }
        }

        &:last-child {
            padding: 0;
        }
    }
}