﻿// SASS Color Variables
// Note: These colors also get added to :root by Bootstrap so they can be utilized via Javascript if necessary

$cl-black: #000;
$cl-white: #fff;
$cl-charcoal-gray: #3b3e43;
$cl-error-2: #d11843;
$cl-rock-blue: #9eb9cc;
$cl-dark-gray-blue: #294a62;
$cl-dusk: #4b4468;
$cl-dusky-purple: #955d7c;
$cl-slate-gray: #696d74;
$cl-light-gray: #cccccc;
$cl-beige: #f0cca2;
$cl-very-light-pink: #fcf5ec;
$cl-green: #0b7745;
$cl-light-yellow: #fcf5ec;

//Datepicker
$color-calendar-border: #e0e0e0;

$colors: ( 
    'black': $cl-black,
    'white': $cl-white,
    'charcoal-gray': $cl-charcoal-gray,
    'error-2': $cl-error-2,
    'rock-blue': $cl-rock-blue,
    'dark-gray-blue': $cl-dark-gray-blue,
    'dusk': $cl-dusk,
    'dusky-purple': $cl-dusky-purple,
    'slate-gray': $cl-slate-gray,
    'light-gray': $cl-light-gray,
    'beige': $cl-beige,
    'very-light-pink': $cl-very-light-pink,
    'green': $cl-green,
    'light-yellow': $cl-light-yellow
);

@mixin color-classes {
    @each $name, $color in $colors {
        .cl-#{$name} {
            color: $color;
        }
    }
}

@mixin bkgd-color-classes {
    @each $name, $color in $colors {
        .cl-bg-#{$name} {
            background-color: $color;
        }
    }
}

/* Color Classes*/
@include color-classes;

/* Color Background Classes*/
@include bkgd-color-classes;
