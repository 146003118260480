.profile-sidebar {
    padding-top: 22px;

    @include media-breakpoint-up(md) {
        padding-top: 44px;
    }


    &__title {
        margin-top: 20px;
        text-align: left;

        @include media-breakpoint-up(sm) {
            display: block;
            margin-top: 11px;
            text-align: center;
        }

        &-welcome {
            font-size: 13px;
            font-weight: 500;
            line-height: 1.54;
            letter-spacing: 0.13px;
            text-transform: uppercase;

            @include media-breakpoint-up(sm) {
                display: block;
            }
        }

        &-username {
            font-size: 13px;
            line-height: 1.54;
            margin-bottom: 10px;

            @include media-breakpoint-up(sm) {
                display: block;
            }
        }
    }

    &__salesrep {
        padding: 15px;
        min-height: 130px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: $cl-very-light-pink;

        @include media-breakpoint-up(sm) {
            text-align: center;
            flex-direction: column;
            align-items: center;
        }

        &--mobile {
            margin-bottom: 44px;
        }

        &-title {
            display: block;
            font-size: 13px;
            font-weight: 600;
            line-height: 1.54;
            letter-spacing: 0.65px;
        }

        &-name {
            display: block;
            font-size: 13px;
            line-height: 1.54;

            @include media-breakpoint-down(xs) {
                margin-bottom: 30px;
            }
        }

        &--img {
            padding: 0;
        }

        &-image {
            background-color: $cl-slate-gray;
            border-radius: 50%;
            margin: auto;
            width: 68px;
            height: 68px;
            overflow: hidden;
        }

        &-contacts {
            display: block;
            font-size: 13px;
            line-height: 1.54;
            text-transform: lowercase;
        }
    }

    &__nav {

        &--mobile {
            width: 100%;
            position: relative;

            .dropdown__button {
                padding: 15px 17px;
            }

            .dropdown-item {
                &.active {
                    background-color: $cl-dark-gray-blue;
                }


                &.btn-link {
                    text-decoration: underline;
                    color: $cl-dark-gray-blue;
                }
            }

            .dropdown-menu {
                width: 100%;
                // override bootstrap styles due to sticky header
                transform: none !important;
                top: 100% !important;
            }
        }

        &-item {
            text-align: center;

            & + & {
                border-top: 1px solid $cl-light-gray;
            }

            &.active {
                background-color: $cl-dark-gray-blue;

                .nav-link {
                    color: $cl-white;
                }
            }
        }

        .nav-link {
            padding: 14px 0;
            font-size: 13px;
            line-height: 1.54;
            text-decoration: none;
        }

        .btn-link {
            padding: 14px 0;
            display: block;
            font-size: 13px;
            line-height: 1.54;
        }
    }
}
