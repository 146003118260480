.dashboard {
    &-page {}


    &__profile {
        background-color: $cl-very-light-pink;
        padding: 22px 11px;

        line-height: 1.54;
        font-size: 13px;
        
        margin-bottom: 22px;

        b {
            display: block;
            line-height: 1.54;
            font-size: 13px;
        }

        word-break: break-word;

        &-col {
            border-top: 1px solid $cl-light-gray;
            padding-top: 22px;
            display: flex;

            dl {
               
                flex-basis: 50%;
                dt {}
                dd {}
            }

            @include media-breakpoint-up(md) {
                padding-top: 0;
                border-top: none;
                border-left: 1px solid $cl-light-gray;
                display: block;
               
            }

            
        }
    }
}