﻿.sitemap-page {
    padding-top: 40px;

    .page-title {
        padding-left: 40px;
    }

    ul {
        list-style: none;
    }
}
