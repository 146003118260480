.note-banner {
    z-index: 1;
    width: 100%;

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 15px;
    }

    &__text {
        color: $cl-white;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5;
        display: flex;
        flex-wrap: wrap;

        p {
            margin: 0;
        }

        span {
            padding: 0 5px;
        }
    }

    &__btn.btn {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.24px;
        color: $cl-white;
        padding: 0.5rem 0.75rem;
        white-space: nowrap;
        height: 32px;
        margin-left: 22px;
        padding: 8px 12px;
        display: inline-flex;
        align-items: center;
        border: 1px solid $cl-white;
    }
}
