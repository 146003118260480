﻿.product-detail {
    &__page {
        @media all and (min-width: 1640px) {
            justify-content: center;
        }
    }

    &__image {
        > ul {
            align-items: center;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            margin-top: 15px;
            padding: 0;
            width: 100%;

            @include media-breakpoint-down(md) {
                margin-bottom: 20px;
            }

            > li {
                & img, & video {
                    border: 1px solid #eee;
                    height: 100px;
                    object-fit: cover;
                    vertical-align: -1px;
                    width: 65px;
                }
            }
        }
    }

    &__rating {
        display: flex;

        > span {
            color: #666;
            margin-left: 10px;
            margin-top: 3px;
        }
    }

    &__buy {
        margin-top: 20px;

        div:last-child {
            padding: 0;

            > button:first-child {
                margin-right: 10px;
            }
        }
    }

    &__social-icon {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    &__contentarea {
        margin-top: 20px;
    }
}

.social-icon {
    padding: 0;

    &__item {
        align-items: center;
        border: 2px solid #eee;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        float: left;
        height: 40px;
        margin-right: 5px;
        width: 40px;

        &:hover {
            background-color: grey;

            & svg {
                color: white;
                fill: grey;
            }
        }
    }
}

.social-fa {
    a {
        color: grey;
    }

    &:hover {
        & a {
            color: white;
            text-decoration: none;
        }
    }
}

.store-pickup {
    display: none;

    &__item {
        border-bottom: 1px solid #eee;
        margin-top: 15px;
        padding-bottom: 10px;
    }
}

.product-tabs {
    margin-top: 20px;
}

.product-tab {
    background-color: #f5f5f5;

    &__item {
        color: black;
        padding: 22px;

        &:hover {
            border-color: transparent !important;
            color: black;
        }

        &.nav-link.active {
            background: black;
            border-bottom: 2px solid black;
            border-left: 0;
            border-radius: 0;
            border-right: 0;
            border-top: 0;
            color: white;
        }
    }

    &__content {
        padding: 15px;

        p {
            margin-bottom: 15px;
        }

        .required.error {
            background: white;
            color: red;
            padding: 0;
        }

        ul {
            list-style: none;
            padding: 0;
        }
    }

    &__review {
        border-bottom: 1px solid #eee;
        display: flex;
        padding: 15px 0px;
        width: 100%;

        &__right {
            margin-right: 30px;
        }

        h5 {
            margin-bottom: 0px;
        }

        p {
            margin-bottom: 0px;
        }
    }

    &__association {
        padding: 15px 0px;
    }
}

.review__rating {
    padding-right: 15px;
}

.review__detail {
    border-left: 1px solid #eee;
    padding-left: 15px;
}

#reviewsListing {
    margin-top: 15px;
    list-style: none;
    padding: 0;
}

.mailTo {
    a {
        color: #666;
        text-decoration: none;
    }

    a:hover {
        color: black;
        opacity: 0.7;
    }
}

.product-zoom-image {
    height: 100%;
    position: relative;
    width: 100%;


    &--container {
        height: 100%;
        padding-right: 0px;
        position: absolute;
        width: 100%;

        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.zoomImg {
    width: 100%;

    @media screen and (max-width: 767px) {
        display: none !important;
    }
}


.product-detail {

    &__accordion {
        align-items: center;
        cursor: pointer;
        color: $cl-black;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

        label {
            margin: 0;
        }

        i {
            background: white;
            font-size: 15px;
            padding: 0 22px;
            position: absolute;
            right: 0;
        }

        &.active:after {
            content: "\f106";
        }
    }

    &__availability {
        padding-right: 20px;
    }

    &__buy {
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
        margin: 15px 0;

        @include media-breakpoint-up(lg) {
            margin: 40px 0;
        }

        &--qty {
            display: flex;
            padding-right: 20px;
            width: 100%;
            display: flex;
            align-items: center;

            @include media-breakpoint-up(lg) {
                margin-bottom: -9px;
            }

            @include media-breakpoint-up(xl) {
                width: 50%;
            }

            label {
                align-self: center;
            }
        }

        .form-group {
            display: inline-block;
            margin-bottom: 0px;
            padding-left: 10px !important;
            width: 100%;

            .sub-title {
                font-size: 0.812rem;
                position: relative;
                bottom: 5px;
            }

            .controls {
                width: 100%;

                .quantity-controls-wrapper {
                    display: flex;
                    justify-content: space-between;
                }

                .controls__field {
                    @include media-breakpoint-up(xl) {
                        display: inline;
                        font-size: .8125rem;
                        font-weight: normal;
                    }
                }

                .controls__button {
                    display: inline;
                }
            }
        }

        &--btn {
            width: 100%;

            @include media-breakpoint-up(xl) {
                width: 50%;
            }

            .btn {
                width: 100%
            }
        }

        @include media-breakpoint-down(md) {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    &__description {
        margin-top: 20px;
    }
    
    &__details {
        display: flex;
        flex-wrap: wrap;
        &-term {
            font-weight: 500;
            flex-basis: 50%;
        }

        &-desc {
            flex-basis: 50%;
        }
    }
    &__flags {
        display: flex;
        margin-bottom: 18px;
        min-height: 18px;

        .flag--1 {
            background: $cl-dusk;
            color: $cl-white;
            display: block;
            font-size: 13px;
            margin-right: 18px;
            max-width: fit-content;
            order: 1;
            padding: 0 5px;
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
                font-size: 11px;
            }
        }

        .flag--2 {
            display: block;
            font-size: 13px;
            margin-right: 18px;
            max-width: fit-content;
            order: 2;

            &.cl-dark-gray-blue:before {
                content: '';
                background-image: url(/Assets/imgs/flags/bestseller.png);
                background-size: contain;
                background-repeat: no-repeat;
                width: 11px;
                height: 11px;
                display: inline-block;
                position: relative;
                bottom: -1px;
            }

            &.cl-dusk:before {
                content: '';
                background-image: url(/Assets/imgs/flags/americanmade.png);
                background-size: contain;
                background-repeat: no-repeat;
                width: 11px;
                height: 11px;
                display: inline-block;
                position: relative;
                bottom: -1px;
            }

            &.cl-dusky-purple:before {
                content: '';
                background-image: url(/Assets/imgs/flags/new.png);
                background-size: contain;
                background-repeat: no-repeat;
                width: 11px;
                height: 11px;
                display: inline-block;
                position: relative;
                bottom: -1px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 11px;
            }
        }

        .flag--3 {
            background: $cl-dusky-purple;
            color: $cl-white;
            display: block;
            font-size: 13px;
            margin-right: 15px;
            max-width: fit-content;
            order: 1;
            padding: 0 5px;
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
                font-size: 11px;
            }
        }
    }

    &__img {
        position: relative;
        max-width: 890px;

        .wishlist--icon {
            color: $cl-dusky-purple;
            position: absolute;
            right: to-rem(40px);
            top: to-rem(40px);

            &:before {
                content: '\f004';
                font-family: 'Font Awesome 5 Pro';
                font-size: to-rem(20px);
                font-weight: 300;
                line-height: 1.28;
                letter-spacing: 1px;
                text-align: left;

                @include media-breakpoint-up(lg) {
                    font-size: to-rem(25px);
                }
            }

            &.is-wishlisted {
                &:before {
                    font-weight: bold;
                }
            }

            i {
                font-size: 25px;
            }

            @include media-breakpoint-up(sm) {
                &:hover {
                    &:before {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    &__image {
        bottom: 15%;
        left: 90px;
        position: absolute;


        @include media-breakpoint-down(md) {
            bottom: 0;
            left: 0;
            position: relative;
        }

        ul > li img, ul > li video {
            border: solid 0.5px $cl-light-gray;
            height: 45px;
            width: 45px;
        }

        ul > li {
            padding-right: 22px;

            .activeImage.active {
                border: solid 2px $cl-dark-gray-blue;
            }
        }
    }

    &__info {
        max-width: 630px;
        padding-left: 80px !important;
        padding-right: 20px !important;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }

    &__link {
        font-size: 13px;
        line-height: 1.54;
        letter-spacing: normal;
        padding: 10px 0;

        i {
            padding-right: 6px;
        }

        a {
            border-bottom: 1px solid $cl-dark-gray-blue;
            text-decoration: none;

            &:hover {
                border-bottom: 1px solid $cl-dusky-purple;
            }
        }
    }

    &__price {
        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
        }

        .price__discount {
            color: $cl-dusky-purple;
            font-weight: 500;
            font-size: clamp(1rem, 2vw, 1.125rem);
            padding: 0 10px;
        }

        .price__old {
            font-weight: 500;
            font-size: clamp(1rem, 2vw, 1.125rem);
            text-decoration: line-through;
        }

        .price__buy-more {
            small {
                font-size: 13px;
                font-weight: 500;
            }
        }
    }

    &__selection {
        display: block;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
            display: flex;
        }

        &--type, &--gygi {
            display: inline-block;
            margin-bottom: 20px;
            position: relative;
            width: 100%;

            @include media-breakpoint-up(lg) {
                flex-basis: auto;
            }

            select option:hover,
            select option:focus,
            select option:active {
                background: linear-gradient($cl-dark-gray-blue, $cl-dark-gray-blue);
                background-color: $cl-dark-gray-blue !important; /* for IE */
                color: $cl-white !important;
            }

            select option:checked {
                background: linear-gradient($cl-dark-gray-blue, $cl-dark-gray-blue);
                background-color: $cl-dark-gray-blue !important; /* for IE */
                color: $cl-white !important;
            }

            .select-menu {
                appearance: none;
                background-image: none;
                border-bottom: 1px solid $cl-light-gray;
                border-left: none;
                border-right: none;
                border-top: none;
                cursor: pointer;
                font-size: 14px;
                letter-spacing: 0.28px;
                line-height: 1.5;
                margin: -3px 0;
                padding: 0;
                width: 100%;
                word-break: normal;
                -webkit-appearance: none;
                -moz-appearance: none;


                option {
                    background-color: $cl-white;

                    &:hover {
                        background-color: $cl-dark-gray-blue;
                        color: $cl-white;
                    }
                }
            }

            &:nth-of-type(2) {
                margin-right: 0px;
            }

            &:after {
                background: white;
                content: '\f107';
                color: $cl-black;
                font-family: 'Font Awesome 5 Pro';
                font-size: 14px;
                position: absolute;
                pointer-events: none;
                padding: 0 8px;
                right: 0px;
                top: 6px;
            }
        }

        &--color {
            color: $cl-black;
            font-weight: 500;
            text-transform: capitalize;
            flex-basis: 100%;

            small {
                font-size: 13px;
            }

            .colorSwatch {
                img {
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    height: auto;
                    margin: 10px 7px 0 0;
                    padding: 0;
                    width: 23px;

                    &.active {
                        border: 1px solid #b7b7b7;
                        border-radius: 50%;
                        padding: 3px;
                        width: 30px;
                    }
                }


                &[data-selected-color=#{x}] {
                    img[data-color=#{x}] {
                        border: 1px solid #b7b7b7;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    &__custom-message {
        display: none;
        padding: 22px;
        border: solid 1px $cl-light-gray;
        background-color: $cl-very-light-pink;

        &-title {
            display: block;
            margin-bottom: 10px;
            text-align: center;
        }

        &-desc {
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: normal;
            text-align: center;
        }

        &--prelogin {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: $cl-dusky-purple;
        }
    }

    &__sub-title {
        display: flex;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    &__signin {
        margin-bottom: 44px;
        margin-top: 20px;
    }

    &__sku {
        padding-right: 20px;
    }

    &__zoomImg {
        position: relative;
        text-align: center;

        img {
            @include media-breakpoint-down(md) {
                padding: 15px;
            }
        }
    }
}

.related-products {
    hr {
        border-top: 3px solid $cl-beige;
        width: to-rem(125px);
        margin-bottom: to-rem(30px);

        @include media-breakpoint-down(md) {
            margin-bottom: to-rem(70px);
        }
    }

    .price__buy-more {
        display: none;
    }
}