﻿/* Styles for Icon block container */
.icon-container-block {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    /* Icon block container CTA button list */
    &--cta_list {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            margin-bottom: 6rem;
        }
    }
    /* Icon block container Icons list */
    &--icon_list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1568px;
        margin: 40px 20px 20px;

        & > div {
            align-items: flex-start;
            display: flex;
            justify-content: center;
            flex: 1 1 auto;
            margin-bottom: 30px;
            padding: 0 14px;
            width: 50%;

            @include media-breakpoint-up(md) {
                margin-bottom: 0px;
                padding: 0 11px;
                width: 100%;
            }
        }

        @include media-breakpoint-up(sm) {
            margin: 3rem 0;
        }
    }
    /* Icon block container Section title */
    &--title {
        color: $cl-white;
        margin-top: 3rem;
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-top: 6rem;
        }

        .section-title-block {
            padding: 0px;

            /*h2, h6, p, span {
                color: $cl-white;
            }*/
        }
    }
}
/* Styles for Icon block */
.icon-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    &--description {
        padding: 2rem 0 0;

        @include media-breakpoint-up(md) {
            padding: 3.5rem 2rem 0;
        }
    }
}
