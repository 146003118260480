.btn {
    border: 2px solid transparent;
    letter-spacing: 1.05px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.875rem 2.8125rem;

    &:focus, &:active {
        box-shadow: none !important;
    }

    &.btn-primary, &.cta-solid-button-theme-dark {
        background-color: $cl-dark-gray-blue;
        border-color: $cl-dark-gray-blue;
        color: $cl-white;

        &:hover {
            background-color: $cl-dusky-purple;
            border-color: $cl-dusky-purple;
            color: $cl-white;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $cl-dark-gray-blue;
            border-color: $cl-dark-gray-blue;
        }
    }

    &.btn-secondary, &.cta-solid-button-theme-light {
        background-color: $cl-very-light-pink;
        border-color: $cl-very-light-pink;
        color: $cl-charcoal-gray;

        &:hover {
            background-color: $cl-beige;
            border-color: $cl-beige;
            color: $cl-charcoal-gray;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $cl-very-light-pink;
            border-color: $cl-very-light-pink;
            color: $cl-charcoal-gray;
        }
    }

    &.cta-outlined-button-theme-light {
        background-color: transparent;
        border: 2px solid $cl-white;
        color: $cl-white;

        &:hover {
            background-color: $cl-white;
            color: $cl-black;
            mix-blend-mode: lighten;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: unset;
            border-color: $cl-white;
            color: $cl-white;
        }
    }

    &.btn-outline-primary, &.cta-outlined-button-theme-dark {
        border-color: $cl-dark-gray-blue;
        color: $cl-dark-gray-blue;

        &:hover {
            background-color: $cl-dark-gray-blue;
            color: $cl-white;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: unset;
            border-color: $cl-dark-gray-blue;
            color: $cl-charcoal-gray;
        }
    }

    &.btn-link, &.btn-link-theme-dark {
        border-bottom: 2px solid $cl-dark-gray-blue;
        color: $cl-dark-gray-blue;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        font-size: 1.063rem;
        font-weight: 500;
        height: auto;
        letter-spacing: 0.080rem;
        line-height: 1.563rem;
        padding: 0;
        text-decoration: none;
        width: auto;


        &:hover {
            border-color: $cl-dusky-purple;
            color: $cl-dusky-purple;
        }
    }

    &.btn-link-theme-light {
        border-bottom: 2px solid $cl-white;
        color: $cl-white;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        font-size: 1.063rem;
        font-weight: 500;
        height: auto;
        letter-spacing: 0.080rem;
        line-height: 1.563rem;
        padding: 0;
        text-decoration: none;
        width: auto;
    }

    &.addToCart {
        padding: 0.875rem 1rem;
    }
}

.btn-sm {
    border-radius: 0;
}

.btn-prev,
.btn-next {
    border: 0;
    background-color: unset;
    font-size: to-rem(13px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    color: $cl-charcoal-gray;
    text-align: center;

    i {
        margin: 0 5px;
    }
    &:focus,
    &:active {
        box-shadow: none !important;
    }

    &.btn-primary {
        color: $cl-white;
    }
}

.btn-icon-link {
    background: none;
    border: none;        

    text-decoration: underline;    
    color: $cl-charcoal-gray;  

    font-size: to-rem(13px);    
    line-height: 1.54;

    margin-right: 23px;

    &:hover, 
    &:focus {
        color: $cl-dusky-purple;
    }

    i {        
        font-size: 1em;
        margin-right: 1ch;
    }      
}


//Ensure that, on a dark colored background, all buttons will follow a white theme.
.cl-bg-dark-gray-blue,
.cl-bg-dusk,
.cl-bg-dusky-purple,
.cl-bg-slate-gray {
    .btn.btn-primary, .btn-cta-solid-button-theme-light {
        background-color: $cl-very-light-pink;
        border-color: $cl-very-light-pink;
        color: $cl-charcoal-gray;

        &:hover {
            background-color: $cl-beige;
            border-color: $cl-beige;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $cl-very-light-pink;
            border-color: $cl-very-light-pink;
            color: $cl-charcoal-gray;
        }
    }

    .btn.btn-outline-primary, .btn.btn-secondary, .btn-cta-outlined-button-theme-light {
        background-color: transparent;
        border: 2px solid $cl-white;
        color: $cl-white;

        &:hover {
            background-color: $cl-white;
            color: $cl-black;
            mix-blend-mode: lighten;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: unset;
            border-color: $cl-white;
            color: $cl-white;
        }
    }

    .btn.btn-link, .btn-link-theme-light {
        border-color: $cl-white;
        color: $cl-white;
    }
}


#scrollToTopBtn {
    position: fixed;
    font-size: to-rem(32px);
    top: 90vh;
    transform: translate(-50%);
    right: 0;
    z-index: 9999;
    opacity: .7;
    color: $cl-charcoal-gray;

    &:hover {
        opacity: 1;
        transition: opacity .2s linear;
    }

    @include media-breakpoint-up(lg) {
        right: to-rem(22px);
    }
    @media print {
        display: none;
    }
}